import React from 'react'
import infoIcon from "../../assets/svg/info-dark.svg";
import {BasicTooltip} from "../../common-components/Tooltip/BasicTooltip/BasicTooltip";

type QuizTableTypeProps = {

    lastBlock?: string
}

export const QuizTable: React.FC<QuizTableTypeProps> = ({lastBlock}) => {

    return (
        <>
            <div className={'title'}>
                <div className={'number-block'}>Номер</div>
                <div className={'quiz-theme-block'}>
                    <BasicTooltip placement={'bottom-start'} title={"Это поле можно использовать для заметок, оно не будет отображаться участникам"}>
                        <img src={infoIcon} alt=""/>
                    </BasicTooltip>
                    <span>Заметка</span>
                </div>
                <div className={'description-block'}>
                    <BasicTooltip placement={'bottom-start'} title={"Описание вопроса будет отображаться на публичной части!"}>
                        <img src={infoIcon} alt=""/>
                    </BasicTooltip>
                    <span>Вопрос</span>
                </div>
                <div className={'type-question-block'}>Тип</div>
                <div className={'type-question-block position-header-question'}>Ответы</div>
                <div className={'attempts-number-block'}>Попытки</div>
                { lastBlock && <div className={'attempts-number-block'}>{lastBlock}</div>}
            </div>
        </>
    )
}