import React, {memo, useEffect} from 'react'
import {FormGroup, Grid, TextField} from '@material-ui/core'
import FormControl from '@mui/material/FormControl';
import styles from './Setting.module.css'
import "react-datepicker/dist/react-datepicker.css";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {
    setChannelName,
    setDescription,
    getRepostedLink,
    setTitle, setYoutubeChannel,
    setYoutubeLink,
    updateSettingsPage, getStartDate
} from "../../redux/settings-reducer";
import {Progress} from "../../common-components/Progress";
import {Button, ErrorSpan, SettingWrap, SettingWrapper} from '../../common-styles/Common.styles';
import {FormsType, PropsType} from "./types";
import {setIsProgress} from "../../redux/app-reducer";
import {getTabnailLink, validateSettingsForm} from "../../utils";
import {PageTitle} from "../../common-components/PageTitle/PageTitle";
import infoIcon from "../../assets/svg/info-icon.svg";
import {BasicTooltip} from "../../common-components/Tooltip/BasicTooltip/BasicTooltip";
import {DataPickerComponent} from "./data-picker/CustomDataPicker";
import {getMode} from "../../redux/selectors/settingsSelector";

export const SettingPage: React.FC<PropsType> = memo(({quizId}) => {

    const dispatch = useDispatch()

    const title = useSelector(setTitle)
    const progress = useSelector(setIsProgress)
    const channelName = useSelector(setChannelName)
    const youtubeChannel = useSelector(setYoutubeChannel)
    const description = useSelector(setDescription)
    const youtubeStreamLink = useSelector(setYoutubeLink)
    const repostedLinks = useSelector(getRepostedLink)
    const startDate = useSelector(getStartDate)
    const mode = useSelector(getMode)

    const formik = useFormik({
        initialValues: {
            title: '',
            channelName: '',
            youtubeChannel: '',
            pointsStrategy: 'TESTING...',
            youtubeStreamLink: '',
            repostedLinkVK: '',
            repostedLinkFB: '',
            description: '',
            timeZone: '',
            startDate: new Date(),
            youtubePreviewLink: ''
        },
        validate: validateSettingsForm(mode),
        onSubmit: values => {
            const currentDate = new Date().getTime();
            const previouslySetDate = new Date(values.startDate).getTime()

            if (currentDate >= previouslySetDate) {
                formik.setFieldError('startDate', 'Выберите дату, которая больше текущей даты и времени.')
                return;
            }

            let data: FormsType = {
                quizId,
                title: values.title.trim(),
                channelName: values.channelName.trim(),
                youtubeChannel: values.youtubeChannel.trim(),
                youtubeStreamLink: values.youtubeStreamLink.trim(),
                pointsStrategy: 'pointsByMaxMinus1PerCorrectAnswer',
                description: values.description.trim(),
                timeZone: '+03:00',
                startDate: values.startDate.toISOString(),
                repostedLinks: {
                    vk: values.repostedLinkVK ? values.repostedLinkVK.trim() : null,
                    fb: values.repostedLinkFB ? values.repostedLinkFB.trim() : null
                },
                youtubePreviewLink: getTabnailLink(values.youtubeStreamLink.trim())
            }
            dispatch(updateSettingsPage(data));
        },
    })

    useEffect(() => {
        formik.setFieldValue("title", title)
        formik.setFieldValue("channelName", channelName)
        formik.setFieldValue("youtubeChannel", youtubeChannel)
        formik.setFieldValue("description", description)
        formik.setFieldValue("youtubeStreamLink", youtubeStreamLink)
        formik.setFieldValue("startDate", startDate)
        if (repostedLinks?.fb) formik.setFieldValue("repostedLinkFB", repostedLinks.fb)
        if (repostedLinks?.vk) formik.setFieldValue("repostedLinkVK", repostedLinks.vk)
    }, [title, description, youtubeStreamLink, repostedLinks, youtubeChannel, channelName, startDate])


    return (
        <SettingWrap>
            {progress && <Progress/>}
            <div className={'wrapper'}>
                <Grid container>
                    <Grid item>
                        <PageTitle text={'Настройка страницы'}/>
                        <form onSubmit={formik.handleSubmit}
                              className={'form-wrap'}>
                            <FormControl sx={{
                                width: '508px',
                                background: '#fff',
                                padding: '34px 36px',
                                border: 'solid 1px #DFDFDF',
                                borderRadius: '2px'
                            }}>
                                <FormGroup>
                                    <div className={styles.stylesForTextField}>
                                        <SettingWrapper>
                                            <div className={'image-block'}>
                                                <TextField
                                                    {...formik.getFieldProps('title')}
                                                    style={{width: '100%'}}
                                                    error={!!(formik.touched.title && formik.errors.title)}
                                                    id="standard-read-only-input"
                                                    label="Название Quiz"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    variant="standard"
                                                />

                                            </div>
                                            {
                                                formik.touched.title && formik.errors.title
                                                && <ErrorSpan>{formik.errors.title}</ErrorSpan>
                                            }
                                        </SettingWrapper>
                                    </div>

                                    <div className={styles.stylesForTextField}>
                                        <SettingWrapper>
                                            <div className={'image-block'}>
                                                <TextField
                                                    {...formik.getFieldProps('channelName')}
                                                    style={{width: '100%', color: 'red'}}
                                                    error={!!(formik.touched.channelName && formik.errors.channelName)}
                                                    id="standard-read-only-input"
                                                    label="Название YouTube канала"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    variant="standard"
                                                />

                                            </div>

                                            {
                                                formik.touched.channelName && formik.errors.channelName
                                                && <ErrorSpan>{formik.errors.channelName}</ErrorSpan>
                                            }

                                        </SettingWrapper>
                                    </div>

                                    <div className={styles.stylesForTextField}>
                                        <label className={styles.dataPickerLabel}>Дата и время проведения стрима</label>
                                        <DataPickerComponent formik={formik}/>
                                        {
                                            formik.errors.startDate && <ErrorSpan>{formik.errors.startDate}</ErrorSpan>
                                        }

                                    </div>

                                    <div className={styles.stylesForTextField}>
                                        <SettingWrapper>
                                            <div className={'image-block'}>
                                                <TextField
                                                    {...formik.getFieldProps('youtubeStreamLink')}
                                                    style={{width: '100%'}}
                                                    error={!!(formik.touched.youtubeStreamLink && formik.errors.youtubeStreamLink)}
                                                    id="standard-read-only-input"
                                                    label="Ссылка на стрим"
                                                    InputProps={{
                                                        readOnly: false,
                                                        style: {
                                                            paddingRight: '28px'
                                                        }
                                                    }}
                                                    variant="standard"
                                                />

                                                <div className={'image'}>
                                                    <BasicTooltip placement={'bottom-end'}
                                                                  title={"Вставьте ссылку на ютуб стрим, который вы будете проводить, как викоторину!"}>
                                                        <img src={infoIcon} alt="infoIcon"/>
                                                    </BasicTooltip>
                                                </div>

                                            </div>

                                            {
                                                formik.touched.youtubeStreamLink && formik.errors.youtubeStreamLink
                                                && <ErrorSpan>{formik.errors.youtubeStreamLink}</ErrorSpan>
                                            }
                                        </SettingWrapper>
                                    </div>

                                    <div className={styles.stylesForTextField}>
                                        <SettingWrapper>
                                            <div className={'image-block'}>

                                                <TextField
                                                    {...formik.getFieldProps('repostedLinkFB')}
                                                    style={{width: '100%'}}
                                                    error={!!(formik.touched.repostedLinkFB && formik.errors.repostedLinkFB)}
                                                    id="standard-read-only-input"
                                                    label="Ссылка на пост, который нужно репостнуть в FB"
                                                    InputProps={{
                                                        readOnly: false,
                                                        style: {
                                                            padding: '4px 5px 5px 0',
                                                        }
                                                    }}
                                                    variant="standard"
                                                />

                                                <div className={'image'}>
                                                    <BasicTooltip placement={'bottom-end'}
                                                                  title={"Вставьте ссылку на пост, репост которого нужно сделать"}>
                                                        <img src={infoIcon} alt="infoIcon"/>
                                                    </BasicTooltip>
                                                </div>

                                            </div>

                                            {
                                                formik.touched.repostedLinkFB && formik.errors.repostedLinkFB
                                                && <ErrorSpan>{formik.errors.repostedLinkFB}</ErrorSpan>
                                            }

                                        </SettingWrapper>
                                    </div>

                                    <div className={styles.stylesForTextField}>
                                        <SettingWrapper>
                                            <div className={'image-block'}>

                                                <TextField
                                                    {...formik.getFieldProps('repostedLinkVK')}
                                                    style={{width: '100%'}}
                                                    error={!!(formik.touched.repostedLinkVK && formik.errors.repostedLinkVK)}
                                                    id="standard-read-only-input"
                                                    label="Ссылка на пост, который нужно репостнуть в VK"
                                                    InputProps={{
                                                        readOnly: false,
                                                        style: {
                                                            paddingRight: '28px'
                                                        }
                                                    }}
                                                    variant="standard"
                                                />

                                                <div className={'image'}>
                                                    <BasicTooltip placement={'bottom-end'}
                                                                  title={"Вставьте ссылку на пост, репост которого нужно сделать"}>
                                                        <img src={infoIcon} alt="infoIcon"/>
                                                    </BasicTooltip>
                                                </div>

                                            </div>

                                            {
                                                formik.touched.repostedLinkVK && formik.errors.repostedLinkVK
                                                && <ErrorSpan>{formik.errors.repostedLinkVK}</ErrorSpan>
                                            }

                                        </SettingWrapper>
                                    </div>

                                    <div className={styles.stylesForTextField}>
                                        <SettingWrapper>
                                            <div className={'image-block'}>
                                                <TextField
                                                    {...formik.getFieldProps('youtubeChannel')}
                                                    error={!!(formik.touched.youtubeChannel && formik.errors.youtubeChannel)}
                                                    style={{width: '100%', color: 'red'}}
                                                    id="standard-read-only-input"
                                                    label="Ссылка на Ваш YouTube канал"
                                                    InputProps={{
                                                        readOnly: false
                                                    }}
                                                    variant="standard"
                                                />

                                            </div>

                                            {
                                                formik.touched.youtubeChannel && formik.errors.youtubeChannel
                                                && <ErrorSpan>{formik.errors.youtubeChannel}</ErrorSpan>
                                            }
                                        </SettingWrapper>
                                    </div>

                                    <div className={'text-field'}>
                                        <TextField
                                            {...formik.getFieldProps('description')}
                                            id="outlined-multiline-static"
                                            name={'description'}
                                            label="Описание Quiz"
                                            multiline
                                            rows={4}
                                            placeholder="Описание..."
                                        />
                                    </div>

                                    <Button className={'button primary'}
                                            sx={{mt: '20px', alignSelf: 'flex-end'}}
                                            variant="extended"
                                            type={'submit'}
                                            aria-label="add">
                                        Сохранить
                                    </Button>

                                </FormGroup>
                            </FormControl>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </SettingWrap>
    )
})





