import styled from "styled-components";

const Wrapper = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.h2`
  text-align: left !important;
  font-weight: 500;
  font-size: 20px;
  margin: 6px 0;
`
export const S = {
    Wrapper,
    Title
}