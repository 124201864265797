import React, {SyntheticEvent, useEffect, useState} from 'react'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {useDispatch, useSelector} from "react-redux";
import {setAlertMessage, setNotificationMessage} from "../redux/settings-reducer";
import {FACEBOOK_NOTIFICATIONS, NOTIFICATION_QUIZ_MESSAGE} from "../constants";

const SNACKBAR_SHOW_DURATION = 3000;

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const EventSnackbar = () => {

    const dispatch = useDispatch()

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const notificationMessage = useSelector(setNotificationMessage)

    useEffect(() => {
        setSnackbarOpen(!!notificationMessage);
    }, [notificationMessage]);

    const handleSnackbarClose = (event: Event | SyntheticEvent, reason?: string): void => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.RESET));
        setSnackbarOpen(false);
    };

    const setNotificationTypeByMessageType = (notificationMessage: NOTIFICATION_QUIZ_MESSAGE) => {
        const defaultSeverity = 'error'

        const notificationMessages = {
            [NOTIFICATION_QUIZ_MESSAGE.PASSED]: 'success',
            [NOTIFICATION_QUIZ_MESSAGE.SETTINGS_INSTALL]: 'success',
            [NOTIFICATION_QUIZ_MESSAGE.TOKEN_COPIED]: 'success',
            [NOTIFICATION_QUIZ_MESSAGE.LINK_COPIED]: 'success',
            [NOTIFICATION_QUIZ_MESSAGE.LAUNCHED]: 'warning',
            [NOTIFICATION_QUIZ_MESSAGE.SET_ALL_QUESTION]: 'warning',
            [FACEBOOK_NOTIFICATIONS.USERS_NOT_FOUNDS]: 'warning',
            [FACEBOOK_NOTIFICATIONS.USERS_NOT_FOUNDS]: 'warning',
        }
        // @ts-ignore
       const result =  notificationMessages[notificationMessage]

        return result ? result : defaultSeverity
    }


    return (
        <>
            {!!notificationMessage && <Snackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                autoHideDuration={SNACKBAR_SHOW_DURATION}
                anchorOrigin={notificationMessage === NOTIFICATION_QUIZ_MESSAGE.SETTINGS_INSTALL ? {
                    horizontal: 'center',
                    vertical: 'bottom'
                } : {horizontal: 'left', vertical: 'bottom'}}
                style={{position: 'fixed', left: '49%'}}>

                <Alert
                    onClose={handleSnackbarClose}
                    severity={setNotificationTypeByMessageType(notificationMessage)}
                    sx={{width: '100%'}}>{notificationMessage}</Alert>
            </Snackbar>}</>

    )
}