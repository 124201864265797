import {
    PaginationType,
    ParticipantStatisticType,
    QuestionStatistics,
    StatisticsType,
    Statuses,
} from "../api/api-types";
import {Dispatch} from "redux";
import {setProgress} from "./app-reducer";
import axios from "axios";
import {logger} from "../logger";
import {AppStateType} from "./redux-store";
import {quizAPI} from "../api/api";

const SET_STATISTIC = 'questions/SET-STATISTIC';
const SET_PARTICIPANTS_STATISTIC = 'questions/SET-PARTICIPANTS-STATISTIC';

type ActionsTypes =
    | ReturnType<typeof setStatisticData>
    | ReturnType<typeof setParticipantStatisticData>


export type initialStateType = StatisticsType

const initialState = {} as initialStateType

export const statisticsReducer = (state = initialState, action: ActionsTypes): initialStateType => {

    switch (action.type) {

        case SET_STATISTIC: {
            return {
                ...state,
                ...action.payload
            }
        }

        case SET_PARTICIPANTS_STATISTIC: {
            const currentItems = state.participantStatistic && state.participantStatistic.items
                ? state.participantStatistic.items
                : [];

            return {
                ...state,
                participantStatistic: {
                    ...action.payload,
                    items: [...currentItems, ...action.payload.items],
                }
            }
        }

        default :
            return state;
    }
}


export const setStatisticData = (data: StatisticsType) => ({
    type: SET_STATISTIC,
    payload: data
} as const)


export const setParticipantStatisticData = (data: PaginationType<ParticipantStatisticType[]>) => ({
    type: SET_PARTICIPANTS_STATISTIC,
    payload: data
} as const)

export const getStatistic = () => async (dispatch: Dispatch) => {
    try {
        const response = await quizAPI.getStatistic()
        if (response.resultCode === Statuses.Success) {
            dispatch(setStatisticData(response.data))
        } else {
            logger.error('Bad response getStatistic', response.messages[0])

        }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            logger.error('getStatistic', error)
        }
        dispatch(setProgress(false))
    }
}

export const  getParticipantStatistic = (nextPage: number = 1 ) => async (dispatch: Dispatch) => {
    try {
        const response = await quizAPI.getParticipantStatistic(nextPage)
        dispatch(setParticipantStatisticData(response.data))
    } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
            logger.error('getParticipantStatistic', e)
        }
        dispatch(setProgress(false))
    }
}

export const getSortedTopParticipants = (state: AppStateType): ParticipantStatisticType[] => state.statistics.participantStatistic?.items;
export const getTopParticipantsTotalCount = (state: AppStateType): number => state.statistics.participantStatistic?.totalCount;
export const getPageNumber = (state: AppStateType): number => state.statistics.participantStatistic?.page;
export const setParticipantAmount = (state: AppStateType): number => state.statistics.participantAmount
export const setNotApprovedParticipantAmount = (state: AppStateType): number => state.statistics.notApprovedParticipantAmount
export const setQuestionStatistics = (state: AppStateType): QuestionStatistics[] => state.statistics.questionStatistic