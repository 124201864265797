import React from 'react'
import styled from 'styled-components'
import { Error } from '../common-styles/Common.styles'


export const NotFound = () => {
    return (
        <Styles404>
        <Error>
            <h1> 404: PAGE NOT FOUND</h1>
        </Error>
        </Styles404>
    )
}

const Styles404 = styled.div`
  background: rgb(255, 255, 255);
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
`