import React from 'react';
import {useSelector} from "react-redux";
import {AppStateType} from "../../../redux/redux-store";
import {OVERLAY_TYPES, OverlayType} from "../../../api/api-types";
import {CSSTransition} from 'react-transition-group';
import s from '../owner-styles/animation.module.css'



export const CorrectAnswers: React.FC = () => {

    const correctAnswers = useSelector<AppStateType, string[] | null>((state) => state.overlays.correctAnswers)
    const correctAnswerOverlay = useSelector<AppStateType, OverlayType | undefined>((state) => state.overlays.meaningOverlays.find(el => el.type === OVERLAY_TYPES.CORRECT_ANSWERS))

    const mappedCorrectAnswers = correctAnswers?.map((a) => <li>{a}</li>)

    return (
        <>
            {
                correctAnswers &&  <CSSTransition
                    in={correctAnswerOverlay?.active}
                    timeout={600}
                    classNames="alert"
                    unmountOnExit
                >
                    <div className={s.stats}>
                        <h2 className={s.title}>Правильные ответы:</h2>
                        <div className={s.text}>

                            <div>
                                <ul>
                                    {mappedCorrectAnswers}
                                </ul>
                            </div>

                        </div>
                    </div>
                </CSSTransition>
            }
        </>
    );
}
