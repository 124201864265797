import React from 'react';
import { S } from './PageTitle_Styles';

export const PageTitle = (props: {text: string, children?: React.ReactNode}) => {
    return (
        <S.Wrapper>
            <S.Title>
                {props.text}
            </S.Title>
            {props.children}
        </S.Wrapper>

    );
}
