import {ThunkAction} from "redux-thunk";
import {AppStateType} from "./redux-store";
import {quizAPI, settingQuizAPI} from "../api/api";
import {setLoading, setProgress} from "./app-reducer";
import {IncrementQuestionNumber, setEventVoting} from "./quiz-reducer";
import {logger} from "../logger";
import {QUESTION_STATUS} from "../constants";
import {QuestionType, Statuses} from "../api/api-types";
import {setAlertMessage} from "./settings-reducer";
import axios from "axios";
import {Dispatch} from "redux";

const DELETE_QUESTION = 'questions/DELETE-QUESTION';
const SET_QUESTIONS = 'questions/SET-QUESTIONS';
const SET_SCROLLING = 'questions/SET-SCROLLING';
const SET_QUESTION_STATUS = 'questions/SET-OPEN-VOTE-QUESTION';
const SET_UPDATED_QUESTION = 'questions/SET-UPDATED-QUESTION';
const SET_LAST_STARTED_QUESTION_ID = 'questions/SET-LAST-STARTED-QUESTION-ID';
const FIND_LAST_CLOSE_QUESTION = 'questions/FIND-LAST-CLOSE-QUESTION';


type ActionsTypes =
    | ReturnType<typeof setLoading>
    | ReturnType<typeof setQuestions>
    | ReturnType<typeof deleteQuestion>
    | ReturnType<typeof setScrolling>
    | ReturnType<typeof setProgress>
    | ReturnType<typeof setEventVoting>
    | ReturnType<typeof setQuestionStatus>
    | ReturnType<typeof updatedQuestion>
    | ReturnType<typeof setAlertMessage>
    | ReturnType<typeof setLastStartedQuestionId>
    | ReturnType<typeof findLastClosedQuestion>
    | ReturnType<typeof IncrementQuestionNumber>


type ThunkType = ThunkAction<void, AppStateType, unknown, ActionsTypes>

export type initialQuestionsStateType = {
    questions: Array<QuestionType>
    lastStartedQuestionId: string | null
    scrolling: boolean
}

const initialState: initialQuestionsStateType = {
    questions: [],
    lastStartedQuestionId: null,
    scrolling: false
};

export const questionReducer = (state = initialState, action: ActionsTypes): initialQuestionsStateType => {

    switch (action.type) {
        case SET_QUESTIONS: {
            return {
                ...state,
                questions: [...action.payload]
            }
        }
        case DELETE_QUESTION: {
            return {
                ...state,
                questions: state.questions.filter((q) => q.id !== action.questionId)
            }
        }
        case SET_SCROLLING: {

            return {
                ...state,
                scrolling: action.value
            }
        }
        case SET_QUESTION_STATUS: {
            return {
                ...state,
                questions: state.questions.map(q => q.id === action.id ? {...q, status: action.status} : q)
            }
        }
        case SET_UPDATED_QUESTION: {
            return {
                ...state,
                questions: state.questions.map((el) => el.id === action.question.id ? {...el, ...action.question} : el)
            }
        }
        case SET_LAST_STARTED_QUESTION_ID: {
            return {
                ...state,
                lastStartedQuestionId: action.questionId
            }
        }

        case FIND_LAST_CLOSE_QUESTION: {
            const filteredQuestions = action.questions.filter(q => q.status === QUESTION_STATUS.CLOSED)
            const lastClosedQuestionId = filteredQuestions[filteredQuestions.length - 1]?.id
            const lastStartedQuestionId = lastClosedQuestionId ? lastClosedQuestionId : null

            return {
                ...state,
                lastStartedQuestionId
            }
        }

        default :
            return state;
    }
}


export const setQuestions = (questions: Array<QuestionType>) => ({
    type: SET_QUESTIONS,
    payload: questions
} as const)

export const updatedQuestion = (question: QuestionType) => ({
    type: SET_UPDATED_QUESTION,
    question
} as const)

export const setScrolling = (value: boolean) => ({
    type: SET_SCROLLING,
    value
} as const)

export const setQuestionStatus = (id: string, status: QUESTION_STATUS) => ({
    type: SET_QUESTION_STATUS,
    id,
    status
} as const)

export const deleteQuestion = (questionId: string) => ({
    type: DELETE_QUESTION,
    questionId
} as const)

export const setLastStartedQuestionId = (questionId: string | null) => ({
    type: SET_LAST_STARTED_QUESTION_ID,
    questionId
} as const)

export const findLastClosedQuestion = (questions: Array<QuestionType>) => ({
    type: FIND_LAST_CLOSE_QUESTION,
    questions
} as const)


export const getQuestions = (): ThunkType => async (dispatch) => {
    try {
        const result = await quizAPI.getAllQuestions();
        if (result.resultCode === Statuses.Success) {
            dispatch(setQuestions(result.data))
        } else {
            logger.error('Bad response getQuestions', result.messages[0])
        }
    } catch (error) {
        logger.error('getQuestions', error)
    } finally {
        dispatch(setProgress(false))
    }
}

export const addQuestion = (quizId: string | null): ThunkType => async (dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await settingQuizAPI.addNewQuestion(quizId)
        if (response.resultCode === Statuses.Success) {
            dispatch(getQuestions())
        } else {
            logger.error('Bad response addQuestion', response.messages[0])
            dispatch(setProgress(false))
        }
    } catch (error) {
        logger.error('addQuestion', error)

        dispatch(setProgress(false))
    }
}

export const updateQuestion = (data: FormData): ThunkType => async (dispatch) => {
    dispatch(setProgress(true))

    try {
        const response = await settingQuizAPI.updateQuestion(data)
        if (response.data.resultCode === Statuses.Success) {
            dispatch(updatedQuestion(response.data.data))
            setTimeout(() => {
                dispatch(setProgress(false))
            }, 1000)
        }
    } catch (error) {
        logger.error('updateQuestion', error)
        dispatch(setProgress(false))
    }
}

export const removeQuestion = (questionId: string): ThunkType => async (dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await settingQuizAPI.deleteQuestion(questionId)
        if (response.resultCode === Statuses.Success) {
            dispatch(getQuestions())
            dispatch(deleteQuestion(questionId))
        } else {
            logger.error('Bad response removeQuestion', response.messages[0].message)
        }
    } catch (error) {
        logger.error('removeQuestion', error)

    }
}

export const updateOrderQuestions = (quizId: string, questionId: string, numberId: number, oldArrayQuestions: Array<QuestionType>): ThunkType => async (dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await settingQuizAPI.updateOrderQuestions(questionId, numberId)
        if (response.resultCode === Statuses.Success) {
            dispatch(getQuestions())
        } else {
            logger.error('Bad response updateOrderQuestions', response.messages[0].message)
        }

    } catch (error) {
        dispatch(setQuestions(oldArrayQuestions))
        logger.error('updateOrderQuestions', error)

    } finally {
        dispatch(setProgress(false))
    }
}

export const openQuestion = (quizId: string, questionId: string): ThunkType => async (dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await quizAPI.questionVoting(questionId)
        if (response.resultCode === Statuses.Success) {
            //await dispatch(getMeaningOverlays(quizId))
            dispatch(setLastStartedQuestionId(null))
            dispatch(setQuestionStatus(questionId, QUESTION_STATUS.STARTED))
        } else {
            logger.error('Bad response openQuestionVoting', response.messages[0].message)

        }
    } catch (error) {
        if (axios.isAxiosError<{ message: string }>(error)) {
            if (error.response) {
                dispatch(setAlertMessage(error.response.data.message))
            }
        }
        logger.error('openQuestionVoting', error)

    } finally {
        dispatch(setProgress(false))
    }
}

export const closeQuestion = (quizId: string, questionId: string): ThunkType => async (dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await quizAPI.closeQuestionVoting(questionId)
        if (response.resultCode === Statuses.Success) {
            //await dispatch(getMeaningOverlays(quizId))
            dispatch(IncrementQuestionNumber())
            dispatch(setQuestionStatus(questionId, QUESTION_STATUS.CLOSED))
            return true;
        } else {
            logger.error('Bad response closeQuestionVoting', response.messages[0].message)
        }
    } catch (error) {
        if (axios.isAxiosError<{ message: string }>(error)) {
            if (error.response) {
                dispatch(setAlertMessage(error.response.data.message))
            }
        }
        logger.error('closeQuestionVoting', error)

    } finally {
        dispatch(setProgress(false))
    }
}

export const sendQuestionClosingTimer = () => async (dispatch: Dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await quizAPI.sendQuestionClosingTimer()
        if (response.resultCode === Statuses.Success) {
            return true
        } else {
            logger.error('Bad response sendQuestionClosingTimer', response.messages[0].message)
        }
    } catch (error) {
        if (axios.isAxiosError<{ message: string }>(error)) {
            if (error.response) {
                dispatch(setAlertMessage(error.response.data.message))
            }
        }
        logger.error('sendQuestionClosingTimer', error)

    } finally {
        dispatch(setProgress(false))
    }
}

