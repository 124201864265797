import React, {memo, useEffect, useMemo, useState} from 'react'
import {Button, CommonWrapper, TableStyles} from "../../common-styles/Common.styles";
import {Progress} from "../../common-components/Progress";
import {useDispatch, useSelector} from "react-redux";
import {openVoteQuiz, getQuizStatus} from "../../redux/quiz-reducer";
import {Box} from "@mui/material";
import {Modal} from '../../common-components/Modal';
import QuizQuestion from "./QuizQuestion";
import {QuizTable} from "../tables/QuizTable";
import {getDelay, setAlertMessage, setQuizDelay} from "../../redux/settings-reducer";
import {setIsProgress} from "../../redux/app-reducer";
import {NOTIFICATION_QUIZ_MESSAGE, QUESTION_STATUS, QUIZ_STATUS} from "../../constants";
import {OverlayButtonsBlock} from "./overlayButtons/OverlayButtonsBlock";
import {WarningMessage} from "../../common-components/WarningMessage/WarningMessage";
import {BasicSelect} from "../../common-components/Select/Select";
import {PageTitle} from "../../common-components/PageTitle/PageTitle";
import {QuestionDataType} from "../../hooks/useQuestionTmer";
import {getQuestions} from "../../redux/selectors/questionSelectors";

type PropsType = {
    quizId: string | null
    timer: number | null
    closeQuestionFlag: boolean
    setActiveTimer: (value: boolean) => void
    setTimer: (timer: number) => void
    setQuestionData: (data: QuestionDataType) => void
}


export const Quiz: React.FC<PropsType> = memo((props) => {

    const dispatch = useDispatch()

    const progress = useSelector(setIsProgress)
    const quizStatus = useSelector(getQuizStatus)
    const arrayQuestions = useSelector(getQuestions)
    const quizDelay = useSelector(getDelay)

    const [lastQuestionWasClosed, setLastQuestionWasClosed] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [delay, setDelay] = useState(0)

    const checkingCompletedQuestionsData = arrayQuestions.filter((q) => !q.questionDescription?.trim())
    const quizUnActive = quizStatus !== QUIZ_STATUS.started

    const delaySeconds = useMemo(() => {
        let seconds = []

        for (let i = 0; i <= 30; i++) {
            seconds.push(`${i.toString()} сек`)
        }

        return seconds
    }, [])

    const quizLaunchHandler = () => {
        if (checkingCompletedQuestionsData.length && quizStatus !== QUIZ_STATUS.closed && quizUnActive) {
            dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.SET_ALL_QUESTION))
            return
        }
        quizUnActive ? dispatch(openVoteQuiz()) : setOpenModal(true)
    }

    const setQuizDelayHandler = (delay: string) => {
        const secondDelay = Number(delay.slice(0, 2).trim())
        dispatch(setQuizDelay(secondDelay * 1000))
    }

    const filteredQuestion = arrayQuestions.filter(q => q.answers.length)

    const questions = filteredQuestion
        .map((q) => <QuizQuestion
        key={q.id}
        quizId={props.quizId!}
        status={q.status}
        questionId={q.id}
        isQuizActive={quizStatus}
        numberId={q.numberId}
        title={q.title}
        answers={q.answers}
        imgData={q.imgData}
        questionDescription={q.questionDescription}
        attemptsNumber={q.maxAttemptsNumber}
        questionType={q.type}
        timer={props.timer}
        closeQuestionFlag={props.closeQuestionFlag}
        setActiveTimer={props.setActiveTimer}
        setTimer={props.setTimer}
        setQuestionData={props.setQuestionData}
    />)

    useEffect(() => {
        setDelay(quizDelay / 1000)
    }, [quizDelay])

    useEffect(() => {
        if (quizStatus === QUIZ_STATUS.started) {
            //find last question in array
            const lastQuestion = filteredQuestion.find((q) => q.numberId === filteredQuestion.length)

            if (lastQuestion?.status === QUESTION_STATUS.CLOSED) {
                setTimeout(() => {
                    setLastQuestionWasClosed(true)
                }, 30000)
            }
        }

    }, [arrayQuestions])

    return (
        <div>
            {progress && <Progress/>}
            <CommonWrapper quizStatus={quizStatus}>
                <div className={'headerWrap'}>

                    <PageTitle text={'Викторина'}>
                        <div>
                            <Button variant="extended"
                                    type={'submit'}
                                    className={quizUnActive ? 'button primary' : 'button primary red'}
                                    onClick={quizLaunchHandler}
                                    aria-label="add">
                                {quizUnActive ? 'Запустить викторину' : 'Остановить викторину'}
                            </Button>

                            <div className={'start-quiz-block'}/>
                        </div>
                    </PageTitle>

                    <WarningMessage
                        text={'Проводя викторину, помните про задержку прямого эфира, вы можете выставить задержку стрима необходимую для Вашего оптимального проведения викторины'}>
                        <div className={'stream-delay'}>
                            <span>Задержка стрима</span>
                            <BasicSelect items={delaySeconds} defaultValue={`${delay} сек`}
                                         setDelay={setQuizDelayHandler}/>
                        </div>
                    </WarningMessage>

                    {
                        !quizUnActive && <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                '& > *': {
                                    m: 1,
                                },
                            }}
                        >
                            <OverlayButtonsBlock/>
                        </Box>
                    }
                </div>
                <TableStyles>
                    <QuizTable lastBlock={""}/>
                </TableStyles>
                <div className={'quiz-question-scroll-block'}>
                    {questions}
                </div>
            </CommonWrapper>

            {
                openModal && <Modal quizId={props.quizId!}
                                    title={'Завершить викторину'}
                                    text={'Вы действительно хотите завершить текущую викторину'}
                                    setOpenModal={setOpenModal}
                />
            }

            {
                lastQuestionWasClosed && <Modal quizId={props.quizId!}
                                                title={'Завершить викторину'}
                                                text={'Последний вопрос был пройден, хотите завершить викторину'}
                                                setOpenModal={setLastQuestionWasClosed}
                />
            }
        </div>
    )
})

