import React from "react";
import {OverlayType, OVERLAY_TYPES} from "../../../api/api-types";
import s from "../owner-styles/animation.module.css";
import {CSSTransition} from "react-transition-group";

type ParticipantPropsType = {
    index: number
    avatarURL: string
    name: string
    points: number
}

export const Participant: React.FC<ParticipantPropsType> = React.memo((
    {
        index,
        avatarURL,
        name,
        points
    }
) => {
    //const top20Points = getOverlayByType(meaningOverlays, OVERLAY_TYPES.TOP20_POINTS);

    return (
        <>
            <div className={s.participantItem}>
                <div className={s.participantBox}>
                    <div className={s.participant}>
                        <div>{(index + 1)}</div>
                        <div className={s.avatar}>
                            <img src={avatarURL} alt={'Avatar url'}/>
                        </div>
                        <div className={s.participantName}>{name}</div>
                    </div>
                    {
                        // <CSSTransition
                        //     in={top20Points?.active}
                        //     timeout={600}
                        //     classNames="alert"
                        //     unmountOnExit
                        // >
                            <div className={s.points}>
                                <div>{points}</div>
                            </div>
                        // </CSSTransition>

                    }
                </div>
            </div>
        </>
    );
})