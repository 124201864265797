import {applyMiddleware, combineReducers, legacy_createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {appReducer} from "./app-reducer";
import {settingsReducer} from "./settings-reducer";
import {quizReducer} from "./quiz-reducer";
import {statisticsReducer} from "./statistick-reducer";
import {questionReducer} from './questions-reducer';
import {participantsRegistrationInfoReducer} from "./verify-participant-reducer";
import {overlaysReducer} from "./overlays-reducer";
import { composeWithDevTools } from 'redux-devtools-extension';

export type AppStateType = ReturnType<typeof reducers>

let reducers = combineReducers({
    app: appReducer,
    settings: settingsReducer,
    quiz: quizReducer,
    statistics: statisticsReducer,
    questions: questionReducer,
    participantsRegistrationInfo: participantsRegistrationInfoReducer,
    overlays: overlaysReducer,
})

let store = legacy_createStore(
    reducers,
    composeWithDevTools(
    applyMiddleware(thunkMiddleware)
));


export default store;

// @ts-ignore
window.store = store