import styled from "styled-components";

export const AccountWrapper = styled.div`
  display: flex;
  column-gap: 13px;

  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 16px;
    line-height: 20px;
  }

  div {
    display: flex;
    flex-direction: column;
  }`