import {
    CheckedUserVK,
    DataForVkRequestReposts,
    MappedParticipantData,
    ResponseVKType,
    VideoDataResponseType,
    VideosVk, VkAPIPostData,
    VkAPIPostDeleted,
    VKRepost,
    VKResponse
} from './types/vk-types'
import {logger} from '../../logger'
import {fillRepostedParticipants, rememberLoggedUser} from "../../utils/vkHelpers";



export const vkAPI = {
    version: '5.131',
    maxCountThatCanGiveVK : '1000',


    async initVk():Promise<void> {
        this.checkVKReadyStatus()
        const apiId = process.env.REACT_APP_API_ID_VK || '8204886'
        await window.VK!.init({apiId})
    },

// Если ВК скрипт загружен - делаем авторизацию
    async vkAuthorize(): Promise<boolean> {
        this.checkVKReadyStatus()
        await this.initVk()

        return new Promise((res, rej) => {
                window.VK!.Auth.login(async (response: VKResponse) => {
                    if (response.status === 'connected') {
                        logger.debug('response from VK Oauth', response)
                        const user = await this.getUsers([response.session.user.id])
                        sessionStorage.setItem('userVK',JSON.stringify(user))
                        rememberLoggedUser(response.session.expire)
                        res(true)
                    } else {
                        rej(false)
                    }
                }, 8192) // friends(2), video(16), wall(8192), groups(262144) - скоупы запрашиваемые у приложения для работы ВК АПИ
            } // https://vk.com/dev/permissions
        )
    },

    async getUsers(usersIds: string[]): Promise<CheckedUserVK[]> {
        this.checkVKReadyStatus()

        return new Promise<CheckedUserVK[]>((resolve, reject) => {
                window.VK!.Api.call('users.get', {
                    user_ids: usersIds,
                    fields:'photo_50',
                    v: this.version
                }, (res: ResponseVKType<CheckedUserVK[]>) => {
                    logger.debug('-> res checkUsersVKForClosedAcc', res)
                    if (res.response) {
                        resolve(res.response)
                    } else {
                        reject(new Error('vk users.get api call doesn\'t return result: ' + JSON.stringify(res)))
                    }
                })
            }
        )
    },

    /*
 функция по ключу типа 4255985_145 получает информацию о посте, обрабатывает данные и направляет в функцию
 которая получает информацию о видео
  @param posts - массив строк вида 4255985_145, где слева от _ это ID VK пользователя, а с права ID поста
   */
    async getPostsByIds(
        posts: string[]
    ): Promise<(VkAPIPostDeleted | VkAPIPostData)[]> {
        this.checkVKReadyStatus()

        return new Promise<(VkAPIPostDeleted | VkAPIPostData)[]>((resolve, reject) => {
            window.VK!.Api.call('wall.getById', {
                posts,
                v: this.version
            }, (res: ResponseVKType<(VkAPIPostDeleted | VkAPIPostData)[]>) => {
                if (res) {
                    resolve(res.response)
                } else {
                    reject(`wall.getById doesn't return result`)
                }
            })
        })
    },

    async getPostsFromWall(
        userId: string
    ): Promise<VkAPIPostData[]> {
        this.checkVKReadyStatus()

        return await new Promise<VkAPIPostData[]>((resolve, reject) => {
            window.VK!.Api.call('wall.get', {owner_id: userId, v: this.version}, function (res: {
                response: {
                    items: VkAPIPostData[]
                }
            }) {
                if (res.response && res.response.items) {
                    resolve(res.response.items)
                } else {
                    reject()
                }
            })
        })
    },


    /*
     Функция через АПИ ВК запрашивает информацию о видео размещенном в посте
      @param data - массив строк вида 4255985_145, где слева от _ это ID VK пользователя, а с права ID видео в посте
     */
    async getVideos(
        data: string[]
    ): Promise<VideoDataResponseType[]> {
        this.checkVKReadyStatus()

        if (!data.length) return []

        return new Promise<VideoDataResponseType[]>(async (resolve, reject) => {
                window.VK!.Api.call('video.get', {videos: data, v: this.version}, (videoRes: ResponseVKType<VideosVk>) => {
                    logger.debug('video response from VK', videoRes.response)
                    if (videoRes.response && videoRes.response.items) {
                        resolve(videoRes.response.items)
                    } else {
                        reject(`video.get doesn't return result`)
                    }
                })
            }
        )
    },

    checkVKReadyStatus() {
        if (!window.VK) throw new Error('VK SHOULD BE INITIALIZED BEFORE USING')
    },

    async getUsersWhoSendRepost(mappedVKUser: MappedParticipantData, dataToRequestForReposts: DataForVkRequestReposts) {
        this.checkVKReadyStatus()

        const userReposted = {...mappedVKUser}
        const quantityUsers = Object.keys(userReposted)

        if (quantityUsers.length > 1000) {
            for (let i = 0; i < quantityUsers.length; i += 1000) {
                const requestForReposts = {...dataToRequestForReposts, offset: i.toString(), count: this.maxCountThatCanGiveVK}
                await this.getRepostsFromWall(requestForReposts, userReposted)
            }
        } else {
            await this.getRepostsFromWall({...dataToRequestForReposts, offset: '0', count: this.maxCountThatCanGiveVK}, userReposted)
        }

        return userReposted
    },

    getRepostsFromWall(dataToRequestForReposts: DataForVkRequestReposts, userReposted: MappedParticipantData):Promise<void> {
        logger.debug('User object dataToRequestForReposts', dataToRequestForReposts)
        return new Promise<void>((resolve, reject) => {
            window.VK!.Api.call('wall.getReposts', dataToRequestForReposts, (reposts: ResponseVKType<VKRepost>) => {
                logger.debug('wall reposts from VK', reposts.response)
                try {
                    const repostsFromVk = reposts.response?.items
                    if (reposts.response && repostsFromVk?.length > 0) {
                        fillRepostedParticipants(reposts.response, userReposted)
                    }
                    resolve()
                } catch {
                    reject(`wall.getReposts doesn't return result`)
                }
            })
        })
    }
}

