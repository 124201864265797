import React, {SetStateAction, useState, Dispatch} from "react";
import {TextField} from "@material-ui/core";
import {ValidateInputWrap} from "./styles";
import {Button, ErrorSpan, SuccessSpan} from "../../../common-styles/Common.styles";
import {
    userIdVK,
    desktopFacebookRegex,
    facebookWallPostLinkWithoutProfileId,
    fbIdMatchRegex, idMatchRegexWithoutProfileId,
    idRegex,
    mobileFacebookRegex, matchRegexVk, isCorrectFBLink, facebookLink
} from "../../../utils/regex";
import {useDispatch} from "react-redux";
import {SOCIAL_NETWORK_PROVIDERS} from "../../../api/api-types";
import {submitIdForVerification} from "../../../redux/verify-participant-reducer";

export const validationModalText = 'Данный вид валидации предназначен, что бы провалидировать в ручную и подтвердить доступ к викторине для пользователя ставьте ссылку на пост пользователя, который он репостнул и нажмите подтвердить!';

export type VerificationParticipantData = {
    id: string
    socialNetworkProvider: SOCIAL_NETWORK_PROVIDERS
}

type ValidateInputType = {
    setOpen: Dispatch<SetStateAction<boolean>>;
}

type NotifyType = {
    error: null | string,
    success: null | string,
}

export const ValidateInput: React.FC<ValidateInputType> = (props) => {

    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [socialNetworkProvider, setSocialNetworkProvider] = useState<SOCIAL_NETWORK_PROVIDERS | null>(null);
    const [notify, setNotify] = useState<NotifyType>({
        error: null,
        success: null,
    })
    console.log(id)
    const submitIdForVerificationHandler = () => {
        if (!id) {
            setNotify(state => ({...state, error: "Укажите ссылку", success: null}));
            return;
        }
        dispatch(submitIdForVerification({id, socialNetworkProvider: socialNetworkProvider!}))
        props.setOpen(state => !state);
    }

    const linkHandler = (link: string) => {
        const isFacebookLink = facebookLink.test(link);
        const isMobileFacebookLink = mobileFacebookRegex.test(link);
        const isDesktopFacebookLink = desktopFacebookRegex.test(link);
        const mobileIsCorrectFacebookLink = isCorrectFBLink.test(link);
        const isFacebookWallPostLinkWithoutProfileId = facebookWallPostLinkWithoutProfileId.test(link);
        const isUserIdVK = userIdVK.test(link)
        const hasId = idRegex.test(link);

        if (
            ((isMobileFacebookLink || isDesktopFacebookLink) && hasId && mobileIsCorrectFacebookLink)
            || isFacebookWallPostLinkWithoutProfileId && mobileIsCorrectFacebookLink
            || isUserIdVK
        ) {
            const fbIdResult = getFBRepostId(link);
            const vKId = getVkId(link);

            if (!fbIdResult && !vKId) {
                setNotify(state => ({...state, error: "Ссылка невалидная!", success: null}));
                return;
            }

            if (fbIdResult) {
                setHandler(fbIdResult, SOCIAL_NETWORK_PROVIDERS.FACEBOOK);
                return;
            }

            if (vKId) {
                setHandler(vKId, SOCIAL_NETWORK_PROVIDERS.VK);
                return;
            }
        } else {
            if (isFacebookLink && !mobileIsCorrectFacebookLink) {
                setNotify(state =>
                    ({...state, error: "Нарушено правило регистрации, делать репост в мобильной версии запрещено!", success: null})
                );
                return;
            }
            setId('')
            setNotify(state => ({...state, error: "Ссылка невалидная!", success: null}));
        }
    };

    const getFBRepostId = (fbLink: string) => {
        if (facebookWallPostLinkWithoutProfileId.test(fbLink)) {
            //если у участника нет id поста, берем name из ссылки, этот же name будет в
            //facebook modal в качестве id
            const match = fbLink.match(idMatchRegexWithoutProfileId);
            if (match && match[1]) {
                return match[1];
            } else {
                return null;
            }
        } else {
            const idFacebookMatch = fbLink.match(fbIdMatchRegex);
            if (idFacebookMatch) {
                const str = idFacebookMatch![0]
                const idStartIndex = str.indexOf("id="); // Индекс начала значения "id"
                if (idStartIndex !== -1) {
                    return str.substring(idStartIndex + 3); // Вырезаем все символы после "id="
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }
    };

    const getVkId = (link: string) => {
        const match = link.match(matchRegexVk);
        if (match && match[1]) return match[1];
        return null;
    }

    const setHandler = (id: string, socialNetworkProvider: SOCIAL_NETWORK_PROVIDERS) => {
        setId(id.trim());
        setSocialNetworkProvider(socialNetworkProvider);
        setNotify(state => ({...state, success: "Ссылка валидная!", error: null}));
    }


    return (
        <ValidateInputWrap>
            <div>
                <p className={'description'}>
                    Данный вид валидации предназначен,
                    что бы провалидировать в ручную и подтвердить доступ к викторине для пользователя!
                </p>
                <p className={'description'}>
                    Вставьте ссылку на пост пользователя, который он репостнул и нажмите подтвердить!
                </p>
            </div>
            <TextField
                style={{width: '100%'}}
                error={!!notify.error}
                label="Ссылка на репост пользователя"
                InputProps={{
                    readOnly: false,
                }}
                variant="standard"
                onChange={(e) => linkHandler(e.currentTarget.value)}
            />

            {
                notify.error && <ErrorSpan>{notify.error}</ErrorSpan>
            }

            {
                notify.success && <SuccessSpan>{notify.success}</SuccessSpan>
            }

            <div className={'button-block'}>
                <Button className={'button primary'}
                        variant="extended"
                        type={'button'}
                        aria-label="add"
                        disabled={!!notify.error}
                        onClick={submitIdForVerificationHandler}>
                    Провалидировать
                </Button>
            </div>
        </ValidateInputWrap>
    );
}