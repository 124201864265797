import styled from "styled-components";
import monthImg from "../../../assets/svg/calendar_month.svg"
export const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    padding-bottom: 5px;

    & input {
      border: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      width: 100%;
    }
  }
  .react-datepicker__input-container {
    &:before {
      content: '';
      background-image: url(${monthImg});
      position: absolute;
      right: 0;
      top: 2px;
      width: 20px;
      height: 20px;
    }
  }
  .react-datepicker {
    border-radius: 2px;
    border: 1px solid #D9D7D8;
    background: #FEFEFE;
    padding: 16px 24px;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
    text-align: left;
  }

  // Стили для строки месяца и года
  .react-datepicker__current-month {
    color: #05080B;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-left: 0.4rem;
  }

  // Общие стили для стрелок
  .react-datepicker__navigation-icon {
    &:before {
      border-color: #05080B;
      border-width: 2px 2px 0 0;
      width: 7px;
      height: 7px;
    }

  }

  // Стили для стрелки назад
  .react-datepicker__navigation--previous {
    top: 22px;
    left: initial;
    right: 62px;
  }

  // Стили для стрелки вперед
  .react-datepicker__navigation--next {
    top: 22px;
    left: initial;
    right: 24px;
  }

  // wrapper дней месяца
  .react-datepicker__month {
    margin: 20px 0 0;
  }

  // wrapper дней недели ( пн, вт .... )
  .react-datepicker__day-names {
    margin-top: 20px;
  }

  // День недели ( пн, вт .... )
  .react-datepicker__day-name {
    color: #8C8889;
    font-weight: 500;
    line-height: 24px;
  }

  .react-datepicker__day {
    font-size: 14px;
    font-weight: 500;
  }

  // Выбранный день
  .react-datepicker__day--selected {
    border-radius: 14px;
    background: #2D8CE4;
    box-shadow: 0 0 3px 4px rgba(213, 232, 250, 1);
    font-weight: 500;
    font-size: 14px;
  }

  // Стилизация даты которая не входит в текущий месяц
  .react-datepicker__day--outside-month {
    color: #B3B0B1;
    font-weight: 500;
    line-height: 24px;
  }

  // Враппер для заголовка времени и инпута
  .react-datepicker__input-time-container {
    margin: 12px 0 0 0.4rem;
  }

  // Стилизация инпута времени
  .react-datepicker-time__input > input {
    border-radius: 2px;
    border: 1px solid #D7D8D9;
    padding: 6px 12px;

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  // Стилизация текста инпута времени
  .react-datepicker-time__caption {
    font-weight: 500;
    line-height: 24px
  }
`