import {useCallback, useEffect, useState} from 'react';

export const usePictureToggle = () => {
  const KEY_NAME_ESC = 'Escape';
  const KEY_EVENT_TYPE = 'keydown';
  const [isZoomImg, setIsZoomImg] = useState(false);

  const handlePicture = useCallback(() => {
    setIsZoomImg(toggle => !toggle);
  },[]);

  const closeZoomPicture = useCallback(() => {
    setIsZoomImg(false)
  },[])

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === KEY_NAME_ESC) {
        event.preventDefault();
        setIsZoomImg(false);
      }
    };
    document.addEventListener(KEY_EVENT_TYPE, keyDownHandler,true);
    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, keyDownHandler,true);
    };
  }, []);

  return { isZoomImg, handlePicture, closeZoomPicture }  as const;
};
