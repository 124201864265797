import React from "react";
import ReactDOM from "react-dom";
import {FormikHelpers, FormikState} from "formik/dist/types";
import {QUESTION_FRONT_TYPES} from "../types";
import {ErrorSpan, UploadImageBlock} from "../../../../common-styles/Common.styles";
import zoomLoadedImg from "../../../../assets/svg/zoom-loaded-img.svg";
import closeLoadedImg from "../../../../assets/svg/close-loaded-img .svg";
import zoom_out from "../../../../assets/svg/zoom-out-picture.svg";
import {UploadButton} from "./uploadButton/UploadButton";

import {Callback} from "../../../../types";
import {useOutsideClick} from "../../../../hooks";


type ImageBlockPropsType = {
    formik: FormikState<any> & FormikHelpers<any>
    isZoomImg: boolean
    handlePicture: Callback
    closeZoomPicture: Callback

}
export const ImageBlock: React.FC<ImageBlockPropsType> = (props) => {
    const {isZoomImg, handlePicture, closeZoomPicture} = props
    const ref = useOutsideClick(closeZoomPicture);

    return (
        <>
            {
                props.formik.values.questionType === QUESTION_FRONT_TYPES.image &&
                <UploadImageBlock>
                    {
                        props.formik.values.image &&
                        <div className={'wrap-pictures-control'}>
                            <div className={'pictures-control'}>
                                <div>
                                    <img className={'control-img'}
                                         src={zoomLoadedImg}
                                         onClick={handlePicture}
                                         alt="Zoom loaded img"/>
                                </div>
                                <div>
                                    <img className={'control-img'}
                                         src={closeLoadedImg}
                                         onClick={() => props.formik.setFieldValue('image', '')}
                                         alt="Delete loaded img"
                                    />
                                </div>
                            </div>

                            {
                                isZoomImg
                                    ? ReactDOM.createPortal(
                                        <div className={'zoom-block-portal'}>
                                            <img className={'zoom-out-portal'}
                                                 src={zoom_out}
                                                 alt="Zoom out img"
                                                 onClick={handlePicture}/>
                                            <img className={'zoom-img-portal'}
                                                 src={props.formik.values.image}
                                                 alt={'Loaded image'}
                                                 ref={ref}/>
                                        </div>,
                                        document.body
                                    )
                                    : <div>
                                        <img className={'loaded-image'}
                                             src={props.formik.values.image}
                                             alt={'Loaded image'}/>
                                    </div>
                            }

                        </div>
                    }

                    <div className={'upload-image-button'}>
                        <UploadButton formik={props.formik}/>
                    </div>
                    {
                        props.formik.touched.image && props.formik.errors.image &&
                        <ErrorSpan>{props.formik.errors.image}</ErrorSpan>
                    }
                </UploadImageBlock>
            }
        </>

    )
}