import {ParticipantsVKType, vkAPIType} from '../api/api-types'
import {logger} from '../logger'
import {MappedParticipantData} from '../api/social-network-api/types/vk-types'
import {vkAPI} from '../api/social-network-api/vkAPI'
import {VkUtils} from './vkUtils'

export class ParticipantsVKManager {

    constructor(protected readonly quizId: string,
                protected readonly repostedLink: string,
                protected readonly vkAPI: vkAPIType,
                protected readonly vkUtils: VkUtils,
    ) {}

    async checkParticipants() {

        const participants = await this.getParticipantsFromCoreServer()

        if (participants.length === 0) {
            /* we send empty array because we should know when notice participant and sockets implemented in post request */
            await this.vkAPI.sendParticipantsPostCheckResult([])
        }

        await this.checkDataViaVK(participants, this.sentResultToCoreServer.bind(this))
    }

    private async getParticipantsFromCoreServer(): Promise<ParticipantsVKType[]> {

        const {data: participants} = await this.vkAPI.getParticipants()
        logger.info('participants that should be checked in VK', participants)

        return participants
    }

    private async checkDataViaVK(participants: ParticipantsVKType[], sentResultToCoreServer: OmitThisParameter<(checkingResult: ParticipantsVKType[]) => Promise<void>>) {

        const mappedVKUser = this.vkUtils.prepareUsersForRequestViaVk(participants)
        logger.info('posts ids from links and prepared vk data: ', {mappedVKUser})

        const usersReposted = await this.startCheckingForPostsIdsPortion(mappedVKUser)

        this.vkUtils.checkingParticipantsOnRepost(usersReposted)
        logger.info('users how reposted post, ready send to server ', {usersReposted})

        const preparedResult = this.vkUtils.prepareResultForServer(usersReposted)

        await sentResultToCoreServer(preparedResult)
    }

    private async startCheckingForPostsIdsPortion(mappedVKUser: MappedParticipantData): Promise<MappedParticipantData> {

        const dataToRequestForReposts = this.vkUtils.getDataToRequestForReposts(this.repostedLink)

       return await vkAPI.getUsersWhoSendRepost(mappedVKUser, dataToRequestForReposts)
    }

    private async sentResultToCoreServer(checkingResult: ParticipantsVKType[]): Promise<void> {
        if (checkingResult.length === 0) return
        await this.vkAPI.sendParticipantsPostCheckResult(checkingResult)
        return
    }
}


/* old flow */
// export class ParticipantsVKManager {
//     constructor(protected readonly quizId: string,
//                 protected readonly youtubeLink: string,
//                 protected readonly vkAPI: vkAPIType,
//                 protected readonly vkUtils: VkUtils,
//     ) {
//     }
//
//     async checkParticipants() {
//         // 1. step get participants that should be checked
//         const participants = await this.getParticipantsFromCoreServer()
//
//         if (participants.length === 0) {
//             /* we send empty array because we should know when notice participant and sockets implemented in post request*/
//             await this.vkAPI.sendParticipantsPostCheckResult([])
//         }
//
//         // 2. check participants
//         await this.checkDataViaVK(participants, /* 3. save portions of checking result */ this.sentResultToCoreServer.bind(this))
//     }
//
//     private async getParticipantsFromCoreServer(): Promise<Array<VKParticipantsType>> {
//         const {data: participants} = await this.vkAPI.getPendingVKParticipantsAndMutateParticipantsWithBadLink()
//         logger.info('participants that should be checked in VK', participants)
//         return participants
//     }
//
//     /*
//     @param processPartialResultCallback function that be called for processing partial ready data prepared via vk
//      */
//     private async checkDataViaVK(participants: Array<VKParticipantsType>,
//                                  processPartialResultCallback: (result: ParticipantsVKType[]) => Promise<void>): Promise<void> {
//         if (participants.length === 0) return
//
//         const mappedVKUser = await this.vkUtils.extractPostsIdsFromLinksAndPrepareData(participants)
//         logger.info('posts ids from links and prepared vk data: ', {mappedVKUser})
//
//         await this.checkUsersViaVKForClosedAccounts(mappedVKUser)
//
//         const postIds = this.extractCorrectPostsIds(mappedVKUser)
//
//         logger.info('collections of postIds: ', postIds)
//         if (postIds.length === 1 && postIds[0] === null) {
//
//             await this.earlyReturnIfUsersAreNotHaveValidLink(mappedVKUser, processPartialResultCallback)
//             return
//         }
//
//         for (let pageNumber = 1; pageNumber <= this.getPortionsCount(postIds); pageNumber++) {
//             const postsIdsForProcessing = pagingUtils.getSubArray(postIds, pageNumber, this.vkUtils.POSTS_IDS_MAX_COUNT_FOR_SINGLE_REQUEST)
//
//             logger.info('start process portion number: ', pageNumber)
//             logger.info('elements count for processing: ', postsIdsForProcessing.length)
//
//             await this.startCheckingForPostsIdsPortion(postsIdsForProcessing, mappedVKUser, processPartialResultCallback)
//         }
//     }
//
//
//     private getPortionsCount(postIds: string[]) {
//         return pagingUtils.getPagesCount(postIds.length, this.vkUtils.POSTS_IDS_MAX_COUNT_FOR_SINGLE_REQUEST)
//     }
//
//     private async startCheckingForPostsIdsPortion(postIds: string[], mappedVKUser: MapParticipantData, proccessPartialResultCallback: (result: ParticipantStatusType[]) => Promise<void>) {
//         try {
//             let participantsPostsCheckingResult: ParticipantStatusType[] = []
//
//             const userErrorMap: ErrorMapType = {}
//
//             // 1. get posts from VK
//             const vkPosts = await vkAPI.getPostsByIds(postIds)
//
//             // 2. prepare data for video fetching
//             const preparedDataForVideoRequest = this.vkUtils.prepareVideoRequestData(
//                 vkPosts,
//                 mappedVKUser,
//                 userErrorMap
//             )
//
//             // 3. fix bad posts
//             this.fixBadPosts(preparedDataForVideoRequest, postIds, mappedVKUser, userErrorMap)
//
//             // 4. fetch videos from VK by single request
//             const vkVideos = await vkAPI.getVideos(preparedDataForVideoRequest) // todo: точно в массиве этом нет плохих потов?
//
//             // 5. check videos
//             await this.checkVideosForCorrectVideoLinkInside(vkVideos, mappedVKUser, this.youtubeLink)
//
//
//             // 6. prepare result array
//             this.vkUtils.prepareResult(mappedVKUser, participantsPostsCheckingResult)
//
//
//             logger.info('participantsPostsCheckingResult: ', participantsPostsCheckingResult)
//
//             // only debug logging
//             // participantsPostsCheckingResult.forEach(r => {
//             //     logger.debug(r.participantId, r)
//             // })
//
//             // 7. save portions of result
//             await proccessPartialResultCallback(participantsPostsCheckingResult)
//
//         } catch (e) {
//             // Ни как не обробатывем ощибку
//             console.log('error ', e)
//         }
//     }
//
//     /*
//       Check that video has link to necessary youtube stream
//         @param vkVideos - videos from VK API
//      */
//     checkVideosForCorrectVideoLinkInside(
//         vkVideos: VideoDataResponseType[],
//         mappedData: MapParticipantData,
//         youtubeLink: string,
//     ): void {
//         const youtubeStreamId = this.vkUtils.getStreamIdFromYoutubeLink(youtubeLink)
//         vkVideos.forEach((video: VideoDataResponseType) => {
//             const isCorrectVideo = video.player.includes(youtubeStreamId)
//
//             mappedData[video.owner_id.toString()].dataFromVK = this.vkUtils.createParticipantStatusObj(
//                 mappedData,
//                 video.owner_id.toString(),
//                 isCorrectVideo ? PARTICIPANT_STATUS.APPROVED : PARTICIPANT_STATUS.BAD_LINK,
//                 isCorrectVideo ? '' : 'The post does not contain a link to the current stream'
//             )
//         })
//     }
//
//     /*
//     because we send for example 100 postsIds, but VK return only 89 results,
//     because some accounts Private or other reasons
//     (on the VK side)
//      */
//     private fixBadPosts(preparedDataForVideoRequest: string[], postIds: string[], mappedVKUser: MapParticipantData, userErrorMap: ErrorMapType) {
//         if (preparedDataForVideoRequest.length !== postIds.length) {
//             this.findBadDataAndCreateStatus(postIds, preparedDataForVideoRequest, mappedVKUser, userErrorMap)
//         }
//     }
//
//     private extractCorrectPostsIds(mappedVKUser: MapParticipantData) {
//         return Object.keys(mappedVKUser)
//             .filter(key => mappedVKUser[key].dataFromVK?.newStatus !== PARTICIPANT_STATUS.PRIVATE_ACCT
//                 || mappedVKUser[key].dataFromVK?.newStatus !== PARTICIPANT_STATUS.BAD_LINK
//                 || mappedVKUser[key].postId !== null)
//             .map(key => mappedVKUser[key].postId as string)
//     }
//
//     /*
//          request users via vk and check set status PARTICIPANT_STATUS.PRIVATE_ACCT if account is private
//          */
//     private async checkUsersViaVKForClosedAccounts(mappedVKUser: MapParticipantData) {
//         const vkUsers = await vkAPI.getUsers(Object.keys(mappedVKUser))
//         vkUsers.forEach((user: CheckedUserVK) => {
//                 if (user.is_closed)
//                     mappedVKUser[user.id.toString()].dataFromVK = this.vkUtils.createParticipantStatusObj(
//                         mappedVKUser,
//                         user.id.toString(),
//                         PARTICIPANT_STATUS.PRIVATE_ACCT,
//                         'there is no access to the page, privacy settings')
//             }
//         )
//     }
//
//     //todo: to check logic before this method
//     private async sentResultToCoreServer(checkingResult: ParticipantsVKType[]): Promise<void> {
//         if (checkingResult.length === 0) return
//         await this.vkAPI.sendParticipantsPostCheckResult(checkingResult)
//
//         return
//     }
//
//     /*
//        Функция сравнивает входные и выходные данные для ВК АПИ запроса и проверяет, на какие данные
//    АПИ не вернула ничего и создает объект проверки
//    */
//     findBadDataAndCreateStatus(
//         dataThatBeSentToVKforChecking: string[],
//         responseData: string[],
//         mappedData: MapParticipantData,
//         userErrorMap: ErrorMapType,
//     ) {
//         dataThatBeSentToVKforChecking.forEach(item => {
//             if (item === null) return
//             const vkUserId = item.split('_')[0]
//             const isVKUserIdInResponse = responseData.some(el => {
//                 const parts = el.split('_')
//                 return parts[0] === vkUserId
//             })
//             if (!isVKUserIdInResponse && !userErrorMap[vkUserId]) {
//                 mappedData[vkUserId].dataFromVK = this.vkUtils.createParticipantStatusObj(
//                     mappedData,
//                     vkUserId,
//                     PARTICIPANT_STATUS.BAD_LINK,
//                     'Invalid post link or not public VK account',
//                 )
//             }
//         })
//     }
//
//     private async earlyReturnIfUsersAreNotHaveValidLink(mappedVKUser: MapParticipantData, processPartialResultCallback: (result: ParticipantsVKType[]) => Promise<void>) {
//         const participantsPostsCheckingResult: ParticipantsVKType[] = []
//         this.vkUtils.prepareResult(mappedVKUser, participantsPostsCheckingResult)
//
//         await processPartialResultCallback(participantsPostsCheckingResult)
//     }
//
// }


