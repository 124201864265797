import styled from "styled-components";

export const ValidateInputWrap = styled.div`
  padding: 0 26px;

  .description {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 20px 0;
  }

  .button-block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;
    padding-bottom: 36px;
  }
`