import React from 'react';
import {useSelector} from "react-redux";
import {AppStateType} from "../../redux/redux-store";
import {OVERLAY_TYPES, OverlayType} from "../../api/api-types";
import { CSSTransition } from 'react-transition-group';
import s from './owner-styles/animation.module.css'


export const TextBlock: React.FC = () => {

    const textOverlay = useSelector<AppStateType, OverlayType | undefined>((state) => state.overlays.meaningOverlays.find(el => el.type === OVERLAY_TYPES.TEXT))
    const text = useSelector<AppStateType, string | null>((state) => state.overlays.text)

    return (
        <>
            {
                <CSSTransition
                    in={textOverlay?.active}
                    timeout={600}
                    classNames="alert"
                    unmountOnExit
                    >

                    <div className={s.textBlock}>
                    <p>
                        {text}
                    </p>
                    </div>
                </CSSTransition>
            }
        </>
    );
}
