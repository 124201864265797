import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getRegistrationInfoByFbParticipants} from "../../../redux/verify-participant-reducer";
import {getQuizId, getRepostedLink, setAlertMessage} from "../../../redux/settings-reducer";
import {NOTIFICATION_QUIZ_MESSAGE} from "../../../constants";
import {PageTitle} from "../../../common-components/PageTitle/PageTitle";
import {SocialNetworkButtonsBlock} from "../socialNetworkLogin/styles";
import {Button, VerifyParticipantsBlock} from "../../../common-styles/Common.styles";
import clockIcon from "../../../assets/svg/verificateParticipants/clock.svg";
import checkmarkIcon from "../../../assets/svg/verificateParticipants/checkmark.svg";
import {BasicTooltip} from "../../../common-components/Tooltip/BasicTooltip/BasicTooltip";
import infoIcon from "../../../assets/svg/info-icon.svg";
import alertIcon from "../../../assets/svg/verificateParticipants/alert.svg";
import errorCrossIcon from "../../../assets/svg/verificateParticipants/error-cross.svg";
import {FbParticipantsWrap, PostLink} from "./index.style";
import {Modal} from "../../../common-components/Modal";
import {ValidateInput, validationModalText} from "../validateInput/ValidateInput";

export const FbParticipants = () => {
    const dispatch = useDispatch()
    const repostedLink = useSelector(getRepostedLink)
    const quizId = useSelector(getQuizId)

    //For modal
    const [isOpen, setOpen] = useState(false);

    const {
        pendingParticipants,
        approvedParticipants,
        blockedParticipants,
        notApprovedParticipants
    } = useSelector(getRegistrationInfoByFbParticipants)

    const copyTokenToBuffer = async () => {
        const token = sessionStorage.getItem("token")
        await navigator.clipboard.writeText(token!)
        dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.TOKEN_COPIED))
    }

    return (
        <FbParticipantsWrap>
            <PageTitle
                text={'Проверка участников Facebook'}/>
            <SocialNetworkButtonsBlock>
                <Button className={'button primary'}
                        variant="extended"
                        type={'button'}
                        aria-label="add"
                        onClick={copyTokenToBuffer}>
                    Скопировать токен
                </Button>

                <Button className={'button blue'}
                        variant="extended"
                        type={'button'}
                        aria-label="add"
                        onClick={() => setOpen(true)}>
                    Ручная валидация
                </Button>
            </SocialNetworkButtonsBlock>
            <PostLink>
                {repostedLink?.fb && <a href={repostedLink.fb} target="_blank">Ссылка на пост для репоста</a>}
            </PostLink>

            <VerifyParticipantsBlock>
                <div className={'CheckWrap'}>
                    <div className={'Box'}>
                        <img src={clockIcon} alt=""/>
                        <h4>Ожидают проверку</h4>
                        <span>{pendingParticipants} человек</span>
                    </div>

                    <div className={'Box'}>
                        <img src={checkmarkIcon} alt=""/>
                        <div>
                            <h4>Прошли проверку</h4>
                            <BasicTooltip placement={'bottom-start'}
                                          title={"Успешно зарегистрированных пользователей, которые могут участвовать в викторине!"}>
                                <img src={infoIcon} alt=""/>
                            </BasicTooltip>
                        </div>
                        <span>{approvedParticipants} человек</span>
                    </div>

                    <div className={'Box'}>
                        <img src={alertIcon} alt=""/>
                        <div>
                            <h4>Не выполнили условия</h4>
                            <BasicTooltip placement={'bottom-start'}
                                          title={"Пользователи, которые не выполнили условия регистрации, но имеют возможность зарегистрироваться еще раз!"}>
                                <img src={infoIcon} alt=""/>
                            </BasicTooltip>
                        </div>
                        <span>{notApprovedParticipants} человек</span>
                    </div>

                    <div className={'Box'}>
                        <img src={errorCrossIcon} alt=""/>
                        <div>
                            <h4>Заблокированы</h4>
                            <BasicTooltip placement={'bottom-end'}
                                          title={"Пользователи, которые не выполнили условия регистрации более 10 раз и больше не имеют возможности зарегистрироваться на викторину!"}>
                                <img src={infoIcon} alt=""/>
                            </BasicTooltip>
                        </div>
                        <span>{blockedParticipants} человек</span>
                    </div>
                </div>
            </VerifyParticipantsBlock>
            {
                isOpen &&
                <Modal
                    quizId={quizId} title={'Ручная валидация'}
                    setOpenModal={() => setOpen(state => !state)}
                    text={validationModalText}>
                    <ValidateInput setOpen={setOpen}/>
                </Modal>
            }
        </FbParticipantsWrap>
    );
}



