import React, {memo} from "react";
import zoomOutImg from "../assets/svg/zoom-out-picture.svg";
import zoomLoadedImg from "../assets/svg/zoom-loaded-img.svg";
import {ImgDataType} from "../api/api-types";
import {useOutsideClick} from "../hooks";
import {Callback} from "../types";

type PropsType = {
    imgData: ImgDataType | null,
    isZoomImg?: boolean
    handlePicture?: Callback
    closeZoomPicture?: Callback
}

export const ImgByQuestion: React.FC<PropsType> = memo((props) => {
    const {imgData, isZoomImg, handlePicture, closeZoomPicture} = props
    const ref = useOutsideClick(closeZoomPicture);

    const iconZoomIn = isZoomImg && <img className={'zoom-out'}
                                         src={zoomOutImg}
                                         alt="Zoom out img"
                                         onClick={closeZoomPicture}/>

    const iconZoomOut = !isZoomImg && <img className={'zoom'}
                                           src={zoomLoadedImg}
                                           alt={'Zoom img'}
                                           onClick={handlePicture}/>


    return (
        <div className={'img-block'}>
            <div className={isZoomImg ? 'zoom-container' : ''}>
                <div className={'zoom-block'}>
                    {iconZoomIn}
                    {iconZoomOut}
                    {
                        imgData && <img className={isZoomImg ? 'adaptive-img' : ''}
                                        src={imgData.imgUrl}
                                        alt={'Image'}
                                        ref={ref}
                        />
                    }
                </div>
            </div>
        </div>
    )
})

