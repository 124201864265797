import styled from "styled-components";

export const WinnersStyles = styled.div`
  position: relative;

  .wrap-top-user {
    display: flex;
    align-items: center;
    //padding-bottom: 11px;
    box-shadow: -1px -1px 4px rgba(0, 0, 0, 0.05), 1px 1px 4px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    margin-bottom: 12px;
    background: #fff;

    .link {
      border-bottom: 1px solid #2D8CE4;
    }

    a {
      color: #2D8CE4;
    }

    a:hover {
      color: #61adfa;
    }
  }

  .number-block {
    text-align: center;
    width: 25px;
    height: 25px;
    margin: 0 15px;
    //padding-top: 3px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  .not-top-users {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }

  .winners-scroll-block {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 78%;
  }
`

export const TopUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 55px 6px 0;
  font-weight: 600;


  .avatar-wrapper {
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  img {
    height: 36px;
    width: 36px;
    border-radius: 45px;
    margin-right: 14px;
  }

  .points {
    font-size: 14px;
    line-height: 17px;
  }

`
