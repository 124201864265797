import React from 'react'
import InfiniteScroll from "react-infinite-scroll-component";
import {Participant} from './Participant'
import {
    getPageNumber,
    getParticipantStatistic,
    getSortedTopParticipants,
    getTopParticipantsTotalCount
} from "../../redux/statistick-reducer";
import {useDispatch, useSelector} from "react-redux";
import {useStatisticsInterval} from "../../hooks";
import {WinnersStyles} from './styles';
import {PageTitle} from "../../common-components/PageTitle/PageTitle";
import {Loading} from "../../common-components/Loading";
import {Preloader} from "../../common-components/Preloader/Preloader";


export const Winners: React.FC = () => {
    useStatisticsInterval()

    const dispatch = useDispatch()
    const participants = useSelector(getSortedTopParticipants)
    const pageNumber = useSelector(getPageNumber)
    const totalCount = useSelector(getTopParticipantsTotalCount)

    const getNextPortionParticipants = () => {
        const nextPage = pageNumber + 1;
        dispatch(getParticipantStatistic(nextPage));
    }

    if (!participants) {
        return  <Loading/>
    }

    const mappedParticipants = participants.map((p, i) => <Participant
        key={i}
        accountId={p.accountId}
        avatar={p.avatarURL}
        point={p.points}
        userName={p.name}
        position={(++i)}
        socialNetworkProvider={p.socialNetworkProvider}
    />)


    return (
        <WinnersStyles>
            <PageTitle text={'Топ участников'}/>
            <div className={'winners-scroll-block'}>
                <InfiniteScroll
                    dataLength={participants.length}
                    next={getNextPortionParticipants}
                    hasMore={!(participants.length === totalCount)} // show text about nothing anymore download "endMessage"
                    loader={<Preloader/>}
                    endMessage={<></>}
                    style={{position: 'relative'}}
                >
                {mappedParticipants}
                </InfiniteScroll>
            </div>
        </WinnersStyles>
    )
}




