import * as React from 'react';
import Button from '@mui/material/Button';
import {FormikHelpers, FormikState} from "formik/dist/types";
import { WrapButton } from './style';


type UploadPropsType = {
    formik: FormikState<any> & FormikHelpers<any>
}

export const UploadButton = (props: UploadPropsType) => {

    const formatBytes = (bytes: number, decimals = 2) => {
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['байт', 'КБ', 'МБ', 'ГБ'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const uploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files!.length) {
            const image = 'image'
            const maxFileSize = 3145728; //3 MB
            const file = event.currentTarget.files![0];
            const currentFileSize = event.currentTarget.files![0].size;
            const fileType = file.type.split('/')[0];

            if (currentFileSize > maxFileSize) {
                alert(`Допускается максимальный размер файла для загрузки до ${formatBytes(maxFileSize)} - ваш: ${formatBytes(currentFileSize)} `);
                return;
            }

            if (fileType !== image) {
                //If an attempt was made to upload a file not image
                const currentImageInState = props.formik.values.image;
                if (currentImageInState) {
                    props.formik.setFieldValue(image, '');
                }
                props.formik.setFieldTouched(image, true);
                alert('Вы можете загрузить только изображение');
                return
            } else {
                props.formik.setFieldValue('imgFile', file);
                props.formik.setFieldValue(image, URL.createObjectURL(file));
            }
        }
    }

    return (
        <WrapButton>
                <Button className={'buttonModalStyle'} variant="contained" component="label">
                    { props.formik.values.image ? 'Загрузить другое изображение' : 'Загрузить изображение' }
                    <input hidden accept="image/*"
                           multiple type="file"
                           onChange={uploadImage}
                           value={''}
                    />
                </Button>
        </WrapButton>
    );
}


