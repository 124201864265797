import React from 'react'
import {QuestionsStyles, StatisticTableWrap} from '../../common-styles/Common.styles'

type StatisticQuestionType = {
    questionOrder: number
    questionDescription: string
    participantsWhoAddSomeAnswerCount: number
    participantsWhoAddCorrectAnswerCount: number
    incorrectAnswersCount: number
    participantsWhoAddAllIncorrectAnswerCount: number
    checkValueHandler: (value: number) => number
}

export const StatisticQuestion = React.memo(function (props: StatisticQuestionType) {

    const {
        questionOrder,
        questionDescription,
        participantsWhoAddSomeAnswerCount,
        participantsWhoAddCorrectAnswerCount,
        incorrectAnswersCount,
        participantsWhoAddAllIncorrectAnswerCount,
        checkValueHandler
    } = props

    return (
        <StatisticTableWrap>
            <QuestionsStyles>
                <div className={'tableGrid'}>
                    <div className={'number-block'}>
                        <div style={{paddingLeft: '10px'}}>#{checkValueHandler(questionOrder)}</div>
                    </div>
                    <div className={'description-settings-block'}>{questionDescription ? questionDescription : '-'}</div>
                    <div className={'statistics-block'}>{checkValueHandler(participantsWhoAddSomeAnswerCount)}</div>
                    <div className={'statistics-block'}>{checkValueHandler(participantsWhoAddCorrectAnswerCount)}</div>
                    <div className={'statistics-block'}>{checkValueHandler(participantsWhoAddAllIncorrectAnswerCount)}</div>
                    <div className={'statistics-block'}>{checkValueHandler((incorrectAnswersCount + participantsWhoAddCorrectAnswerCount))}</div>
                </div>
            </QuestionsStyles>
        </StatisticTableWrap>
    )
})
