import React, {memo} from "react";
import gold from '../../assets/svg/winner1.svg'
import silver from '../../assets/svg/winner2.svg'
import bronze from '../../assets/svg/winner3.svg'
import {TopUser} from "./styles";
import {NavLink} from "react-router-dom";
import {SocialNetworkProviderType} from "../../api/api-types";


type WinnerPropsType = {
    avatar: string
    userName: string
    point: number
    position: number
    accountId: string
    socialNetworkProvider: SocialNetworkProviderType
}

export const Participant: React.FC<WinnerPropsType> = memo(({
                                                                userName,
                                                                avatar,
                                                                point,
                                                                position,
                                                                accountId,
                                                                socialNetworkProvider
                                                            }) => {
    const winnerImg = [gold, silver, bronze];
    const positionParticipant = position < 4 ? <img src={winnerImg[position - 1]} alt="Victory cup"/> : position


    const socialNetworkDomain = defineProvider(socialNetworkProvider, accountId)

    const location = {
        pathname: socialNetworkDomain,
        state: {fromDashboard: true}
    }

    function defineProvider(socialNetworkProvider: SocialNetworkProviderType, accountId: string) {
        const vkDomain = `https://vk.com/id${accountId}`

        switch (socialNetworkProvider.currentProvider) {
            case "facebook":
                return socialNetworkProvider.profileLink!
            case "vk":
                return vkDomain

            default:
                return 'Provider not defined'
        }
    }

    return (
        <div className={'wrap-top-user'}>
            <div className='number-block'>
                {positionParticipant}
            </div>
            <div style={{width: '100%'}}>
                <TopUser>
                    <div className='avatar-wrapper'>
                        <div>
                            <img src={avatar} alt='avatar'/>
                        </div>
                        <div className={'link'}>
                            <NavLink to={location}
                                     target="_blank">
                                <span>{userName}</span>
                            </NavLink>

                        </div>
                    </div>
                    <div className='points'>
                        {point} баллов
                    </div>
                </TopUser>
            </div>
        </div>
    )
})


