import {QuestionDataType} from "../hooks/useQuestionTmer";

class _Storage {
    private static tokenKey = 'token';
    private static timerKey = 'timer';
    private static questionDataKey = 'questionData';

    constructor() {
    }

    static saveToken(token: string) {
        sessionStorage.setItem(this.tokenKey, token)
    }

    static getToken(): string | null {
        return sessionStorage.getItem(this.tokenKey)
    }

    static setClosingQuestionTimer(timer: string) {
        sessionStorage.setItem(this.timerKey, timer)
    }

    static clearSessionStorage(key: string) {
        sessionStorage.removeItem(key)
    }


    static getClosingQuestionTimer(): number | null {
        const timer =  sessionStorage.getItem(this.timerKey)
        return Number(timer)
    }

    static getQuestionData(): QuestionDataType | null {
        const questionData = sessionStorage.getItem(this.questionDataKey)
        return questionData ? JSON.parse(questionData) : null
    }

    static setQuestionData(data: QuestionDataType) {
        return sessionStorage.setItem(this.questionDataKey, JSON.stringify(data))
    }

}

export default _Storage
