import {
  QuestionStatisticType,
  ROUTS_SOCKET_IO,
  WSResDelayType,
  WSResponseType,
  ParticipantRegistrationInfoResType,
} from "../api/api-types";
import { setAlertMessage, setDelay } from "../redux/settings-reducer";
import {
  setMeaningOverlays,
  setOverlaysData,
  setStatistic,
} from "../redux/overlays-reducer";
import { setRegistrationParticipantInfo } from "../redux/verify-participant-reducer";
import { Dispatch } from "redux";
import { NOTIFICATION_QUIZ_MESSAGE } from "../constants";
import { socketIoApi } from "../api/api";

export const connectSocketAdminFront = (quizId: string, dispatch: Dispatch) => {
  socketIoApi.createConnection(quizId);
  socketIoApi.socket?.on(
    ROUTS_SOCKET_IO.OVERLAY_EVENT,
    (data: WSResponseType) => {
      if (data.data?.errors) {
        dispatch(
          setAlertMessage(data.data?.errors[0] as NOTIFICATION_QUIZ_MESSAGE)
        );
        return;
      }
      dispatch(setMeaningOverlays(data.data?.meaningOverlays!));
    }
  );
  socketIoApi.socket?.on(
    ROUTS_SOCKET_IO.DELAY_EVENT,
    (data: WSResDelayType) => {
      dispatch(setDelay(data.delayActivated));
    }
  );
  socketIoApi.socket?.on(
    ROUTS_SOCKET_IO.REGISTRATION_INFO,
    (data: ParticipantRegistrationInfoResType) => {
      dispatch(setRegistrationParticipantInfo(data));
    }
  );
};

export const connectSocketOverlaysFront = (
  quizId: string,
  dispatch: Dispatch
) => {
  socketIoApi.createConnection(quizId);
  socketIoApi.socket?.on(
    ROUTS_SOCKET_IO.OVERLAY_EVENT,
    (data: WSResponseType) => {
      if (data.data?.errors) {
        dispatch(
          setAlertMessage(data.data?.errors[0] as NOTIFICATION_QUIZ_MESSAGE)
        );
        return;
      }
      dispatch(setOverlaysData(data.data));
    }
  );

  socketIoApi.socket?.on(
    ROUTS_SOCKET_IO.STATISTIC_BY_QUESTION_EVENT,
    (data: QuestionStatisticType) => {
      dispatch(setStatistic(data));
    }
  );
};
