import React from "react";
import styles from "../VerifyParticipants.module.css";
import loading from "../../../assets/img/loading.gif";

type loadingScript = {
    mainHeight: number
}

export const LoadingScript: React.FC<loadingScript> = (props) => {
    return (
        <div className={styles.spinnerContainer} style={{height: props.mainHeight + 'px'}}>
            <img src={loading} className={styles.spinner} alt="Spinner"/>
        </div>
    );
}
