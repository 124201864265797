import React, {DetailedHTMLProps, FC, InputHTMLAttributes, MouseEvent,useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import {AnswerInputWrap} from "./styles"
import {WarningModal} from "../../../../common-components/WarningModal";
import pencil from '../../../../assets/svg/pencil.svg'
import delete_answer from '../../../../assets/svg/delete-answer.svg'
import active_edit from '../../../../assets/svg/active-edit-answer.svg'


import img from "*.jpg";

interface AnswerInputPropsType extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    index: number
    answer: string
    answers: string[]
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    setFieldTouched: (field: string, touched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}


export const AnswerInput: FC<AnswerInputPropsType> = (
    {
        index,
        answer,
        setFieldValue,
        setFieldTouched,
        answers,
        ...restProps
    }
) => {

    const ref = useRef<HTMLInputElement>(null);
    const [modal, setModal] = useState(false)
    const [editAnswer, setEditAnswer] = useState(false)
    const [localAnswer, setLocalAnswer] = useState('')

    const setActiveEditAnswer = (e: MouseEvent<HTMLImageElement>) => {
         e.stopPropagation();

        setTimeout(() => {
            ref.current?.focus()
        }, 100);

        setFieldTouched('answers', true)
        setEditAnswer(state => !state)
    }

    const deleteAnswer = () => {
        const filteredAnswers = answers.filter((a, i) => i !== index)
        setFieldValue('answers', filteredAnswers)
    }

    const setAnswerInFormikState = () => {
        const editedAnswers = answers.map((a, i) => i === index ? localAnswer : a)
        setFieldValue('answers', editedAnswers)
        setEditAnswer(state => !state)
    }

    const setModalHandler = () => {
        if (!localAnswer.trim()) {
            deleteAnswer()
            return
        }

        setModal(true)
    }

    useEffect(() => {
        setLocalAnswer(answer)
    }, [answers])

    return (
        <AnswerInputWrap answerIsActive={editAnswer}>
            {
                editAnswer
                    ?
                    <div>

                        <input className={'input-style'}
                               type="text"
                               ref={ref}
                               onBlur={setAnswerInFormikState}
                               onChange={(e) => setLocalAnswer(e.currentTarget.value)}
                               value={localAnswer}
                               {...restProps}/>

                        <div className={'answer-img-inactive'}>
                            <img className={'answer-img-block cursor'}
                                 src={active_edit}
                                 alt="active_edit"
                                 onClick={setAnswerInFormikState}
                            />
                        </div>

                    </div>
                    :
                    <div className={'input-style answer-inactive-block'}
                         onClick={setActiveEditAnswer}
                    >

                        <span>{answer}</span>

                        <div className={'answer-img-inactive'}>
                            <img className={'cursor'}
                                 src={pencil}
                                 alt="edit"
                                 onClick={setActiveEditAnswer}/>
                            <img className={'cursor'}
                                 src={delete_answer}
                                 alt="delete_answer"
                                 onClick={setModalHandler}/>
                        </div>

                    </div>
            }


            {
                modal
                    ?
                    ReactDOM.createPortal(
                        <WarningModal description={answer}
                                      title={'Удаление ответа'}
                                      text={`Вы действительно хотите удалить ответ `}
                                      callBack={deleteAnswer}
                                      setWarningModal={setModal}
                        />
                        ,
                        document.body
                    )
                    :
                    null
            }
        </AnswerInputWrap>
    )
}