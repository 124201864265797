import Fab from "@mui/material/Fab";
import styled from "styled-components";
import theme from '../theme'
import { QUIZ_STATUS} from "../constants";

export const AppWrap = styled.div<{ overlayFrontActive: boolean }>`
  position: relative;
  padding: ${(props) => props.overlayFrontActive ? 0 : '0 24px 0 44px'};
  background-color: ${(props) => props.overlayFrontActive ? 'transparent' : '#FAFAFA'};
  width: 100%;
`;


export const CommonWrapper = styled.div<{
    modal?: boolean,
    togglePage?: null | string,
    zoomImg?: boolean,
    quizStatus?: QUIZ_STATUS
}>`
  position: relative;
  min-width: 920px;
  width: 100%;

  .selectWrap {
    .Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 0.87) !important
    }
  }

  .cursor {
    cursor: ${(props) => props.zoomImg ? 'default !important' : 'grab'};
  }

  .headerWrap {
    display: flex;
    flex-direction: column;
  }

  .header {
    padding-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .tableGrid {
    padding: 12px;
    display: grid;
    align-items: center;
    grid-template-columns: 10% 15% 22% 15% 12% 7% auto;
    width: 100%;
    gap: 0 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 2;
    //word-break: break-all;
  }

  .number-block {

    span {
      padding-left: 8px
    }

    .sex-img {
      width: 35px;
    }

    img {
      padding-right: 15px;
    }

    .position {
      display: flex;
      align-items: baseline;

      .hide {
        visibility: hidden;
        opacity: 0;
      }
     
    }

  }

  .description-block {
    margin: 0 5px;
    display: flex;
  }

  .type-question-block {
    word-break: break-word;
    font-weight: 500;

    div {
      ol {
        margin: 0;
        padding: 0;
        counter-reset: item;
      }

      ol > li {
        margin: 0;
        padding: 0 0 0 2em;
        text-indent: -2em;
        list-style-type: none;
        counter-increment: item;
      }

      ol > li:before {
        display: inline-block;
        width: 1em;
        padding-right: 0.5em;
        font-weight: 500;
        text-align: right;
        content: counter(item) ".";
      }

      ol > li::marker {
        font-weight: 500;
      }
    }

  }

  .theme-block {
    width: 22%;
    padding-right: 10px;
    word-break: break-word;

    div {
      display: flex;
      align-items: center;
    }

    img {
      padding-right: 12px;
    }
  }

  .quiz-theme-block {
    word-break: break-word;
    margin: 0 5px;
    display: flex;

    span {
      margin-left: 8px;
    }

    div {
      display: flex;
      align-items: center;
    }
  }

  .question-block {
    width: 23%;
    padding-left: 10px;
    word-break: break-word;
    display: ${(props) => {
      return props.togglePage === 'statisticPage' || props.togglePage === 'conferencePage' ? 'flex' : '';
    }
    };
    justify-content: ${(props) => props.togglePage === 'statisticPage' || props.togglePage === 'conferencePage' ? 'center' : 'flex-start'};

    .conference-buttons-group {
      display: flex;
      padding-left: 90px;

      .active-button {
        margin-right: 24px;

      }
    }
  }

  .attempts-number-block {
    text-align: right;
    font-weight: 500;
    display: ${(props) => {
      return props.togglePage === 'statisticPage' || props.togglePage === 'conferencePage' ? 'flex' : '';

    }
    };
    justify-content: ${(props) => props.togglePage === 'statisticPage' || props.togglePage === 'conferencePage' ? 'center' : 'flex-start'};

    .conference-buttons-group {
      display: flex;
      padding-left: 90px;

      .active-button {
        margin-right: 24px;
      }
    }
  }

  .start-conference-block {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    padding-top: 19px;

    div {
      display: flex;
      align-items: flex-start;
    }

    img {
      padding-right: 12px
    }
  }

  .center-content {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .basket {
      margin-left: 14px;
    }

    .picture {
      margin-left: 26px;
    }
  }

  .extra-padding {
    padding-left: 14px;
  }

  .start-quiz-block {
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;

    div {
      display: flex;
      align-items: flex-start;
    }

    img {
      padding-right: 12px
    }
  }

  .statistic-scroll-block {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: clamp(28.125rem, 6.25rem + 27.3438vw, 50rem);
  }

  .quiz-question-scroll-block {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: clamp(28.125rem, 6.25rem + 27.3438vw, 50rem);
  }
`;

export const TableStyles = styled.div`
  
  .title {
    padding: 13px 12px 14px 12px;
    display: grid;
    grid-template-columns: 10% 15% 22% 15% 12% 7% auto;
    width: 100%;
    background: ${theme.colors.quaternary.semilight};
    gap: 0 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    box-sizing: border-box;
    align-items: center;

    .position-header-question {
      position: relative;
      right: 11px;
    }

    .description-block {
      position: relative;
      right: 5px;
      span {
        margin-left: 8px;
      }
    }
  }
`;

export const StatisticTableWrap = styled.div`
  .description-settings-block {
  }

  .statistics-block {
    text-align: right;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
`

export const ModalStyles = styled.div<{ modal?: boolean, isPresentation?: boolean, isZoomImg?: boolean }>
    ` position: fixed;
      width: 462px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 90;
      background: ${theme.colors.quaternary.mediumlight};
      max-height: 750px;
      border-radius: 2px;
      visibility: ${({isZoomImg}) => isZoomImg ? 'hidden' : 'visible'};
      
      * {
        font-family: "Montserrat", sans-serif !important;
      }
      
      @media all and (min-height: 950px) and (max-width: 3800px) {
        
        //todo: Sveta, why is the transition here?
        //transition: 1s;
        max-height: 960px;
      }

      &.modal-questions {
        overflow-x: hidden;
      }

      .MuiFormControl-root,
      .MuiTextField-root,
      .css-1u3bzj6-MuiFormControl-root-MuiTextField-roots {
        width: 100%;
      }

      .border {
        border-bottom: ${theme.setBorder(theme.colors.quaternary.light)};
        margin-bottom: 22px;
        padding: 0 27px;
        position: relative;

        img {
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: 24px;
          transform: translateY(-50%);
        }
      }

      h2 {
        font-weight: 500;
        font-size: 20px;
        color: ${theme.colors.quaternary.dark};
        padding: 18px 0;
        margin-bottom: 0;
      }

      .form {
        padding: 0 24px 36px;
        display: flex;
        flex-direction: column;
        
        label {
          font-size: 14px;
        }
        
        .toggle-wrapper {
          display: flex;
          flex-direction: column;
          padding-top: 15px;
          cursor: pointer;

          .flex {
            display: flex;
          }

          .toggle-header {
            padding-bottom: 5px;
            opacity: 0.6;
            font-size: 13px;
            line-height: 20px;
          }

          .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 168px;
            height: 34px;
            border: ${theme.setBorder(theme.colors.quaternary.light)};
            box-sizing: border-box;
            border-radius: 2px;
          }

        }

        .number-id-input {

        }

        .speaker-name-input {
          padding-top: 20px;
        }

        .company-position-input {
          padding-top: 22px;
        }

        .link-input {
          padding-top: 22px;
        }

        .time-input {
          position: relative;
          padding-top: 22px;

          .field-text-notification {
            position: absolute;
            top: ${(props) => props.isPresentation ? '-48px' : '-71px'};

            .css-rxdq0m-MuiPaper-root {
              z-index: 90;
              height: 55px;
              width: 278px;
              color: black;
            }

            .css-ayy8wd-MuiGrid-root {
              max-width: none;
            }

            .css-1nmiy3s {
              padding: 16px 35px 16px 16px;
            }
          }

          .img-notification-block {
            cursor: pointer;
          }

          .time-wrap {
            display: flex;
            align-items: center;

            .position-time-block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
          }

          .time-zone-block {
            display: flex;

            div {
              margin-left: 5px;
            }
          }

          .ant-picker,
          .ant-picker-range {
            width: 86%;
            height: 39px;
            border-radius: 2px;
          }

          .ant-picker:hover,
          .ant-picker-focused {
            border: black solid 1px !important;
            border-radius: 2px;
            transition: 0s;
          }
        }

        .fields-input-block {
          padding-top: 23px;
        }

        .text-area-input {
          padding-top: 40px;
        }
        
        .buttons-block {
          padding-top: 39px;
          padding-bottom: 20px;
          display: flex;
          justify-content: flex-end;
        }
        
      .ansvers-block {
        margin-top: 24px;
      }
    }
`;

export const ModalWrap = styled.div`
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 1100;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  .fields-textarea-block {
    padding-top: 24px;
  }
  
  .field-title {
    display: flex;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 0;
  }
  
  .answer {
    margin-bottom: 12px;
    height: 36px
  }
  
  .more-answer-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #2D8CE4;
    margin: 4px 0 10px;
    display: inline-block;
  }
  
  .css-18ie9tq-MuiStack-root {
    width: 100%;
  }

  .css-1869usk-MuiFormControl-root {
    margin: 0;
  }

  .form {
    display: flex;
    flex-direction: column;
    
    .text {
      margin-bottom: 36px;
      
      .answer-text-wrap {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        flex-direction: column;
        
        span {
          display: flex;
        }
        
        strong {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
`;

export const QuestionsStyles = styled.div<{ setStyle?: boolean | undefined, isZoomImg?: boolean }>`
  position: relative;
  display: flex;
  align-items: center !important;
  min-height: 84px;
  //padding-top: 40px;
  border-bottom: ${theme.setBorder(theme.colors.quaternary.semilight)};
    //padding-bottom: ${(props) => props.setStyle ? '65px' : '23px'};
  background: #FFFFFF;

  .votes-block {
    padding-top: 5px;
  }

  .last-child {
    margin-bottom: 36px;
  }

  .flex-block {
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      padding-left: 11px;
    }
  }

  .pictures-block {
    display: flex;
    justify-content: center;

    .stub {
      padding-left: 162px;
    }

    .basket {
      cursor: pointer;
      padding-left: 21px
    }

    .picture {
      right: 39px;
      cursor: pointer;
    }

    .timer {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      color: #E42D2D;
    }
  }

  .img-block {
    position: relative;
    width: 60px;
    height: 60px;
    object-fit: cover;

    .zoom {
      position: absolute;
      right: 3px;
      top: 3px;
      width: 18px;
      height: 18px;
      cursor: pointer;
      z-index: 9;
    }

    .zoom-container {

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;

      .zoom-block {
        position: relative;
display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        object-fit: contain;

        .adaptive-img {
          max-height: 90vh;
          max-width: 90vw;
          height: 100%;
          width: 100%;
          min-width: 30vw;
        }

        .zoom-out {
          width: 30px;
          height: 30px;
          position: absolute;
          right: 8px;
          top: 8px;
          cursor: pointer;
          z-index: 9999999;
        }
      }
    }

    img {
      width: ${({isZoomImg}) => isZoomImg ? '' : '60px'};
      height: ${({isZoomImg}) => isZoomImg ? '' : '60px'};
      object-fit: cover;
    }
  }
}
`;

export const ProgressStyles = styled.div
    `  background: ${theme.colors.quaternary.main};
      width: 100%;
      height: 100vh;
      z-index: 10;
      position: fixed;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;`;

export const ButtonBot = styled.button<{ eventVoting: boolean, status: string, progress?: boolean, timer?: number | null }>`
  height: 36px;
  background-color: transparent;
  border: 1px solid #2D8CE4;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  
  display: ${(props) => props.progress  ? 'none' : ''};

  border: ${(props) => {
    if (props.status === 'started') {
      return `${theme.setBorder(theme.colors.danger.main)}`;
    } else {
      return `${theme.setBorder(theme.colors.primary.main)}`;
    }
  }};

  color: ${(props) => {
    if (props.status === 'started') {
      return `${theme.colors.danger.main}`;
    } else {
      return `${theme.colors.primary.main}`;
    }
  }};
`;

export const VerifyParticipantsBlock = styled.div`
  .CheckWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 192px;
    gap: 24px
  }

  .Box {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 36px 0 30px;
  }

  .Box:first-child {
    grid-area: 1/1/2/4;
  }

  .StatisticsWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 42px;
  }

  .StatisticsContent {
    margin-right: 44px;
  }
  
  span {
    font-weight: 500;
    font-size: 20px;
  }
  
  h4 {
    margin: 20px 5px 10px 0;
    font-size: 15px;
    opacity: 0.5;
    display: inline-block;
  }
`;

export const StatisticsBlock = styled.div`
  .StatisticsWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 42px;
  }

  .StatisticsContent + .StatisticsContent{
      margin-left: 44px;
  }
  
  h4 {
    margin-bottom: 10px;
    font-size: 15px;
    opacity: 0.5;
  }
  
  span {
    font-weight: 500;
    font-size: 20px;
  }
  

`;

export const Error = styled.div`
  position: fixed;
  top: 42%;
  left: 35%;
  text-align: center;
`;


export const SettingWrapper = styled.div`
  .MuiInput-input, .MuiInputBase-input,
  .css-1x51dt5-MuiInputBase-input-MuiInput-input {
    font-weight: 600;
  }
`;


export const SettingWrap = styled.div<{ timeZoneColor?: boolean }>`
  * {
    font-family: "Montserrat", sans-serif !important;
  }
  
  .time-zone-block {
    display: block;
    flex-direction: column;
    position: relative;
    padding-top: 26px;
    width: 100%;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2;
      color: ${(props) => {
        return props.timeZoneColor ? `${theme.colors.primary.lightest}` : 'rgba(0, 0, 0, 0.6)';
      }
      }
    }

    .time-zone-image {
      padding-left: 24px;

    }

    .css-qc6sy-singleValue {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .css-7uysqn-MuiPaper-root-MuiAlert-root {
      width: 100%
    }

    .css-1s2u09g-control {
      border-bottom: ${theme.setBorder(theme.colors.tertiary.dark)};
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }

    .css-1pahdxg-control:hover {
      border: none;
      box-shadow: none;
    }

  }

  .css-1iwtne7-MuiTypography-root-MuiLink-root {
    font-size: 19px
  }

  .image-block {
    display: flex;
    position: relative;

    .image {
      margin-left: 24px;
      position: absolute;
      right: 4px;
      bottom: 10px;
    }
  }

  .color-picker-block {
    display: flex;
    align-items: center;
    padding-top: 7px;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
      padding-left: 12px;
    }
  }

  h3 {
    text-align: center;
  }

  label {
    font-size: 16px;
  }

  input {
    font-weight: 500 !important;
    //padding-right: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .css-7uysqn-MuiPaper-root-MuiAlert-root {
    cursor: pointer;
    padding: 0 16px;
    position: relative;
    top: 6px;
  }
}


.text-field {
  padding-top: 28px;
  

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  label {
    z-index: 0
  }
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: ${theme.colors.quaternary.dark};
}

.css-1480iag-MuiInputBase-root-MuiInput-root:after {
  border-bottom: ${theme.setBorder(theme.colors.tertiary.dark)};
}

.css-1o2jng6-MuiOutlinedInput-notchedOutline {
  border: ${theme.setBorder('rgba(0, 0, 0, 42%')})
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-width: 2px;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: ${theme.setBorder(theme.colors.tertiary.dark)};
}

.css-7uysqn-MuiPaper-root-MuiAlert-root {
  background: none;
}

.css-1869usk-MuiFormControl-root {
  width: 92%;
  margin: 0;
}

`;

export const SidebarWrap = styled.div`
  height: 100%;
  width: 270px;
  background: ${theme.colors.quaternary.main};
  
  .active-styles {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 24px;
  }

  .not-active {
    padding-left: 15px;
    opacity: 0.4;
  }

  .active {
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
  }

  .logo {
    color: ${theme.colors.quaternary.main};
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
    
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 37px;
      align-items: center;
      text-decoration: none;
      width: 100%;
      color: ${theme.colors.tertiary.dark};
      &:hover {
        opacity: .5;
      }
      img {
        margin-right: 12px;
      }
    }
  }
`;

export const SidebarNav = styled.nav`
  position: sticky;
  top: 0;
  .active-styles {
    margin-top: 20px;
    cursor: pointer;
    opacity: .5;
    
    &:focus, &:hover, &.active {
      opacity: 1;
    }
  }
  
  .link {
    display: flex;
    gap: 8px;
    font-weight: 700;
    font-size: 12px;
    color: #0B0C0D;
    text-decoration: underline;
    margin-top: 100px;
    opacity: 1;
    line-height: 24px;
    transition: opacity 0.5s ease-out;
    &:hover {
      opacity: 0.7;
    }
  }
  
  .public-link {
    display: flex;
    align-items: flex-end;
    
    img {
      margin-bottom: 4px;
      cursor: pointer;
    }
  }
`

export const SideBarBackground = styled.div<{ modal: boolean }>`
  z-index: 0;
  opacity: ${props => props.modal && 0.6};
  border-right: 1px solid #DFDFDF;
  border-top: 1px solid #DFDFDF;

  &:hover {
    .hover-front-wrap {
      display: block;

      span {
        cursor: pointer;
        padding: 8px 0 8px 24px;

        &:hover {
          opacity: 1;
          background: rgba(255, 255, 255, 0.13);
        }
      }
    }
  }

  .hover-front-wrap {
    padding-top: 150px;
    display: none;
    color: ${theme.colors.quaternary.main};

    div {
      margin-top: 16px;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      opacity: 0.5;
    }
  }
`;


export const AddSpeakerBlock = styled.div`
  bottom: 0;
  height: 36px;
  width: 203px;
  display: flex;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.primary.main};

  &:hover {
    background: ${theme.colors.primary.lightest};
  }

  span {
    color: ${theme.colors.quaternary.main};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
  }
`;

export const OverlayWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
`

export const Button = styled(Fab)`
  &.button {
    padding: 0 28px;
    height: 36px;
    box-shadow: 0 4px 18px rgba(45, 140, 228, 0.35), inset 0 1px 0 rgba(255, 255, 255, 0.3);
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-transform: unset;
    font-size: 16px;
    z-index: 0;
  }

  &.primary {
    background-color: ${theme.colors.primary.main};
    color: ${theme.colors.quaternary.main};
    border-radius: 2px;

    &:hover {
      background-color: ${theme.colors.primary.lightest};
    }

    &.red {
      background-color: #E42D2D;
      box-shadow: 0 4px 18px rgba(228, 45, 45, 0.35), inset 0 1px 0 rgba(255, 255, 255, 0.3);

      &:hover {
        background-color: #cc1313;
      }

    }
  }
  
  &.blue {
    border-radius: 2px;
    border: 1px solid #2D8CE4;
    color: ${theme.colors.blue.light};
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.30) inset, 0 4px 18px 0 rgba(45, 140, 228, 0.35);
    background: #FAFAFA;
  }

  &:hover {
    background-color: ${theme.colors.primary.lightest};
  }

  &.secondary {
    background-color: ${theme.colors.quaternary.main};
    color: ${theme.colors.primary.main};
    border-radius: 2px;
    box-shadow: none;
    border: solid 2px ${theme.colors.primary.main};
    position: absolute;
    bottom: -24%;
    margin: 0 auto;
    right: 50%;
    margin-right: 65px;
    
    &:hover {
      background-color: ${theme.colors.primary.lightest};
      color: ${theme.colors.quaternary.main};
    }

  }
`

export const ErrorSpan = styled.div`
  color: ${theme.colors.danger.main};
  padding-top: 4px;
`

export const SuccessSpan = styled.div`
  color: #33B830;
  padding-top: 4px;
`


export const UploadImageBlock = styled.div`
  padding: 12px 0;
  position: relative;

  .wrap-pictures-control {
    width: 100%;
  }

  .loaded-image {
    width: 72px;
    height: 72px;
    object-fit: cover;
  }

  .pictures-control {
    width: 72px;
    padding: 5px 3px 0 3px;
    position: absolute;
    display: flex;
    justify-content: space-between;

    .control-img {
      cursor: pointer;
    }
  }

  .upload-image-button {
    padding-top: 12px;

    input {
      display: none;
    }

  }
`

export const ButtonsBlock = styled.div`
  margin: 0 !important;
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 12px 24px;
  padding-bottom: 24px;
  
  .quizCheckbox {
    border: 1px solid #DFDFDF;
    padding: 10px 9px 16px 12px;
    z-index: 10;
    font-family: "Montserrat", sans-serif;
    
    &:hover {
      background: rgba(45, 140, 228, 0.05);
      cursor: pointer;
    }
  }

  .activeButton {
    background: rgba(45, 140, 228, 0.05);
    border: 1px solid #2D8CE4;
  }

  .formText {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #808080;
    max-width: 160px;
    margin-bottom: 0;
    margin-left: 32px;
  }

  label {
    font-weight: 500;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin-right: 0;
    span {
      font-weight: 500;
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
    }
  }
`;
