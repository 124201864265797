import React, {
    useEffect,
    useLayoutEffect,
    useMemo, useRef,
    useState
} from "react";
import 'antd/dist/antd.css';
import {Button, ErrorSpan, ModalStyles, ModalWrap} from "../../../common-styles/Common.styles";
import closeButton from "../../../assets/svg/close-modal.svg";
import TextField from '@mui/material/TextField';
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {updateQuestionRequestType} from "../../../api/api-types";
import { updateQuestion} from '../../../redux/questions-reducer';
import {
    ClearFieldsModalType,
    QUESTION_TYPES,
    QUESTION_FRONT_TYPES,
    QuestionModalPropsType,
    FORM_DATA_KEY
} from "./types";
import {filterArrayAnswers, validateQuestionForm} from "../../../utils";
import {ImageBlock} from "./imageBlock/ImageBlock";
import {usePictureToggle} from "../../../hooks";
import {BasicTooltip} from "../../../common-components/Tooltip/BasicTooltip/BasicTooltip";
import infoIcon from "../../../assets/svg/info-icon.svg";
import {BasicSelect} from "../../../common-components/Select/Select";
import {BasicTextField} from "../../../common-components/TextField/TextField";
import {AnswerInput} from "./answerInput/AnswerInput";
import {getQuestions} from "../../../redux/selectors/questionSelectors";


export const QuestionModal: React.FC<QuestionModalPropsType> = ({modal, setModal, questionId}) => {
    const dispatch = useDispatch()
    const questions = useSelector(getQuestions);

    const answerEndRef = useRef<HTMLDivElement>(null);
    const [scroll, setScroll] = useState(false)
    const [clearFieldsModal, setClearFieldsModal] = useState<ClearFieldsModalType>('set_data');
    const {isZoomImg, handlePicture, closeZoomPicture} = usePictureToggle();

    const questionsType = Object.values(QUESTION_FRONT_TYPES)
    const currentQuestion = useMemo(() => questions.find(q => q.id === questionId), [questions, questionId])

    const handleDismiss = () => {
        setModal(false)
        setClearFieldsModal('set_data')
    }

    const addNewAnswer = () => {
        if (formik.values.answers.length === 10) return
        if (!scroll) setScroll(true)
        formik.setFieldValue('answers', [...formik.values.answers, ''])
    }

    const prepareFileData = (data: updateQuestionRequestType, imgFile: File) => {
        const formData = new FormData();
        formData.append(FORM_DATA_KEY.DATA, JSON.stringify(data));

        if (imgFile?.size) {
            formData.append(FORM_DATA_KEY.FILE, new Blob([imgFile]));
        }

        return formData
    }


    const formik = useFormik({
        initialValues: {
            questionType: '',
            notes: '',
            questionDescription: '',
            maxAttemptsNumber: '1',
            answers: [],
            image: '',
            imgFile: {} as File
        },
        validate: validateQuestionForm,

        onSubmit: (values) => {
            const data: updateQuestionRequestType = {
                //@ts-ignore
                type: QUESTION_TYPES[values.questionType],
                questionId: questionId,
                title: values.notes,
                answers: filterArrayAnswers(values.answers),
                maxAttemptsNumber: +values.maxAttemptsNumber,
                questionDescription: values.questionDescription,
            };

            const formData = prepareFileData(data, values.imgFile)

            dispatch(updateQuestion(formData));
            setModal(false)
        },
    });


    useLayoutEffect(() => {
        if (currentQuestion) {
            formik.setFieldValue("notes", currentQuestion.title);
            formik.setFieldValue("questionDescription", currentQuestion.questionDescription);
            formik.setFieldValue("questionType", QUESTION_FRONT_TYPES[currentQuestion.type]);
            formik.setFieldValue("maxAttemptsNumber", currentQuestion.maxAttemptsNumber);
            formik.setFieldValue("answers", currentQuestion.answers);
            if (currentQuestion.imgData) {
                formik.setFieldValue("image", currentQuestion?.imgData.imgUrl);
            }
        }
    }, [currentQuestion, clearFieldsModal])

    useEffect(() => {
        if (clearFieldsModal !== 'set_data') {
            formik.setFieldValue("notes", '');
        }
    }, [clearFieldsModal])

    useEffect(() => {
        if (!scroll) {
            return
        }
        answerEndRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [formik.values.answers])


    const answers = formik.values.answers.map((a, i) => <AnswerInput key={i}
                                                                     index={i}
                                                                     answer={a}
                                                                     answers={formik.values.answers}
                                                                     setFieldValue={formik.setFieldValue}
                                                                     setFieldTouched={formik.setFieldTouched}
    />)


    return (
        <ModalWrap>
            <ModalStyles className={'modal-questions'}
                         isZoomImg={isZoomImg}
                         modal={modal}
                         isPresentation={false}>

                <div className={'border'}>
                    <h2>Редактирование вопроса</h2>
                    <img
                        src={closeButton}
                        alt="close img"
                        onClick={handleDismiss}
                    />
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className={'form'}>

                        <div className={"fields-input-block"}>
                            <FormControl variant="standard" sx={{minWidth: 120}}>
                                <InputLabel id="select-label"
                                            sx={{top: '-40px'}}>Тип вопроса</InputLabel>

                                <BasicSelect items={questionsType}
                                             defaultValue="Текст"
                                             formikFieldName={'questionType'}
                                             getFieldPropsFormik={formik.getFieldProps}
                                />
                            </FormControl>
                        </div>

                        <ImageBlock formik={formik}
                                    isZoomImg={isZoomImg}
                                    handlePicture={handlePicture}
                                    closeZoomPicture={closeZoomPicture}
                        />

                        <div className={'fields-input-block'}>
                            <InputLabel id="select-label">Заметка</InputLabel>
                            <BasicTextField
                                id="topic"
                                rows={2}
                                {...formik.getFieldProps('notes')}
                            />
                        </div>


                        <div className={'fields-textarea-block'}>
                            <div className='field-title'>
                                <InputLabel id="select-label"
                                            sx={{mr: '5px'}}
                                            error={!!(formik.touched.questionDescription && formik.errors.questionDescription)}
                                >Вопрос</InputLabel>

                                <BasicTooltip placement={'bottom-start'}
                                              title={"Этот текст  будет отображаться участникам!"}>
                                    <img src={infoIcon} alt="info icon"/>
                                </BasicTooltip>
                            </div>

                            <BasicTextField
                                error={!!(formik.touched.questionDescription && formik.errors.questionDescription)}
                                id="description"
                                rows={4}
                                {...formik.getFieldProps('questionDescription')}
                            />

                            {
                                formik.touched.questionDescription && formik.errors.questionDescription &&
                                <ErrorSpan>{formik.errors.questionDescription}</ErrorSpan>
                            }
                        </div>

                        <div className={'fields-textarea-block'}>
                            <TextField
                                label={'Число попыток ответов на вопрос (не более 10 попыток)'}
                                variant="standard"
                                type={"number"}
                                InputProps={{inputProps: {min: 1, max: 10}}}
                                {...formik.getFieldProps('maxAttemptsNumber')}
                            />
                        </div>


                        {/*<div className={'ansvers-block'}>*/}
                            <h3>Возможные ответы</h3>

                        {/*</div>*/}

                        <div>
                            {answers}
                            <div ref={answerEndRef}/>

                            {
                                formik.touched.answers && formik.errors.answers &&
                                <ErrorSpan>{formik.errors.answers}</ErrorSpan>
                            }
                            <a className="more-answer-link" onClick={addNewAnswer}>+добавить ответ</a>
                        </div>
                        <Button
                            className={'button primary'}
                            type="submit"
                            variant="extended"
                            aria-label="add"
                            sx={{alignSelf: 'flex-end', mb: '20px'}}
                        >Сохранить</Button>

                    </div>
                </form>
            </ModalStyles>
        </ModalWrap>
    )
}







