import React from 'react'
import {StatisticTableWrap} from "../../common-styles/Common.styles";

export const StatisticsTable: React.FC = () => {

    return (
        <StatisticTableWrap>
            <div className={'title statistic'}>
                <div className={'number-block'}>Номер</div>
                <div className={'description-settings-block'}>Вопрос</div>
                <div className={'statistics-block'}>Всего ответили</div>
                <div className={'statistics-block'}>Верно ответили</div>
                <div className={'statistics-block'}>Неверно ответили</div>
                <div className={'statistics-block'}>Кол-во попыток</div>
            </div>
        </StatisticTableWrap>
    )
}