import styled from "styled-components";

export const AuthBtnWrapper = styled.div<{backgroundColor: string}>`

  .primary {
    min-width: 236px;
    padding: 20px 19px;
    margin-right: 24px;
    background:${(props) => props.backgroundColor};
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
`

export const IconWrapper = styled.div`
  transform: translateX(-10px);
  max-height: 26px;
`

export const SocialNetworkButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 7px;

  .check-participants-button {
    margin-right: 24px;

    .lkjqHZ.primary {
      height: 40px;
    }
  }
`

  
