import React from "react";
import {Button, ModalStyles, ModalWrap} from "../common-styles/Common.styles";
import closeButton from "../assets/svg/close-modal.svg";

import {useDispatch} from "react-redux";
import {closeVoteQuiz} from "../redux/quiz-reducer";

type PropsType = {
    quizId: string
    title: string
    text: string
    setOpenModal: (value: boolean) => void
}

export const Modal: React.FC<PropsType> = ({
                                                                  setOpenModal,
                                                                  title,
                                                                  text,
                                                                  children
                                                              }) => {
    const dispatch = useDispatch()

    const onClickHandler = () => {
        dispatch(closeVoteQuiz())
        setOpenModal(false)
    }

    return (
        <ModalWrap>
            <ModalStyles>
                <div className={'border'}>
                    <h2>{title}</h2>
                    <img
                        src={closeButton}
                        alt="close img"
                        onClick={() => setOpenModal(false)}
                    />
                </div>
                {
                    children ?
                        <>
                    {children}
                        </>
                        :
                        <>
                        <div className={'form'}>
                            <div>
                        <span style={{display: 'flex', textAlign: 'left', padding: '24px 0', fontSize: '16px'}}>
                            {text}?
                        </span>
                            </div>
                            <Button
                                className={'button primary red'}
                                type="button"
                                variant="extended"
                                aria-label="continue"
                                onClick={onClickHandler}
                                sx={{alignSelf: 'flex-end'}}
                            >Остановить</Button>
                        </div>
                        </>
                }

            </ModalStyles>
        </ModalWrap>
    )
}