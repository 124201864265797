import React from 'react'
import loading from "../assets/img/loading.gif";
import { ProgressStyles } from '../common-styles/Common.styles';

export const Loading = () => {

    return (
        <ProgressStyles className='loading'>
            <img src={loading} alt=""
                 style={{
                     position: 'fixed',
                     top: '50%',
                     left: '50%',
                     transform: 'translate(-50%, -50%)',
                     zIndex: 90,
                 }}/>
        </ProgressStyles>
    )
}

