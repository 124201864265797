const primary = '#2D8CE4';
const secondary = '#2A506F';
const quaternary = '#fff';
const tertiary = '#527699';
const danger = '#FF423D';

const colors = {
    primary: {
		main: primary,
		semilight: '#183aee',
		light: '#08bcff',
        lighter: '#0419B1',
        lightest: '#1976d2',
		dark: '#0018A8',
	},
	secondary: {
		main: secondary,
		semilight: '#abb9c5',
		light: '#2e587a',
		dark: '#23445e',
	},
    quaternary: {
		main: quaternary,
		semilight: '#E5E5E5',
		light: '#CCCCCC',
		mediumlight: '#FCFBFB',
        lighter: '#FCFCFC',
        lightest: '#eeeeee',
		dark: '#818181',
	},
    tertiary: {
		main: tertiary,
		light: '#141F31',
		dark: '#000',
	},
	blue: {
		light: "#2D8CE4"
	},
    danger: {
		main: danger,
	},
}

const theme = {
    colors,
    setBorder: (color:string) => `1px solid ${color}`
    };
export default theme;
