import React, {useEffect, useRef, useState} from 'react';
import {Button, CommonWrapper, TableStyles} from '../../common-styles/Common.styles';
import {
    DragDropContext,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from "react-redux";
import {Progress} from "../../common-components/Progress";
import {WarningModal} from "../../common-components/WarningModal";
import {QuizTable} from '../tables/QuizTable';
import {Question} from './Question';
import {addQuestion, setQuestions, setScrolling, updateOrderQuestions,} from '../../redux/questions-reducer';
import {getQuestions, getScrolling} from "../../redux/selectors/questionSelectors";
import {QuestionModal} from './modal';
import {NOTIFICATION_QUIZ_MESSAGE, QUIZ_STATUS} from "../../constants";
import {setIsProgress} from "../../redux/app-reducer";
import {getQuizStatus} from "../../redux/quiz-reducer";
import '../../App.css';
import {PageTitle} from "../../common-components/PageTitle/PageTitle";
import {setAlertMessage} from "../../redux/settings-reducer";


type PropsType = {
    quizId: string | null
    modal: boolean
    setModal: (modal: boolean) => void
}

export const Questions: React.FC<PropsType> = ({
                                                   modal,
                                                   setModal,
                                                   quizId,
                                               }) => {

    const dispatch = useDispatch();

    const status = useSelector(getQuizStatus);
    const progress = useSelector(setIsProgress);
    const questions = useSelector(getQuestions);
    const scrolling = useSelector(getScrolling);
    const quizStatus = useSelector(getQuizStatus);

    const [warningModal, setWarningModal] = useState(false)
    const [handleOnDrag, setHandleOnDrag] = useState(false)
    const [questionId, setQuestionId] = useState('');
    const [questionTitle, setQuestionTitle] = useState('');
    const questionEndRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
            if (!scrolling) return;
            if (questionEndRef.current) {
                questionEndRef.current.scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'end',
                    }
                );
            }

            return () => {
                dispatch(setScrolling(false));
            };
        },
        [questions]
    );

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }, [handleOnDrag])

    const handleOnDragEnd = (result: DropResult) => {
        if (scrolling) {
            dispatch(setScrolling(false));
        }
        setHandleOnDrag(state => !state)
        if (!result.destination || result.destination.index === result.source.index) return;
        // oldArrayQuestions на случай если промис reject => откатить к предыдущему состаянию массив.
        const oldArrayQuestions = questions;
        const items = Array.from(questions);
        let index = 0;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(index = result.destination.index, 0, reorderedItem);
        dispatch(updateOrderQuestions(quizId!, reorderedItem.id, (index + 1), oldArrayQuestions));
        dispatch(setQuestions(items));
    }

    const addQuestionHandler = () => {
        if (status === QUIZ_STATUS.started || status === QUIZ_STATUS.closed) {
            const toCheckQuestionsNotCompleted = questions.filter((q) => !q.answers.length);
            if (toCheckQuestionsNotCompleted.length) {
                dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.IS_QUESTION_NOT_COMPLETED));
                return;
            }
        }

        if (!scrolling) {
            dispatch(setScrolling(true))
        }
        dispatch(addQuestion(quizId));
    }

    const arrayQuestions = questions.map((q, index) => <Question
        key={q.id}
        index={index}
        setStyle={(index + 1) === questions.length}
        answers={q.answers}
        setWarningModal={setWarningModal}
        attemptsNumber={q.maxAttemptsNumber}
        setQuestionId={setQuestionId}
        setModal={setModal}
        questionId={q.id}
        setQuestionTitle={setQuestionTitle}
        numberId={q.numberId}
        questionTitle={q.title}
        questionType={q.type}
        questionDescription={q.questionDescription}
        imgData={q.imgData}
    />);

    return (
        <div>
            {progress && <Progress/>}
            <CommonWrapper modal={modal}>
                <PageTitle text={'Вопросы'}>
                    <Button className={'button primary'}
                            variant="extended"
                            type={'button'}
                            onClick={addQuestionHandler}
                            disabled={progress}
                            aria-label="add">Добавить вопрос
                    </Button>
                </PageTitle>
                <TableStyles>
                    <QuizTable/>
                </TableStyles>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable
                        isDropDisabled={status === QUIZ_STATUS.started || quizStatus === QUIZ_STATUS.closed || progress}
                        droppableId={'characters'}>
                        {(provided) => {
                            return (
                                <div style={{
                                    alignItems: 'flex-start !important',
                                    height: '100%'
                                }} {...provided.droppableProps}
                                     ref={provided.innerRef}>
                                    <div className={'questions-scroll-block'}>
                                        {arrayQuestions}
                                        <div ref={questionEndRef}/>
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )
                        }}
                    </Droppable>
                </DragDropContext>
            </CommonWrapper>
            {
                modal && <QuestionModal
                    modal={modal}
                    setModal={setModal}
                    questionId={questionId}/>
            }
            {
                warningModal && <WarningModal description={questionTitle}
                                              title={'Удаление вопроса'}
                                              text={'Вы уверены, что хотите удалить вопрос на тему '}
                                              setWarningModal={setWarningModal}
                                              questionId={questionId}
                />
            }
        </div>
    );
};
