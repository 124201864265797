import {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Storage from "../utils/storage";
import {setAlertMessage} from "../redux/settings-reducer";
import {NOTIFICATION_QUIZ_MESSAGE} from "../constants";
import {closeQuestion} from "../redux/questions-reducer";

export type QuestionDataType = {
    quizId: string
    questionId: string
    questionsLength: number
    numberId: number
}

export const useQuestionTimer = () => {
    const dispatch = useDispatch();
    const [timer, setTimer] = useState<null | number>(null);
    const [activeTimer, setActiveTimer] = useState(false);
    const [questionData, setQuestionData] = useState<QuestionDataType | null>(null);
    const [closeQuestionFlag, setCloseQuestionFlag] = useState(false);

    const closeQuestionHandler = async (quizId: string, questionId: string, questionsLength: number, numberId: number) => {
        const result = await dispatch(closeQuestion(quizId, questionId))

        //If this is last question in quiz, to show the message 'Quiz пройден'
        if (questionsLength === numberId) {
            dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.PASSED))
        }
        return result
    }

    useLayoutEffect(() => {
        const timer = Storage.getClosingQuestionTimer()
        const questionData = Storage.getQuestionData()
        if (timer && questionData) {
            setTimer(timer)
            setActiveTimer(true)
            setQuestionData(questionData)
        }

    }, [])

    useEffect(() => {
        if (closeQuestionFlag) {
            //@ts-ignore
            (async function () {
                await closeQuestionHandler(
                    questionData!.quizId,
                    questionData!.questionId,
                    questionData!.questionsLength,
                    questionData!.numberId
                )
            })();
            setCloseQuestionFlag(false)
        }
    }, [closeQuestionFlag])

    useEffect(() => {

        if (timer) {
            const interval = setInterval(() => {
                setTimer(prevTimer => {
                        Storage.setClosingQuestionTimer((prevTimer as number - 1).toString());

                        if ((prevTimer as number) <= 1) {
                            Storage.clearSessionStorage('timer');
                            Storage.clearSessionStorage('questionData');
                            setActiveTimer(false);
                            setCloseQuestionFlag(true)
                            return null;
                        }

                        return (prevTimer as number) - 1;
                    }
                );
            }, 1000);

            return () => clearInterval(interval)
        }

    }, [activeTimer])

    return {timer, closeQuestionFlag, setTimer, setActiveTimer, setQuestionData}
}
