export enum OVERLAY_BUTTON_LABEL{
    'image' = 'Картинка вопроса',
    'text' = 'Текст вопроса',
    'top-10' = 'Топ-10 c баллами',
    'top-20' = 'Топ-20 с баллами',
    'questionStatistics' = 'Статистика',
    'correctAnswers' = 'Правильные ответы',

}


export enum OVERLAY_BUTTON_DESCRIPTION {
    'image' = 'Показываем картинку-вопрос для зрителей',
    'text' = 'Показываем текст вопроса для зрителей',
    'top-10' = 'Показываем топ-10 участников с баллами',
    'top-20' = 'Показываем топ-20 участников с баллами',
    'questionStatistics' = 'Показываем статистику по текущему вопросу',
    'correctAnswers' = 'Показываем статистику по текущему вопросу'
}