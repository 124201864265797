import axios, {AxiosResponse} from "axios";
import io, {Socket} from 'socket.io-client';
import {FormsType} from "../pages/settingPage";
import Storage from "../utils/storage";
import {
    QuizType,
    UpdateSettingPagType,
    ResponseType,
    QuestionType,
    DataType,
    StatisticsType,
    WSResponseType,
    OverlayType,
    ParticipantsVKType,
    ParticipantRegistrationInfoResType,
    PaginationType,
    ParticipantStatisticType
} from "./api-types";
import {UserRepostedPostType} from "./social-network-api/types/fb-types";
import {VerificationParticipantData} from "../pages/verifyParticipants/validateInput/ValidateInput";

export const setHandleError = (callback: (text: string) => void) => {
    handleError = callback
}

let handleError = (text: string) => {
}

export const setHandle401 = (callback: () => void) => {
    handle401 = callback
}

let handle401 = () => {
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_DOMAIN || 'https://quiz.staging.it-incubator.ru/api',
});

instance.interceptors.request.use(
    (config) => {
        let token = Storage.getToken()
        if (!!token) {
            config.headers!['token'] = token
        }

        return config
    },
    error => Promise.reject(error)
)

instance.interceptors.response.use(
    response => response,
    error => {
        console.dir(error)
        if (error.response?.status === 401) {
            handle401()
        } else {
            handleError(error.message)
        }
        return Promise.reject(error)
    }
)


export const authAPI = {
    async authMe() {
        return instance.get<string>(`/management-auth/me`)
            .then(response => response.data);
    },
}

export const settingQuizAPI = {
    async getSettings(quizId: string) {
        return instance.get<QuizType>(`/quizzes`, {params: {quizId}})
            .then(response => response.data);
    },

    async settingsPage(settingsDate: FormsType) {
        return instance.put<FormsType, AxiosResponse<UpdateSettingPagType>>(`/quizzes`,
            settingsDate
        )
            .then(response => response.data);
    },

    async addNewQuestion(quizId: string | null) {
        return instance.post<{
            _id: string | null
        }, AxiosResponse<ResponseType<QuizType>>>(`/quizzes/questions`, {_id: quizId})
            .then(response => response.data);
    },

    async updateQuestion(data: FormData) {
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }

        return instance.patch<any, AxiosResponse<ResponseType<QuestionType>>>(`/quizzes/questions`, data, config)
            .then(response => {
                return response
            });
    },

    async deleteQuestion(questionId: string) {
        return instance.delete<ResponseType<QuizType>>(`/quizzes/questions`, {params: {questionId}})
            .then(response => response.data);
    },

    //drag and drop
    async updateOrderQuestions(questionId: string, numberId: number) {
        return instance.put<{ questionId: string, numberId: number },
            AxiosResponse<ResponseType<Array<QuestionType>>>>('/quizzes/questions/order', {
            questionId,
            numberId
        })
            .then(response => response.data)
    },
}

export const quizAPI = {
    async getAllQuestions() {
        return instance.get<ResponseType<Array<QuestionType>>>(`/quizzes/questions`)
            .then(response => response.data);
    },
    async openVoteQuiz() {
        return instance.post<DataType & { data: boolean }>('/quizzes/vote')
            .then(response => response.data)
    },

    async closeVoteQuiz() {
        return instance.delete<DataType>('/quizzes/vote')
            .then(response => response.data)
    },
    async questionVoting(questionId: string) {
        return instance.post<ResponseType>(`quizzes/questions/vote/${questionId}`)
            .then(response => response.data)
    },
    async closeQuestionVoting(questionId: string) {
        return instance.delete<ResponseType>(`quizzes/questions/vote/${questionId}`)
            .then(response => response.data)
    },

    async sendQuestionClosingTimer() {
        return instance.post<ResponseType>(`quizzes/questions/set-closing-timer`)
            .then(response => response.data)
    },

    async getStatistic() {
        return instance.get<ResponseType<StatisticsType>>(`quizzes/statistics`)
            .then(response => response.data)
    },
    async getParticipantStatistic(pageNumber: number) {
        return instance.get<ResponseType<PaginationType<ParticipantStatisticType[]>>>(`quizzes/participant-statistic?pageNumber=${pageNumber}`)
            .then(response => response.data)
    },
    async getParticipantsRegistrationInfo() {
        return instance.get<ResponseType<ParticipantRegistrationInfoResType[]>>(`quizzes/participants-info`)
            .then(response => response.data)
    },

    async setQuizDelay(delay: number) {
        return instance.put<{ delay: number }, AxiosResponse<ResponseType<QuizType>>>(`quizzes/set-delay`, {delay})
            .then(response => response.data)
    },

    async submitIdForVerification(data: VerificationParticipantData) {
        return instance.put<VerificationParticipantData, AxiosResponse<ResponseType<QuizType>>>(`quizzes/approve-participant`, data)
            .then(response => response.data)
    }

    // getRecalculatedParticipants() {
    //     return instance.get(`quizzes/recalculated-participants`)
    //         .then(response => response.data)
    // }
}

export const overlaysAPI = {
    async getOverlays(quizId: string) {
        return instance.get<WSResponseType>(`/quizzes/overlay-active`, {params: {quizId}})
            .then(response => response.data.data);
    },

    async getMeaningOverlays(quizId: string) {
        return instance.get<OverlayType[]>(`/quizzes/overlay-values`, {params: {quizId}})
            .then(response => response.data)
    },
}

export const vkAPI = {
    async getParticipants() {
        return instance.get<ResponseType<Array<ParticipantsVKType>>>(`quizzes/vk-posts`)
            .then(response => response.data)
    },
    async sendParticipantsPostCheckResult(data: ParticipantsVKType[]) {
        return instance.post(`quizzes/vk-posts`, {checkResults: data})
            .then(response => response.data)
    },
}


export const fbAPI = {
    async verifyFBParticipants(users: UserRepostedPostType[]) {
        return instance.post<UserRepostedPostType[], AxiosResponse<ResponseType>>(`quizzes/fb-posts`, {users})
            .then(response => response.data)
    }
}


export const socketIoApi = {
    socket: null as null | Socket,

    createConnection(roomKey: string) {
        const socketOptions = {
            extraHeaders: {
                token: Storage.getToken() as string,
                roomKey
            }
        }

        this.socket = io(process.env.REACT_APP_API_SOCKET_BASE_URL || 'https://quiz-socket.staging.it-incubator.ru/admin', socketOptions);

        this.socket.on("connect", () => {
            console.log("ws:connect")
        })

        this.socket.on("disconnect", (e) => {
            console.log("ws:disconnect")
        })
    },
}
