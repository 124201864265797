import {useQuery} from "./hooks";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {auth, setIsAuth, setIsLoading} from "./redux/app-reducer";
import {getQuizId} from "./redux/settings-reducer";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import Storage from "./utils/storage";
import {connectSocketAdminFront} from "./utils";
import {Loading} from "./common-components/Loading";
import {NotAuthorized} from "./common-components/NotAuthorized";
import {Header} from "./pages/header/Header";
import {Sidebar} from "./pages/sidebar/Sidebar";
import {EventSnackbar} from "./common-components/EventSnackbar";
import {AppWrap} from "./common-styles/Common.styles";
import {Progress} from "./common-components/Progress";
import {ROUTES} from "./types";
import {SettingPage} from "./pages/settingPage";
import {Questions} from "./pages/questions/Questions";
import {VerifyParticipants} from "./pages/verifyParticipants/VerivfyParticipants";
import {Quiz} from "./pages/quiz/Quiz";
import {Statistics} from "./pages/statistics/Statistics";
import {Winners} from "./pages/winners/Winners";
import OverlayPublic from "./pages/overlay/OverlayPublick";
import {NotFound} from "./common-components/NotFound";
import {useQuestionTimer} from "./hooks/useQuestionTmer";


const App = () => {

    const query = useQuery()
    const location = useLocation()
    const dispatch = useDispatch()

    const loading = useSelector(setIsLoading)
    const isAuth = useSelector(setIsAuth)
    const quizId = useSelector(getQuizId)

    const [modal, setModal] = useState(false)
    const {timer, closeQuestionFlag, setTimer, setActiveTimer, setQuestionData} = useQuestionTimer()

    const [overlayFrontActive, setOverlayFrontActive] = useState(false   )

    const mainContainer = useRef(null);

    const checkOverlayFrontIsActive = (url: string) => {
        return url.match(/(^|\W)overlays($|\W)/)
    }

    useLayoutEffect(() => {
        let token = query.get('token')

        if (checkOverlayFrontIsActive(location.pathname)) {
            setOverlayFrontActive(true)
        }

        if (!token) return

        Storage.saveToken(token)
    }, [dispatch])


    useEffect(() => {
        dispatch(auth())
    }, [dispatch])

    useEffect(() => {
        if (quizId && !overlayFrontActive) connectSocketAdminFront(quizId, dispatch)
    }, [quizId])


    if (loading) return <Loading/>

    if (!isAuth && !overlayFrontActive) return <NotAuthorized/>

    return (
        <div className="App">
            <Header modal={modal} overlayFrontActive={overlayFrontActive}/>

            <main className="Main">
                <Sidebar modal={modal}
                         quizId={quizId}
                         overlayFrontActive={overlayFrontActive}/>

                <EventSnackbar/>

                <AppWrap overlayFrontActive={overlayFrontActive}>
                    {loading && <Progress/>}
                    <div className="Wrap">
                        <Switch>
                            <Route exact path={ROUTES.BASE_URL} render={() => (<Redirect to={ROUTES.SETTING_PAGE}/>)}/>
                            <Route path={ROUTES.SETTING_PAGE}
                                   render={() => <SettingPage quizId={quizId}/>}/>
                            <Route path={ROUTES.QUESTIONS}
                                   render={() => <Questions modal={modal}
                                                            setModal={setModal}
                                                            quizId={quizId}/>}/>
                            <Route path={ROUTES.VERIFY_PARTICIPANTS}
                                   render={() => {
                                       if (!quizId) return null;
                                       return <VerifyParticipants quizId={quizId} mainContainer={mainContainer}/>
                                   }}/>
                            <Route path={ROUTES.QUIZ}
                                   render={() => <Quiz quizId={quizId}
                                                       timer={timer}
                                                       closeQuestionFlag={closeQuestionFlag}
                                                       setActiveTimer={setActiveTimer}
                                                       setTimer={setTimer}
                                                       setQuestionData={setQuestionData}/>
                                   }/>
                            <Route path={ROUTES.STATISTICS}
                                   render={() => <Statistics/>}/>
                            <Route path={ROUTES.WINNERS}
                                   render={() => <Winners/>}/>
                            <Route path={ROUTES.OVERLAYS}
                                   render={() => <OverlayPublic/>}/>
                            <Route exact path={ROUTES.NOT_FOUND} render={() => <NotFound/>}/>
                            <Redirect from={ROUTES.OTHER} to={ROUTES.NOT_FOUND}/>
                        </Switch>
                    </div>
                </AppWrap>
            </main>
        </div>
    );
}

export default App;
