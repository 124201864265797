import React from "react";
import {Button, ModalStyles, ModalWrap} from "../common-styles/Common.styles";
import closeButton from "../assets/svg/close-modal.svg";
import {useDispatch} from "react-redux";
import {removeQuestion} from '../redux/questions-reducer';

type PropsType = {
    title: string
    text: string
    description: string
    questionId?: string
    callBack?: () => void
    setWarningModal?: (value: boolean) => void
}

export const WarningModal: React.FC<PropsType> = ({
                                                      callBack,
                                                      setWarningModal,
                                                      questionId,
                                                      description,
                                                      title,
                                                      text,
                                                  }) => {
    const dispatch = useDispatch()

    const onClickHandler = () => {
        if (questionId) dispatch(removeQuestion(questionId))
        callBack && callBack()
        setWarningModal && setWarningModal(false)
    }
    return (
        <ModalWrap>
            <div className={'test'}>
                <ModalStyles>
                    <div className={'border'}>
                        <h2>{title}</h2>
                        <img
                            src={closeButton}
                            alt="close img"
                            onClick={() => {
                                setWarningModal && setWarningModal(false)
                            }}
                        />
                    </div>
                    <div className={'form'}>
                        <div className={'text'}>
                            {
                                description
                                    ? <span className={'answer-text-wrap'}>{text}<span><strong>{description}</strong>?</span></span>
                                    : <span>Вы действительно хотите удалить вопрос ?</span>
                            }
                        </div>
                        <Button
                            variant="extended"
                            sx={{alignSelf: 'flex-end'}}
                            className={'button primary red'}
                            onClick={onClickHandler}>Удалить</Button>
                    </div>
                </ModalStyles>
            </div>
        </ModalWrap>
    )
}


