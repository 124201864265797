import { PARTICIPANT_STATUS, QUESTION_STATUS, QUIZ_STATUS } from "../constants";
import { QUESTION_FRONT_TYPES, QUESTION_TYPES } from "../pages/questions/modal";
import { vkAPI } from "./api";

export type PointsStrategyType =
  | "pointsByMaxMinus1PerCorrectAnswer"
  | "pointPerCorrectAnswer"
  | "otherStrategies";

export type vkAPIType = typeof vkAPI;

export type ResponseType<T = {}> = {
  data: T;
  messages: Array<{ message: string }>;
  resultCode: number;
};

export type QuizType = {
  _id: string;
  channelName: string;
  youtubeChannel: string;
  color: string;
  currentQuestionId: null | string;
  description: string;
  participantsCount: number;
  pointsStrategy: PointsStrategyType;
  questions: QuestionType[];
  status: QUIZ_STATUS;
  startDate: Date;
  mode: ModeType;
  timeZone: string;
  title: string;
  youtubeStreamLink: string;
  repostedLinks: RepostedLinksType;
  delay: number;
  delayActivated: boolean;
};

export type ModeType = 'public' | 'not-public' | 'test-mode';

export type RepostedLinksType = {
  fb: string | null;
  vk: string | null;
};

export type QuestionType = {
  id: string;
  title: string;
  numberId: number;
  status: QUESTION_STATUS;
  questionDescription: string;
  answers: string[];
  type: QUESTION_TYPES;
  imgData: ImgDataType | null;
  maxAttemptsNumber: number;
  correctAnswersCount: number;
};

export type ImgDataType = {
  imgID: string;
  imgUrl: string;
};

export type updateQuestionRequestType = {
  questionId: string;
  title: string;
  answers: string[];
  maxAttemptsNumber: number;
  type: keyof typeof QUESTION_FRONT_TYPES;
  questionDescription: string;
};

export type StatisticsType = {
  participantAmount: number;
  notApprovedParticipantAmount: number;
  questionStatistic: Array<QuestionStatistics>;
  participantStatistic: PaginationType<ParticipantStatisticType[]>
};

interface Participant {
  _id: string;
  quizId: string;
}

export interface VKParticipantsType extends Participant {
  socialNetworkLink: string;
}

export interface ParticipantsVKType extends Participant {
  vkId: number;
  newStatus?: string;
  attemptData?: AttemptType;
}

export type QuestionStatistics = {
  questionDescription: string;
  participantsWhoAddSomeAnswerCount: number;
  participantsWhoAddCorrectAnswerCount: number;
  incorrectAnswersCount: number;
  participantsWhoAddAllIncorrectAnswerCount: number;
};

export type TopParticipantType = {
  accountId: string;
  name: string;
  avatarURL: string;
  points: number;
  socialNetworkProvider: SocialNetworkProviderType;
};

export type SocialNetworkProviderType = {
  currentProvider: "vk" | "facebook";
  profileLink: string | null;
};

export type DataType = {
  resultCode: number;
  messages: [
    {
      message: string;
      field?: Array<string>;
    }
  ];
};

export type UpdateSettingPagType = DataType & {
  data: QuizType;
};

export enum Statuses {
  Success = 0,
}

export type AttemptType = {
  type: string;
  originalPostLink: string;
  error: string;
  callstack: string;
};

export type QuestionStatisticType = {
  correctAnswersCount: number;
  incorrectAnswersCount: number;
  usersCountWhoAnsweredIncorrectly: number;
};

export type ParticipantStatusType = {
  participantId: string;
  quizId: string;
  newStatus: PARTICIPANT_STATUS;
  attemptData: AttemptType;
};

export type Top15ParticipantType = {
  name: string;
  avatarURL: string;
  points: number;
};

export type OverlayType = {
  type: OVERLAY_TYPES;
  active: boolean;
};

export type DataOverlaysType = {
  meaningOverlays?: OverlayType[];
  image?: string;
  text?: string;
  top15Participants?: Top15ParticipantType[];
  questionStatistic?: QuestionStatisticType;
  errors?: string[];
};

export type WSResponseType = {
  data: DataOverlaysType;
};

export type WSResDelayType = {
  delayActivated: boolean;
};

export type ParticipantRegistrationInfoResType = {
  provider: SOCIAL_NETWORK_PROVIDERS;
  registrationParticipantsData: {
    approvedParticipants: number;
    pendingParticipants: number;
    notApprovedParticipants: number;
    blockedParticipants: number;
  };
};

export type ParticipantStatisticType = {
  accountId: string;
  name: string;
  avatarURL: string;
  points: number;
  socialNetworkProvider: SocialNetworkProviderType
};

export interface PaginationType<T> {
  pagesCount: number;
  page: number;
  pageSize: number;
  totalCount: number;
  items: T;
}

export enum ROUTS_SOCKET_IO {
  CLIENT_OVERLAY_EVENT = "client-overlay-event",
  OVERLAY_EVENT = "overlay-event",
  STATISTIC_BY_QUESTION_EVENT = "statistic-by-question-event",
  DELAY_EVENT = "delay-event",
  REGISTRATION_INFO = "registration-info",
}

export enum OVERLAY_TYPES {
  IMAGE = "image",
  TEXT = "text",
  TOP10 = "top-10",
  TOP20_POINTS = "top-20",
  QUESTION_STATISTICS = "questionStatistics",
  CORRECT_ANSWERS = "correctAnswers",
}

export enum SOCIAL_NETWORK_PROVIDERS {
  FACEBOOK = "facebook",
  VK = "vk",
}

export type ErrorType = {
  message: string
  path: string
  statusCode: number
  timestamp: Date
}
