import React, {useCallback, useEffect} from 'react'
import {CommonWrapper, StatisticsBlock, TableStyles} from "../../common-styles/Common.styles";
import {useSelector} from "react-redux";
import {
    setNotApprovedParticipantAmount,
    setParticipantAmount,
    setQuestionStatistics
} from '../../redux/statistick-reducer';
import {StatisticsTable} from "../tables/SettingsTable";
import {StatisticQuestion} from "./StatisticQuestion";
import {useStatisticsInterval} from "../../hooks";
import {PageTitle} from "../../common-components/PageTitle/PageTitle";


export const Statistics = () => {

    useStatisticsInterval()

    const participantAmount = useSelector(setParticipantAmount)
    const notApprovedParticipantAmount = useSelector(setNotApprovedParticipantAmount)
    const questionStatistics = useSelector(setQuestionStatistics)

    const checkValueHandler = useCallback(function (value: number) {
        return value ? value : 0
    }, [questionStatistics])


    const _questionStatistics = questionStatistics && questionStatistics.map((q, i) =>
        <StatisticQuestion key={i}
                           questionOrder={++i}
                           questionDescription={q.questionDescription}
                           incorrectAnswersCount={q.incorrectAnswersCount}
                           participantsWhoAddAllIncorrectAnswerCount={q.participantsWhoAddAllIncorrectAnswerCount}
                           participantsWhoAddSomeAnswerCount={q.participantsWhoAddSomeAnswerCount}
                           participantsWhoAddCorrectAnswerCount={q.participantsWhoAddCorrectAnswerCount}
                           checkValueHandler={checkValueHandler}/>)


    return (
        <div>
            <CommonWrapper>
                <PageTitle text={'Статистика вопросов'}/>
                <StatisticsBlock>
                    <div className={'StatisticsWrap'}>
                        <div className={'StatisticsContent'}>
                            <h4>Кол-во зарегистрированных участников</h4>
                            <span>{checkValueHandler(participantAmount)} человек</span>
                        </div>
                        <div className={'StatisticsContent'}>
                            <h4>Кол-во заявок не прошедших валидацию</h4>
                            <span>{checkValueHandler(notApprovedParticipantAmount)} человек</span>
                        </div>
                    </div>
                </StatisticsBlock>
                <TableStyles>
                    <StatisticsTable/>
                </TableStyles>
                <div className={'statistic-scroll-block'}>
                    {_questionStatistics}
                </div>
            </CommonWrapper>
        </div>
    )
}


