import React from 'react';
import {useSelector} from "react-redux";
import {AppStateType} from "../../redux/redux-store";
import {OVERLAY_TYPES, OverlayType} from "../../api/api-types";
import s from './owner-styles/animation.module.css'
import {CSSTransition} from 'react-transition-group';

export const ImageBlock: React.FC = () => {

    const img = useSelector<AppStateType, string | null>((state) => state.overlays.image)
    const imgOverlay = useSelector<AppStateType, OverlayType | undefined>((state) =>
        state.overlays.meaningOverlays.find(el => el.type === OVERLAY_TYPES.IMAGE))

    return (
        <>
                <CSSTransition
                    in={imgOverlay?.active}
                    timeout={600}
                    classNames="alert"
                    unmountOnExit
                >

                    <div className={s.imgBox}>
                        {img && <img
                            src={img} alt="img"
                        />}
                    </div>
                </CSSTransition>
        </>
    );
}

