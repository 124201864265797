import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../../redux/redux-store";
import {OVERLAY_TYPES, OverlayType, Top15ParticipantType} from "../../../api/api-types";
import {setTopParticipants} from "../../../redux/overlays-reducer";
import {CSSTransition} from 'react-transition-group';
import s from '../owner-styles/animation.module.css'
import {Participant} from "./Participant";
import { S } from '../winners/styles/Participants_Styles'



export const Winners: React.FC = () => {
    const dispatch = useDispatch()
    const meaningOverlays = useSelector<AppStateType, OverlayType[]>(state => state.overlays.meaningOverlays)
    const topParticipants = useSelector<AppStateType, Top15ParticipantType[] | null>(state => state.overlays.top15Participants)

    // const user = {
    //     name: 'Denis Kavabangovi4',
    //     avatarURL: 'https://static01.nyt.com/images/2022/09/16/arts/16CAMERON1/merlin_32166390_1d2bc63a-bc12-42d9-a341-dd4298250844-superJumbo.jpg?quality=75&auto=webp',
    //     points: 40
    // }
    // let topParticipants: Top15ParticipantType[] | null = []

    // for (let i = 0; i < 20; i++) {
    //     topParticipants.push(user)
    // }
    const getOverlayByType = useCallback(
        function getOverlayByType(meaningOverlays: OverlayType[], overlayType: OVERLAY_TYPES): OverlayType | undefined {
            return meaningOverlays.find(el => el.type === overlayType);
        }, [meaningOverlays])

    const getMappedTopParticipantsArray = (): Top15ParticipantType[] | null => {
        const overlay_top20 = getOverlayByType(meaningOverlays, OVERLAY_TYPES.TOP20_POINTS);
        if (!topParticipants) return null
        return overlay_top20?.active ? topParticipants : topParticipants.slice(0, 10);
    }

    const mappedTopParticipants = getMappedTopParticipantsArray()?.map((el, i) => <Participant
        key={i}
        index={i}
        name={el.name}
        avatarURL={el.avatarURL}
        points={el.points}
    />)

    const overlay_top20 = getOverlayByType(meaningOverlays, OVERLAY_TYPES.TOP20_POINTS);

    useEffect(() => {
        const overlay_top10 = getOverlayByType(meaningOverlays, OVERLAY_TYPES.TOP10);
        if (!overlay_top10?.active && !overlay_top20?.active) {
            dispatch(setTopParticipants(null))
        }
    }, [meaningOverlays])

    return (
        <CSSTransition
            in={!!mappedTopParticipants?.length}
            timeout={600}
            classNames="alert"
            unmountOnExit
        >
            <S.Participants overlaySize={overlay_top20?.active ? 'big' : 'small'}>
                <div className={s.participants} >
                    <h2 className={s.title}>
                        {overlay_top20?.active ? 'Toп-20 участников' : 'Toп-10 участников'}
                    </h2>
                    <S.ParticipantsWrap overlaySize={overlay_top20?.active ? 'big' : 'small'}>
                        {mappedTopParticipants}
                    </S.ParticipantsWrap>
                </div>
            </S.Participants>
        </CSSTransition>
    );
}



