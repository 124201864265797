export const enum PARTICIPANT_STATUS {
    NULL = 'null',
    APPROVED = 'approved',
    PENDING = 'pending',
    BAD_LINK = 'bad-link',
    PRIVATE_ACCT = 'private_network',
}

export const enum QUIZ_STATUS {
    started = 'started',
    pending = 'pending',
    closed = "closed"
}

export const enum QUESTION_STATUS {
    STARTED = 'started',
    WAITING = 'onWaiting',
    CLOSED = 'closed',
}

export const enum NOTIFICATION_QUIZ_MESSAGE {
    PASSED = 'Quiz пройден',
    LAUNCHED = 'Вы не можете удалить заполненный вопрос после старта викторины',
    SETTINGS_INSTALL = 'Настройки установлены',
    SET_ALL_QUESTION = 'Пожалуйста, заполните все вопросы!',
    QUIZ_IS_INACTIVE = 'Стрим ещё не запущен',
    CURRENT_QUESTION_WAS_STARTED = 'Вы не можете редактировать тукущий вопрос',
    IS_QUESTION_NOT_COMPLETED = 'Заполните предыдущий добавленный вопрос',
    TOKEN_COPIED = 'Tокен скопирован',
    LINK_COPIED = 'Ссылка скопирована',
    RESET = '',
}

export const enum FACEBOOK_NOTIFICATIONS {
    NO_FACEBOOK_LINK = 'Вы не указали ссылку на facebook пост',
    USERS_NOT_FOUNDS = 'Нет пользователей сделавших репост',
    NO_AUTHORIZED_FB = 'Нет удалось авторизироваться в Facebook, попробуйте еще раз',
}

export const enum VK_NOTIFICATIONS {
    NO_VK_LINK = 'Вы не указали ссылку на VK пост',
    NO_AUTHORIZED_VK = 'Нет удалось авторизироваться в VK, попробуйте еще раз',
}

