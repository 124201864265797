import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from './redux/redux-store'
import {setHandle401} from "./api/api";
import {setIsLogged} from "./redux/app-reducer";

setHandle401(() => {
     store.dispatch(setIsLogged(false));
});


ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <App/>
        </Provider>
    </BrowserRouter>
    ,
    document.getElementById('root')
);

reportWebVitals();
