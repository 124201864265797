import {quizAPI, settingQuizAPI} from "../api/api";
import {ThunkAction} from "redux-thunk";
import {AppStateType} from "./redux-store";
import {setLoading, setProgress} from "./app-reducer";
import {findLastClosedQuestion, setQuestions} from "./questions-reducer";
import {setEventVoting} from "./quiz-reducer";
import {logger} from "../logger";
import {FormsType} from "../pages/settingPage";
import {FACEBOOK_NOTIFICATIONS, NOTIFICATION_QUIZ_MESSAGE, VK_NOTIFICATIONS} from "../constants";
import {ModeType, QuizType, RepostedLinksType, Statuses} from "../api/api-types";
import {Dispatch} from "redux";

const SET_EVENT_VALUE = 'setting/SET_EVENT_VALUE';
const SET_DATE_SETTING = 'setting/SET-DATE-SETTING';
const SET_DELAY = 'setting/SET-DELAY';

type ActionsTypes =
    | ReturnType<typeof setAlertMessage>
    | ReturnType<typeof setLoading>
    | ReturnType<typeof setDateSettings>
    | ReturnType<typeof setProgress>
    | ReturnType<typeof setQuestions>
    | ReturnType<typeof setEventVoting>
    | ReturnType<typeof findLastClosedQuestion>
    | ReturnType<typeof setDelay>

type ThunkType = ThunkAction<void, AppStateType, unknown, ActionsTypes>

export type SettingsInitialStateType = {
    quizId: null | string
    title: string
    channelName: string
    youtubeChannel: string
    youtubeStreamLink: string
    repostedLinks: RepostedLinksType | null
    description: string
    notificationMessage: string
    openPicker: boolean
    startDate: Date | null
    mode: ModeType | null
    timeZone: string
    delay: number
    delayActivated: boolean
}

const initialState: SettingsInitialStateType = {
    quizId: null,
    notificationMessage: '',
    title: '',
    channelName: '',
    youtubeChannel: '',
    youtubeStreamLink: '',
    repostedLinks: null,
    description: '',
    openPicker: false,
    startDate: null,
    mode: null,
    timeZone: '',
    delay: 0,
    delayActivated: false,
};

export const settingsReducer = (state = initialState, action: ActionsTypes): SettingsInitialStateType => {

    switch (action.type) {
        case SET_EVENT_VALUE: {
            return {
                ...state,
                notificationMessage: action.event,
            }
        }

        case SET_DATE_SETTING: {

            return {
                ...state,
                quizId: action.payload._id,
                title: action.payload.title,
                channelName: action.payload.channelName,
                youtubeChannel: action.payload.youtubeChannel,
                description: action.payload.description,
                youtubeStreamLink: action.payload.youtubeStreamLink,
                repostedLinks: action.payload.repostedLinks,
                startDate: action.payload.startDate ? new Date(action.payload.startDate) : new Date(), //Here is format date toISOString
                mode: action.payload.mode,
                timeZone: action.payload.timeZone,
                delay: action.payload.delay,
                delayActivated: action.payload.delayActivated
            }
        }
        case SET_DELAY: {
            return {
                ...state,
                delayActivated: action.delayActivated
            }
        }

        default :
            return state;
    }
}

export const setAlertMessage = (event: NOTIFICATION_QUIZ_MESSAGE | FACEBOOK_NOTIFICATIONS | VK_NOTIFICATIONS | string) => ({
    type: SET_EVENT_VALUE,
    event,
} as const)

export const setDateSettings = (data: QuizType) => ({
    type: SET_DATE_SETTING,
    payload: data
} as const)

export const setDelay = (delayActivated: boolean) => ({
    type: SET_DELAY,
    delayActivated
} as const)


export const updateSettingsPage = (settingsDate: FormsType): ThunkType => async (dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await settingQuizAPI.settingsPage(settingsDate)
        if (response.resultCode === Statuses.Success) {
            dispatch(setDateSettings(response.data))
            dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.SETTINGS_INSTALL))
        } else {
            logger.error('Bad response updateSettingsPage', response.messages[0].message)
        }
    } catch (error) {
        logger.error('updateSettingsPage', error)

    } finally {
        dispatch(setProgress(false))
    }
}


export const getDataByQuiz = (quizId: string): ThunkType => async (dispatch) => {
    try {
        const response = await settingQuizAPI.getSettings(quizId)
        dispatch(setDateSettings(response))
        dispatch(setEventVoting(response.status))
        dispatch(setQuestions(response.questions))
        dispatch(findLastClosedQuestion(response.questions))
    } catch (error) {
        logger.error('getDataByQuiz', error)
    } finally {
        dispatch(setLoading(false))
    }
}

export const setQuizDelay = (delay: number) => async (dispatch: Dispatch) => {
    try {
        dispatch(setProgress(true))
        const response = await quizAPI.setQuizDelay(delay)
        if (response.resultCode === Statuses.Success) {
            dispatch(setDateSettings(response.data))
        }
    } catch (error) {
        logger.error('updateSettingsPage', error)
    } finally {
        dispatch(setProgress(false))
    }
}

export const setYoutubeLink = (state: AppStateType): string => state.settings.youtubeStreamLink
export const getRepostedLink = (state: AppStateType): RepostedLinksType | null => state.settings.repostedLinks
export const getStartDate = (state: AppStateType): Date | null => state.settings.startDate
export const getQuizId = ((state: AppStateType): string => state.settings.quizId!)
export const setTitle = ((state: AppStateType): string => state.settings.title)
export const setChannelName = ((state: AppStateType): string => state.settings.channelName)
export const setYoutubeChannel = ((state: AppStateType): string => state.settings.youtubeChannel)
export const setDescription = ((state: AppStateType): string => state.settings.description)
export const getDelay = ((state: AppStateType): number => state.settings.delay)
export const getDelayActivated = ((state: AppStateType): boolean => state.settings.delayActivated)
export const setNotificationMessage = (state: AppStateType): NOTIFICATION_QUIZ_MESSAGE => state.settings.notificationMessage as NOTIFICATION_QUIZ_MESSAGE
