import {SettingsErrorType} from "../pages/settingPage";
import {
    facebookWallPostLink,
    patterOfValidRepostedLink,
    youtubeChannelRegTypeOne,
    youtubeChannelRegTypeTwo,
    youtubeStreamLink, zoomLinkPattern
} from "../constants";
import {FormikValues} from "formik";
import {QUESTION_FRONT_TYPES, QuestionErrorType} from "../pages/questions/modal";
import {ModeType} from "../api/api-types";


export const filterArrayAnswers = (answers: string[]) => {
    return answers.filter(a => a?.trim())
}

export const validateSettingsForm = (mode: ModeType | null): (values: FormikValues) => SettingsErrorType => {
    return (values: FormikValues): SettingsErrorType => {
        const modeIsPublic = mode === 'public'
        const modeIsNotPublic = mode === 'not-public'
        const errors: SettingsErrorType = {};

        if (!values.title?.trim()) {
            errors.title = 'Название конференции обязательно';
        }

        if (!values.channelName?.trim()) {
            errors.channelName = 'Название YouTube канала обязательно';
        }

        if (!values.youtubeChannel?.trim()) {
            errors.youtubeChannel = 'Ссылка на YouTube канал обязательна'
        } else if (!youtubeChannelRegTypeTwo.test(values.youtubeChannel) && !youtubeChannelRegTypeOne.test(values.youtubeChannel)) {
            errors.youtubeChannel = 'Ссылка YouTube канала невалидная'
        }

        if (!values.youtubeStreamLink && !modeIsNotPublic) {
            errors.youtubeStreamLink = 'Ссылка на стрим обязательна';
        } else if (!modeIsNotPublic && !youtubeStreamLink.test(values.youtubeStreamLink)) {
            errors.youtubeStreamLink = 'Ссылка на YouTube стрим невалидная';
        }

        if (modeIsPublic && !values.repostedLinkVK && !values.repostedLinkFB) {
            errors.repostedLinkVK = 'Укажите хотя бы одну ссылку на репост';
            errors.repostedLinkFB = 'Укажите хотя бы одну ссылку на репост';
        }

        if (modeIsPublic && values.repostedLinkVK && !patterOfValidRepostedLink.test(values.repostedLinkVK)) {
            errors.repostedLinkVK = 'Ссылка на репост VK невалидная';
        }


        if (modeIsPublic && values.repostedLinkFB && !facebookWallPostLink.test(values.repostedLinkFB)) {
            errors.repostedLinkFB = 'Ссылка на репост Facebook невалидная';
        }

        return errors;
    }
}

export const validateQuestionForm = (values: FormikValues): QuestionErrorType => {
    const errors: QuestionErrorType = {};

    if (!values.questionDescription?.trim()) {
        errors.questionDescription = 'Обязательное поля для текущего типа вопроса'
    } else if (values.questionDescription?.trim().length > 1000) {
        errors.questionDescription = 'Вопрос может содержать не более 1000 символов'
    }

    if (values.notes?.trim().length > 1000) {
        errors.notes = 'Заметка может содержать не более 1000 символов'
    }

    if (!filterArrayAnswers(values.answers).length) {
        errors.answers = `Должен быть минимум 1 вариант ответа`;
    }

    if (values.questionType === QUESTION_FRONT_TYPES.image && !values.image) {
        errors.image = 'Изображение обязательно';
    }

    return errors;
}