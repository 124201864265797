export enum ROUTES {
    BASE_URL='/',
    SETTING_PAGE='/settingPage',
    QUESTIONS = '/questions',
    VERIFY_PARTICIPANTS = '/verifyParticipants',
    QUIZ = '/quiz',
    STATISTICS = '/statistics',
    WINNERS = '/winners',
    OVERLAYS = '/stream/overlays',
    NOT_FOUND = '/404',
    OTHER = '*'
}
