import React from 'react';
import {NavLink} from "react-router-dom";
import {SideBarBackground, SidebarNav, SidebarWrap} from '../../common-styles/Common.styles';
import Storage from "../../utils/storage";
import settingsIcon from "../../assets/svg/sidebarIcons/settings.svg";
import questionIcon from "../../assets/svg/sidebarIcons/questions.svg";
import checkParticipantsIcon from "../../assets/svg/sidebarIcons/participants.svg";
import quizIcon from "../../assets/svg/sidebarIcons/quiz.svg";
import statisticIcon from "../../assets/svg/sidebarIcons/statistic.svg";
import topParticipantsIcon from "../../assets/svg/sidebarIcons/top-participants.svg";
import overlaysIcon from "../../assets/svg/sidebarIcons/overlays.svg";
import copyIcon from "../../assets/svg/Copy.svg";
import {copyToBuffer} from "../../utils/copyToBuffer";
import {useDispatch} from "react-redux";
import {NOTIFICATION_QUIZ_MESSAGE} from "../../constants";


type PropsType = {
    modal: boolean
    quizId: string | null
    overlayFrontActive: boolean
}

export const Sidebar: React.FC<PropsType> = ({
                                                 modal,
                                                 quizId,
                                                 overlayFrontActive
                                             }) => {
    const dispatch = useDispatch()
    const host = `${process.env.REACT_APP_FRONT_QUIZ_URL || 'https://quiz.staging.it-incubator.ru'}/${quizId}`
    const location = {
        pathname: host,
        state: {fromDashboard: true}
    }

    const copyToBufferHandler = async () => {
        await copyToBuffer(dispatch, NOTIFICATION_QUIZ_MESSAGE.LINK_COPIED, host)
    }

    return (
        <>
            {
                !overlayFrontActive &&
                <SideBarBackground modal={modal}>
                    <SidebarWrap>
                        <aside style={{position: 'relative', height: '100%'}}>
                            <SidebarNav>
                                <NavLink to="/settingPage"
                                         className="active-styles"
                                         activeClassName="active">
                                    <img src={settingsIcon} alt="settings"/>
                                    Настройки страницы</NavLink>
                                <NavLink to="/questions"
                                         className="active-styles"
                                         activeClassName="active">
                                    <img src={questionIcon} alt="settings"/>
                                    Вопросы
                                </NavLink>
                                <NavLink to="/verifyParticipants"
                                         className="active-styles"
                                         activeClassName="active">
                                    <img src={checkParticipantsIcon} alt="settings"/>
                                    Проверка участников</NavLink>
                                <NavLink to="/quiz"
                                         className="active-styles"
                                         activeClassName="active">
                                    <img src={quizIcon} alt="settings"/>
                                    Викторина</NavLink>
                                <NavLink to="/statistics"
                                         className="active-styles"
                                         activeClassName="active">
                                    <img src={statisticIcon} alt="settings"/>
                                    Статистика вопросов</NavLink>
                                <NavLink to="/winners"
                                         className="active-styles"
                                         activeClassName="active">
                                    <img src={topParticipantsIcon} alt="settings"/>
                                    Топ участников</NavLink>
                                <NavLink to={`/stream/overlays?token=${Storage.getToken()}&quizId=${quizId}`}
                                         target="_blank"
                                         className="active-styles"
                                         activeClassName="active">
                                    <img src={overlaysIcon} alt="settings"/>
                                    Overlays</NavLink>
                                <div className={'public-link'}>
                                    <NavLink to={location}
                                             target="_blank"
                                             className="link active-styles">
                                        Публичная страница
                                    </NavLink>
                                    <img
                                        src={copyIcon}
                                        alt="publicPage"
                                        onClick={copyToBufferHandler}/>
                                </div>
                            </SidebarNav>
                        </aside>
                    </SidebarWrap>
                </SideBarBackground>
            }
        </>

    );
};
