import React from 'react'
import { Error } from '../common-styles/Common.styles'


export const NotAuthorized = () => {
   return (
        <Error>
            <h1>401 YOU ARE NOT AUTHORIZED</h1>
        </Error>
    )
}

