import React, {useEffect} from 'react';
import {ButtonBot, QuestionsStyles} from "../../common-styles/Common.styles";
import {useDispatch, useSelector} from "react-redux";
import {AppStateType} from "../../redux/redux-store";
import circle from "../../assets/img/Group 651.png";
import {setCurrentNumberVoteQuestion} from '../../redux/quiz-reducer';
import {ImgDataType, QuestionType} from "../../api/api-types";
import {openQuestion, sendQuestionClosingTimer} from "../../redux/questions-reducer";
import {setAlertMessage} from "../../redux/settings-reducer";
import {NOTIFICATION_QUIZ_MESSAGE, QUESTION_STATUS, QUIZ_STATUS} from "../../constants";
import {QUESTION_FRONT_TYPES, QUESTION_TYPES} from "../questions/modal";
import {ImgByQuestion} from "../../common-components/ImgByQuestion";
import {usePictureToggle} from "../../hooks";
import {questionTimer} from "../../utils/questionTimer";
import {QuestionDataType} from "../../hooks/useQuestionTmer";
import {socketIoApi} from "../../api/api";
import styled, {keyframes} from "styled-components";
import Storage from "../../utils/storage";

type QuestionPropsType = {
    quizId: string
    questionId: string
    numberId: number
    status: string
    isQuizActive: QUIZ_STATUS
    title: string
    answers: string[]
    questionDescription: string
    imgData: ImgDataType | null
    attemptsNumber: number
    questionType: QUESTION_TYPES
    timer: number | null
    closeQuestionFlag: boolean
    setActiveTimer: (value: boolean) => void
    setTimer: (timer: number) => void
    setQuestionData: (data: QuestionDataType) => void
}

const QuizQuestion: React.FC<QuestionPropsType> = React.memo(function (props) {
    const dispatch = useDispatch();

    const progress = useSelector<AppStateType, boolean>((state) => state.app.progress);
    const currentQuestionNumber = useSelector<AppStateType, number>((state) => state.quiz.currentQuestionNumber);
    const questions = useSelector<AppStateType, Array<QuestionType>>((state) => state.questions.questions);

    const {isZoomImg, handlePicture, closeZoomPicture} = usePictureToggle();

    const isStartButton = ((props.isQuizActive === QUIZ_STATUS.started)
        && (currentQuestionNumber === props.numberId) && (props.status === QUESTION_STATUS.WAITING))
    const isStopButton = ((props.isQuizActive === QUIZ_STATUS.started)
        && (currentQuestionNumber === props.numberId) && (props.status === QUESTION_STATUS.STARTED))


    const openVoteQuestion = () => {
        dispatch(openQuestion(props.quizId, props.questionId))
    }

    const setQuestionClosingTimer = async () => {
        const result = await dispatch(sendQuestionClosingTimer())
        if (!result) return
        const questionData = {
            quizId: props.quizId,
            questionId: props.questionId,
            questionsLength: questions.length,
            numberId: props.numberId
        }
        Storage.setQuestionData(questionData);
        props.setQuestionData(questionData)
        props.setTimer(10);
        props.setActiveTimer(true);
    }


    useEffect(() => {
        const currentQuestionNumber = questions.findIndex((q) => (q.status === QUESTION_STATUS.WAITING || q.status === QUESTION_STATUS.STARTED)) + 1
        dispatch(setCurrentNumberVoteQuestion(currentQuestionNumber))

        return () => {
            dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.RESET))
        }
    }, [dispatch])


    return (
        <>
            <QuestionsStyles>
                <div className={'tableGrid'}>
                    <div className={'number-block'}>
                        <div className={'position'}>
                            <img src={circle} alt="circle"/>
                            <div>#{props.numberId}</div>
                        </div>
                    </div>
                    <div className={'quiz-theme-block'}>
                        <span>{props.title}</span>
                    </div>
                    <div className={'description-block'}>
                        <div className={'flex-block'}>
                            <span>{props.questionDescription}</span>
                        </div>
                    </div>
                    <div className={'type-question-block position-question-block'}>
                        <div className={'type-question-block'}>
                            <div className={'type-answer-block position-question-type-block'}>
                                {
                                    props.questionType === 'image'
                                        ? <ImgByQuestion imgData={props.imgData}
                                                         isZoomImg={isZoomImg}
                                                         handlePicture={handlePicture}
                                                         closeZoomPicture={closeZoomPicture}/>
                                        : QUESTION_FRONT_TYPES[props.questionType]
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'type-question-block position-question-block wrap-answer'}>
                        <div>
                            <ol>
                                {
                                    props.answers && props.answers.map((a, i) => <li key={i}>{a}</li>)
                                }
                            </ol>
                        </div>
                    </div>
                    <div className={'attempts-number-block center-content'}>
                        <span>{props.attemptsNumber}</span>
                    </div>
                    <div
                        className={'pictures-block'}
                        style={{display: "grid"}}
                    >
                        {
                            isStartButton && <ButtonBot
                                status={props.status}
                                eventVoting={false}
                                className={'active-button'}
                                onClick={openVoteQuestion}
                            >
                                Запустить
                            </ButtonBot>
                        }
                        {
                            isStopButton && <>
                                {
                                    props.timer !== null ?
                                        (
                                            <BlinkingText>
                                                <span className={'timer'}>{questionTimer(props.timer)}</span>
                                            </BlinkingText>
                                        )
                                        :
                                        (isStopButton && !props.closeQuestionFlag && <ButtonBot
                                                progress={progress}
                                                timer={props.timer}
                                                status={props.status}
                                                eventVoting={false}
                                                className={'active-button'}
                                                onClick={setQuestionClosingTimer}
                                            >
                                                Остановить
                                            </ButtonBot>
                                        )}
                            </>

                        }
                        {!isStartButton && !isStopButton && <div className={'stub'}/>}
                    </div>
                </div>
            </QuestionsStyles>
        </>
    );
});

export default QuizQuestion;

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BlinkingText = styled.div`
  display: block;
  animation: ${blinkAnimation} 1s linear infinite;
`;