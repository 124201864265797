import React from 'react';
import {useSelector} from "react-redux";
import {AppStateType} from "../../redux/redux-store";
import {OVERLAY_TYPES, OverlayType, QuestionStatisticType} from "../../api/api-types";
import {CSSTransition} from 'react-transition-group';
import s from './owner-styles/animation.module.css'

export const QuestionStatistic: React.FC = () => {

    const questionStatisticOverlay = useSelector<AppStateType, OverlayType | undefined>((state) => state.overlays.meaningOverlays.find(el => el.type === OVERLAY_TYPES.QUESTION_STATISTICS))
    const questionStatistic = useSelector<AppStateType, QuestionStatisticType | null>((state) => state.overlays.questionStatistic)
    const totalNumberAnswersToTheQuestion = questionStatistic && questionStatistic.correctAnswersCount + questionStatistic.incorrectAnswersCount

    return (
        <>
            {
                questionStatistic && <CSSTransition
                    in={questionStatisticOverlay?.active}
                    timeout={600}
                    classNames="alert"
                    unmountOnExit
                >
                    <div className={s.stats}>
                        <h2 className={s.title}>Статистика ответов на вопрос:</h2>
                        <div className={s.text}>
                            <div>
                                <span>Правильно ответили:</span>
                                {questionStatistic.correctAnswersCount}
                            </div>
                            <div>
                                <span>Неправильно ответили:</span>
                                {questionStatistic.usersCountWhoAnsweredIncorrectly}
                            </div>
                            <div>
                                <span>Количество попыток:</span>
                                {totalNumberAnswersToTheQuestion}
                            </div>

                        </div>
                    </div>
                </CSSTransition>
            }
        </>
    );
}
