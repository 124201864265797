import React, {FC, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {useSelector} from "react-redux";
import {getStartDate} from "../../../redux/settings-reducer";
import {FormikValues} from "formik/dist/types";
import {DatePickerWrapper} from "./CustomDataPicker_Styles";

type  DataPickerComponentType = {
    formik: FormikValues
}

export const DataPickerComponent: FC<DataPickerComponentType> = ({formik}) => {
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const previouslySetDate = useSelector(getStartDate);

    useEffect(() => {
        setStartDate(previouslySetDate);
    }, [previouslySetDate])

    useEffect(() => {
        const isErrorStartDateField = formik.errors.startDate;
        if (isErrorStartDateField) formik.setFieldError('startDate', '');
        formik.setFieldValue("startDate", startDate);
    }, [startDate])

    return (
        <DatePickerWrapper>
            <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy - HH:mm"
                showTimeInput
            />
        </DatePickerWrapper>

    )
}