import styled from "styled-components";
import {TextField} from "@mui/material";

export const StyledTextarea = styled(TextField)({
    '&.MuiTextField-root': {
        minWidth: '140px',
        marginTop: '5px',
    },
    '& .MuiOutlinedInput-root': {
        height: '100%',
        width: '100%',
        borderRadius: "2px",
        fontWeight: 500,
        fontSize: '16px',
        backgroundColor: "var(--neutralLight140)",
        position: "relative",
        padding: '8px 12px'
    },
    '& .MuiOutlinedInput-input': {
        height: '100%',
        '&::placeholder': {
            color: 'var(--neutralDark80)',
            opacity: 1
        },
    },

    '& fieldset': {
        borderColor: "#DEDBDC",
        '& legend': {
            display: "none"
        }
    },
});