import React, {useEffect, useLayoutEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    activateOverlayFront,
    getOverlays, setOverlayFrontActive,
} from "../../redux/overlays-reducer";
import {Loading} from "../../common-components/Loading";
import {ImageBlock} from "./ImageBlock";
import {TextBlock} from "./TextBlock";
import {Winners} from "./winners/Winners";
import {QuestionStatistic} from "./QuestionStatistic";
import {useHistory} from "react-router-dom";
import {OverlayWrapper} from '../../common-styles/Common.styles';
import {connectSocketOverlaysFront} from "../../utils";
import {getQuizId} from "../../redux/settings-reducer";
import {useQuery} from "../../hooks";
import Storage from "../../utils/storage";
import {CorrectAnswers} from "./correctAnswers/CorrectAnswers";


const OverlayPublic: React.FC = () => {
    const history = useHistory()
    const query = useQuery()
    const dispatch = useDispatch()
    const overlayActive = useSelector(setOverlayFrontActive)
    const quizId = useSelector(getQuizId)

    function removingQueriedParameters(param: string) {
        const queryParams = new URLSearchParams(window.location.search)
        queryParams.delete(param)
        history.replace({
            search: queryParams.toString(),
        })
    }

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "transparent"
        let token = query.get('token')
        if (token) {
            Storage.saveToken(token)
        }

        const quizId = query.get('quizId')

        if (quizId) {
            if (!overlayActive) {
                //If the front is drawn for the first time, we save the token in local storage
                // and cut it out of the query parameters
                removingQueriedParameters('token')
                dispatch(activateOverlayFront())
            }
            dispatch(getOverlays(quizId))
        } else {
            console.error('quizId not found!')
        }
    }, [])

    useEffect(() => {
        if (quizId) connectSocketOverlaysFront(quizId, dispatch)
    }, [quizId])


    if (!overlayActive) return <Loading/>

    return (
        <OverlayWrapper>
            <div className={'overlays-wrap'}>
                <ImageBlock/>
                <TextBlock/>
                <Winners/>
                <QuestionStatistic/>
                <CorrectAnswers/>
            </div>
        </OverlayWrapper>
    );
}

export default OverlayPublic;


