import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 24px;
  border: 1px solid #E42D2D;
  background-color: rgba(228, 45, 45, 0.05);
  color: #000000;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  gap: 12px;

  .stream-delay {
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #737067;
      white-space: nowrap;
    }
  }
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  margin: 0
`

export const S = {
    Wrapper,
    Text
}