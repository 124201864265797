import React from 'react';
import { S } from './WarningMessage_Styles';

export const WarningMessage = (props: {text: string, children?: React.ReactNode}) => {
    return (
        <S.Wrapper>
            <S.Text>
                {props.text}
            </S.Text>
            {props.children}
        </S.Wrapper>
    );
}


