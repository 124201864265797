import styled from "styled-components";

export const FbParticipantsWrap = styled.div`
  margin: 20px 0;
`

export const PostLink = styled.div`
  margin-bottom: 17px;

  a {
    color: #2D8CE4;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-decoration-line: underline;
  }
`