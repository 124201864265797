import React from 'react'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const Progress = () => {

    return (
        <Box sx={{
            width: '100%',
            position: 'fixed',
            top: '1px',
            left: '49%',
            transform: 'translate(-50%, -50%)',
            zIndex: 90,
        }}>
            <LinearProgress/>
        </Box>
    )
}

