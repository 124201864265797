import React from "react";
import {AccountType} from "./types";
import {AccountWrapper} from "./styles";

export const Account = ({photo, fullName, accountName}: AccountType) => {
  return (
    <AccountWrapper>
      <img src={photo} alt="Avatar"/>
      <div>
        <h3>{fullName}</h3>
        <span>Авторизация через аккаунт <strong>{accountName}</strong></span>
      </div>
    </AccountWrapper>
  );
}

