import {logger} from "../../logger";
import {
    FBPostType,
    LoginResponseType,
    FBUserType,
    FBResponseType,
    RepostUserType,
    ResponseLoginType, FacebookAuthResponseType
} from "./types/fb-types";


export class FacebookAPI {
    private static readonly instance = {
        appId: process.env.REACT_APP_API_ID_FB || '511592947685545',
        autoLogAppEvents: true,
        version: 'v15.0'
    }

    static async auth(): Promise<boolean> {
        window.FB.init(this.instance)

        return await new Promise((res, rej) => {
            window.FB.getLoginStatus(function (response: FacebookAuthResponseType) {

                if (response.unknown) {
                    console.log('response from Facebook auth rejected', response)
                    logger.debug('response from Facebook auth rejected', response)
                    rej(false)
                }

                if (response.status === 'connected') {
                    res(true)
                } else res(false)
            });
        })
    }

    static async loginFB(): Promise<ResponseLoginType> {
        window.FB.init(this.instance)


        return await new Promise((res, rej) => {
            window.FB.login(function (response: LoginResponseType) {
                if (response.authResponse) {
                    logger.debug('response from Facebook loginFB', response)
                    window.FB.api('/me', {
                        fields: 'email, name',
                    }, function (user: FBUserType) {
                        logger.debug('response from Facebook me', user)
                        res({
                            isLogged: true,
                            token_expiration_time: response.authResponse.data_access_expiration_time
                        })
                    });
                } else {
                    logger.error('User cancelled login or did not fully authorize.', response)
                    rej(false)
                }
            });
        })
    }

    static async getPostId(encryptedPostId: string): Promise<string> {
        return await new Promise((res, rej) => {
            window.FB.api(
                `/${encryptedPostId}`,
                function (response: FBPostType) {
                    if (response && !response.error) {
                        logger.debug('response from Facebook post', response)
                        res(response.id)
                    } else {
                        logger.error('response from Facebook post failed', response)
                        rej(response.error)
                    }
                }
            );
        })
    }

    static async getUsersRepostedPost(postId?: string): Promise<RepostUserType[]> {
        return await new Promise((res, rej) => {
            window.FB.api(
                `/${postId}/sharedposts?limit=1000&fields=from`,
                function (response: FBResponseType<RepostUserType[]>) {
                    if (response && !response.error) {
                        res(response.data)

                    } else {
                        logger.error('response from Facebook sharedposts failed', response)
                        rej(response.error)
                    }
                }
            );
        })
    }


    static async getUserPosts(pageId: string): Promise<FBResponseType<FBPostType>> {
        return await new Promise((res, rej) => {
            window.FB.api(
                `/${pageId}/posts`,
                function (response: FBResponseType<FBPostType>) {
                    logger.debug('response from Facebook posts', response)
                    if (response && !response.error) {
                        res(response)
                    } else {
                        logger.error('response from Facebook posts failed', response)
                        rej(response.error)
                    }
                }
            );
        })
    }
}