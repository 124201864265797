import * as React from "react";
import {ButtonsBlock} from "../../../common-styles/Common.styles";
import {useSelector} from "react-redux";
import {setOverlays} from "../../../redux/overlays-reducer";
import {OverlayButton} from "./OverlayButton";

export const OverlayButtonsBlock = () => {
    const meaningOverlays = useSelector(setOverlays)
    const overlayButtons = meaningOverlays.map((o, i) => <OverlayButton key={i} overlay={o}/>)

    return (
        <ButtonsBlock>
            {overlayButtons}
        </ButtonsBlock>
    )
}
