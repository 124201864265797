import styled from "styled-components";

export const Wrap = styled.div<{ modal: boolean }>`

  opacity: ${props => props.modal && 0.6};
  z-index: ${props => props.modal && 0};

  header {
    //position: absolute;
    top: 54px;
    right: 24px;
    z-index: 9999;

    h1 {
      padding-right: 24px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      cursor: pointer;

      span {
        font-weight: 600
      }

      a:active,
      a:hover,
      a {
        text-decoration: none;
        color: #000;
      }

      &:hover {
        font-size: 22px;
      }
    }
  }
`