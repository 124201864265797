import {ParticipantStatusType, ParticipantsVKType, VKParticipantsType} from '../api/api-types'
import {
    DataForVkRequestReposts,
    ErrorMapType,
    MapParticipantData,
    MappedParticipantData,
    VkAPIPostData,
    VkAPIPostDeleted
} from '../api/social-network-api/types/vk-types'
import {PARTICIPANT_STATUS} from '../constants'
import {logger} from "../logger";
import {isPostDeleted} from "./isPostDeleted";

export class VkUtils {

    POSTS_IDS_MAX_COUNT_FOR_SINGLE_REQUEST = 100

    /* функция подготавливает данные полученные с монго ДБ для запросов в АПи ВК */
    async extractPostsIdsFromLinksAndPrepareData(participants: Array<VKParticipantsType>) {
        //const postIds: string[] = [] // '1111_300'
        const mappedVKUser: MapParticipantData = {}
        for (const participant of participants) {
            // check the link for case when user send link to not wall post, but directly to video
            if (/* turn off this feature */ false && participant.socialNetworkLink.indexOf('video') !== -1) {
                // await this.checkDirectlyVideoLink(participant, mappedVKUser)
                continue
            }

            //  https://vk.com/leonid_shuliak?w=wall50532117_301
            const anchor = participant.socialNetworkLink.indexOf('wall')
            const postId = participant.socialNetworkLink.slice(anchor + 4)
            const userId = postId.split('_')[0]
            mappedVKUser[userId] = {...participant, postId: null}
            if (userId && anchor !== -1) {
                mappedVKUser[userId].postId = postId
            } else {
                mappedVKUser[userId].dataFromVK =
                    this.createParticipantStatusObj(mappedVKUser,
                        userId,
                        PARTICIPANT_STATUS.BAD_LINK,
                        'Invalid post link')
            }
        }
        return mappedVKUser
    }

    /*  async checkDirectlyVideoLink(participant: VKParticipantsType, mappedVKUser: MapParticipantData) {
          // todo refactor 12.07.22
          // validate this type of link https://vk.com/leonid_shuliak?z=video50532117_456239034%2F0520f139b208e65282%2Fpl_wall_50532117
          const videoId = participant.socialNetworkLink.split('%')[0].split('video')[1].split('_')[1]
          const userId = participant.socialNetworkLink.split('%')[0].split('video')[1].split('_')[0]
          const postsFromWall = await vkAPI.getPostsFromWall(userId)
          const postIdFromWall = await this.getPostIdByVideoId(userId, videoId, postsFromWall)
          mappedVKUser[userId] = {...participant, postId: null}
          if (postIdFromWall) {
              mappedVKUser[userId].postId = `${userId}_${postIdFromWall}`

          } else {
              mappedVKUser[userId].dataFromVK =
                  createParticipantStatusObj(mappedVKUser,
                      userId,
                      PARTICIPANT_STATUS.BAD_LINK,
                      'Invalid post link')
          }
      }
  */
    getPostIdByVideoId(
        userId: string,
        videoId: string,
        postsFromWall: VkAPIPostData[]
    ): string | null {
        let post: { id: number } | undefined

        post = postsFromWall.find((p: any) => {
            if (p.attachments !== undefined && p.attachments.length !== 0) {
                return p.attachments[0].video?.id === +videoId
            }
        })

        if (post) {
            return post.id.toString()
        }
        return null
    }

    /*
   функция проверяет содержит ли пост ссылку на внешний ресурс и если да, формирует данные для запроса информации
   по внешнему ресурсу

    @param data - информация которую вернула АПИ ВК по посту
     */
    prepareVideoRequestData(
        data: (VkAPIPostDeleted | VkAPIPostData)[],
        mappedVKUser: MapParticipantData,
        userErrorMap: ErrorMapType,
    ) {
        const result: string[] = []
        for (let post of data) {

            if (isPostDeleted(post)) {
                mappedVKUser[post.owner_id.toString()].dataFromVK = this.createParticipantStatusObj(
                    mappedVKUser,
                    post.owner_id.toString(),
                    PARTICIPANT_STATUS.BAD_LINK,
                    'The post was deleted')
                continue
            }
            if (!post.attachments || post.attachments?.length === 0 || post.attachments?.length > 1) {
                userErrorMap[post.owner_id] = true
                mappedVKUser[post.owner_id.toString()].dataFromVK = this.createParticipantStatusObj(
                    mappedVKUser,
                    post.owner_id.toString(),
                    PARTICIPANT_STATUS.BAD_LINK,
                    'There is no attachment in the post or post contains to many attachments',
                )
            } else {
                // предполагаем that user should post single video without other attachments
                const attachment = post.attachments[0]
                if (attachment.type !== 'video') {
                    userErrorMap[post.owner_id] = true
                    mappedVKUser[post.owner_id.toString()].dataFromVK = this.createParticipantStatusObj(
                        mappedVKUser,
                        post.owner_id.toString(),
                        PARTICIPANT_STATUS.BAD_LINK,
                        'The post does not contain stream link',
                    )
                } else {
                    result.push(`${attachment.video.owner_id}_${attachment.video.id}`)
                }
            }
        }
        logger.info('prepareVideoRequestData ID__Video_ID', result)
        return result
    }

    /* Фунция которая создает объект проверки поста */
    createParticipantStatusObj(
        mappedData: MapParticipantData,
        vkUserId: string,
        status: PARTICIPANT_STATUS,
        errorText: string,
    ): ParticipantStatusType {
        return {
            participantId: mappedData[vkUserId]._id,
            quizId: mappedData[vkUserId].quizId,
            newStatus: status,
            attemptData: {
                type: 'vkAPI',
                originalPostLink: mappedData[vkUserId].socialNetworkLink,
                error: errorText,
                callstack: '',
            },
        }
    }

    getStreamIdFromYoutubeLink(link: string) {
        const paramIndex = link.indexOf('?')
        const paramsString = link.slice(paramIndex + 1)
        const params = paramsString.split('&')
        let result = ''
        params.forEach(el => {
            const parts = el.split('=')
            if (parts[0] === 'v') {
                result = parts[1]
            }
        })
        return result
    }

    /*
    *Фунция обрабатывает юзеров и формируем массив для быза данных.
    *Если у юзера неду данных о ВК по какой_либо причине, то автоматически присваевается
    *сстатус PARTICIPANT_STATUS.BAD_LINK

    *@param participantsPostsCheckingResult - the result of users who are sent to the database
    *@param mappedVKUser - all users participating in the quiz
    */
    prepareResult(mappedVKUser: MapParticipantData, participantsPostsCheckingResult: ParticipantStatusType[]): void {
        for (const user in mappedVKUser) {
            if (mappedVKUser[user].dataFromVK) {
                participantsPostsCheckingResult.push(mappedVKUser[user].dataFromVK!)
            } else {
                participantsPostsCheckingResult.push(mappedVKUser[user].dataFromVK = this.createParticipantStatusObj(
                    mappedVKUser,
                    user,
                    PARTICIPANT_STATUS.BAD_LINK,
                    'No response from Vk API',
                ))
            }
        }
    }

    /* Prepare user in array to associated object */
    prepareUsersForRequestViaVk(participants: ParticipantsVKType[]): MappedParticipantData {
        const mappedData: MappedParticipantData = {}
        for (const participant of participants) {
            mappedData[participant.vkId] = participant
        }
        return mappedData
    }

   getDataToRequestForReposts(youtubeLink: string): DataForVkRequestReposts{
        const VERSION_VK_API = '5.131'
        const ANCHOR_IN_LINK = 'wall'

        const anchor = youtubeLink.indexOf(ANCHOR_IN_LINK)
        const userId_postId = youtubeLink.slice(anchor + ANCHOR_IN_LINK.length)
        const userId = userId_postId.split('_')[0]
        const postId = userId_postId.split('_')[1]
        return {
            owner_id: userId,
            post_id: postId,
            offset: '0',
            count: '1000',
            v: VERSION_VK_API
        }
    }
    // todo We need check if admin send wrong link

    prepareResultForServer(usersReposted:MappedParticipantData){
       return Object.values(usersReposted)
    }
    checkingParticipantsOnRepost (userReposted: MappedParticipantData){
        for (const user in userReposted) {
            if (!userReposted[user].hasOwnProperty('newStatus')) {
                userReposted[user].newStatus = PARTICIPANT_STATUS.BAD_LINK
                userReposted[user].attemptData = {
                    type: 'vkAPI',
                    originalPostLink: '',
                    error: `Don't find repost`,
                    callstack: '',
                };
            }
        }
    }
}

