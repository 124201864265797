import {OVERLAY_TYPES, OverlayType, QuestionType, ROUTS_SOCKET_IO} from "../../../api/api-types";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDelayActivated, getQuizId} from "../../../redux/settings-reducer";
import {
    setLastStartedQuestionId,
} from "../../../redux/questions-reducer";
import {QUESTION_IMAGE, QUESTION_TEXT, QUESTION_VOICE} from "../../questions/modal";
import {socketIoApi} from "../../../api/api";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import {useEffect} from "react";
import {QUESTION_STATUS} from "../../../constants";
import {OVERLAY_BUTTON_DESCRIPTION, OVERLAY_BUTTON_LABEL} from "../../../types/overlay-types";
import {
    getCurrentStartedQuestion,
    getFilteredQuestionsByStatus,
    getLastClosedQuestion
} from "../../../redux/selectors/questionSelectors";

type OverlayButtonType = {
    overlay: OverlayType
}

export const OverlayButton: React.FC<OverlayButtonType> = ({overlay}) => {
    const dispatch = useDispatch()
    const quizId = useSelector(getQuizId)
    //отфильтрованные вопросы для проверки на старт первого вопроса c целью раздизейбла кнопок
    const startedQuestions = useSelector(getFilteredQuestionsByStatus)
    const isActiveQuestion = useSelector(getCurrentStartedQuestion)
    const lastClosedQuestion = useSelector(getLastClosedQuestion)
    const delayActivated = useSelector(getDelayActivated)
    const currentQuestion = isActiveQuestion ? isActiveQuestion : lastClosedQuestion
    const disable = startedQuestions.length ? disableButtonByOverlayType(overlay, currentQuestion) : true


    function disableButtonByOverlayType(overlay: OverlayType, question: QuestionType | undefined) {
        switch (overlay.type) {
            case OVERLAY_TYPES.TOP10:
                return false
            case OVERLAY_TYPES.TOP20_POINTS:
                return false
            case OVERLAY_TYPES.CORRECT_ANSWERS:
                return lastClosedQuestion?.status !== QUESTION_STATUS.CLOSED
            case OVERLAY_TYPES.QUESTION_STATISTICS:
                return false;

            case OVERLAY_TYPES.IMAGE:
                return question?.type !== QUESTION_IMAGE

            case OVERLAY_TYPES.TEXT:
                return question?.type === QUESTION_VOICE ? false : question?.type === QUESTION_TEXT ? false : true
            default:
                return !overlay.active
        }
    }

    const overlayHandler = (quizId: string, type: OVERLAY_TYPES, active: boolean) => {
        if (disable || delayActivated) return
        socketIoApi.socket?.emit(ROUTS_SOCKET_IO.CLIENT_OVERLAY_EVENT, {quizId, type, active})
    }

    useEffect(() => {
        if (isActiveQuestion) dispatch(setLastStartedQuestionId(isActiveQuestion.id))
    }, [isActiveQuestion])

    return (
        <div className={overlay.active ? 'quizCheckbox activeButton' : 'quizCheckbox'}

             onClick={() => overlayHandler(quizId, overlay.type, !overlay.active)}
        >
            <div className={'formGroup'}>
                <FormGroup>
                    <FormControlLabel
                        label={OVERLAY_BUTTON_LABEL[overlay.type]}
                        control={
                            <Checkbox disabled={disable || delayActivated}
                                      checked={overlay.active}
                            />
                        }
                    />
                    <p className={'formText'}>{OVERLAY_BUTTON_DESCRIPTION[overlay.type]}</p>
                </FormGroup>
            </div>
        </div>
    )
}
