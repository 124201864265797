import React from 'react';

import SpriteIcons from '../../../../../assets/svg/sprite-icons.svg';

import { StyledIcon } from './styles';

type IconPropsType = {
  iconId: string;
  width?: string;
  height?: string;
  viewBox?: string;
};

export const Icon = ({ iconId, width, height, viewBox }: IconPropsType) => (
  <StyledIcon
    width={width || '24px'}
    height={height || '24px'}
    viewBox={viewBox || '0 0 24 24'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use xlinkHref={`${SpriteIcons}#${iconId}`} />
  </StyledIcon>
);
