import {MappedParticipantData, PARTICIPANT_STATUS, VKRepost} from "../api/social-network-api/types/vk-types";

export const fillRepostedParticipants = (response: VKRepost, userReposted: MappedParticipantData): void => {
    const repostsFromVk = response?.items
    for (const index in repostsFromVk) {
        const vkRepost = repostsFromVk[index]
        const vkId = vkRepost.to_id.toString()
        if (userReposted[vkId]) {
            userReposted[vkId].newStatus = PARTICIPANT_STATUS.APPROVED;
            userReposted[vkId].attemptData = {
                type: 'vkAPI',
                originalPostLink: repostsFromVk[index].id.toString(),// id post
                error: '',
                callstack: '',
            };
        }
    }
}

export const rememberLoggedUser = (expireTime: number): void => {
    sessionStorage.setItem('expireTime-loggedUser-vk', JSON.stringify( expireTime ))
}