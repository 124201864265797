export const getTabnailLink =  (youtubeStreamLink: string) => {
  const match = youtubeStreamLink.match(/v=([^&]+)/);

  if(match !== null){
  return`https://img.youtube.com/vi/${match[1]}/mqdefault.jpg `
  }
  const matchSecondPattern = youtubeStreamLink.match(/\/([^\/]+)$/)
  if(matchSecondPattern){
    return`https://img.youtube.com/vi/${matchSecondPattern[1]}/mqdefault.jpg`
  }
  return null
}