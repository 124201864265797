import React, {FC} from "react";
import {IconWrapper} from "./styles";
import {Icon} from "./styles/icon/Icon";

type SocialNetworkIconType = {
    iconId: string
    text: string
}

export const SocialNetworkIcon: FC<SocialNetworkIconType> = (props) => {
    return (

        <>
            <IconWrapper>
                <Icon iconId={props.iconId} width="26" height="26" viewBox="0 0 26 26"/>
            </IconWrapper>
            <span>{props.text}</span>
        </>

    )
}