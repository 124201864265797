import React, {useEffect} from 'react'
import logo from "./../../assets/svg/logo.svg";
import { Wrap } from './styles/Header.styles';

type HeaderPropsType = {
    modal: boolean
    overlayFrontActive: boolean
}

export const Header: React.FC<HeaderPropsType> = (props) => {

    useEffect(() => {
        if (props.modal) {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = '15px';
        }
        return () => {
            document.body.style.overflow = 'unset';
            document.body.style.paddingRight = '0px';
        };
    }, [props.modal]);

    return (
        <>
            {
                !props.overlayFrontActive &&
                <Wrap modal={props.modal}>
                    <header>
                        <img src={logo} className="logo" alt="logo"/>
                    </header>
                </Wrap>
            }
        </>

    )
}