import * as React from 'react';
import {StyledTooltip} from './BasicTooltip_Styles';


type BasicTooltipPropsType = {
    children: React.ReactElement,
    title: string,
    placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const BasicTooltip = (props: BasicTooltipPropsType) => {
    return <StyledTooltip title={props.title} placement={props.placement}>
        {props.children}
    </StyledTooltip>
}