export type ClearFieldsModalType = 'set_data' | 'clear_speaker_fields' | 'clear_event_fields'

export type QuestionErrorType = {
    notes?: string
    questionType?: string
    maxAttemptsNumber?: string
    answers?: string
    questionDescription?: string
    image?: string
}

export type QuestionModalPropsType = {
    questionId: string
    modal?: boolean
    setModal: (modal: boolean) => void
}

export enum FORM_DATA_KEY {
    DATA = 'data',
    FILE = 'file'
}

export enum QUESTION_TYPES {
    'Изображение' = 'image',
    'Текст' = 'text',
    'Голосовое' = 'voice',
}

export enum QUESTION_FRONT_TYPES {
    'image' = 'Изображение',
    'text' = 'Текст',
    'voice' = 'Голосовое'
}

export const QUESTION_IMAGE = 'image'
export const QUESTION_TEXT = 'text'
export const QUESTION_VOICE = 'voice'


export type TimeZonePropsType = {
    tz: any
    timeZoneColor: boolean
    setTimeZoneColor: (value: boolean) => void
    setTz: (tz: any) => void
}