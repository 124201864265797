import {useDispatch, useSelector} from "react-redux";
import {
    getRegistrationInfoByVkParticipants, verifyVKParticipants
} from "../../../redux/verify-participant-reducer";
import {setAlertMessage, getQuizId, getRepostedLink} from "../../../redux/settings-reducer";
import {VK_NOTIFICATIONS} from "../../../constants";
import {PageTitle} from "../../../common-components/PageTitle/PageTitle";
import {SocialNetworkButtonsBlock} from "../socialNetworkLogin/styles";
import {Button, VerifyParticipantsBlock} from "../../../common-styles/Common.styles";
import clockIcon from "../../../assets/svg/verificateParticipants/clock.svg";
import checkmarkIcon from "../../../assets/svg/verificateParticipants/checkmark.svg";
import {BasicTooltip} from "../../../common-components/Tooltip/BasicTooltip/BasicTooltip";
import infoIcon from "../../../assets/svg/info-icon.svg";
import alertIcon from "../../../assets/svg/verificateParticipants/alert.svg";
import errorCrossIcon from "../../../assets/svg/verificateParticipants/error-cross.svg";
import React, {useEffect, useState} from "react";
import {UserVK} from "../socialNetworkLogin/types";
import {vkAPI} from "../../../api/social-network-api/vkAPI";
import {PageTitleWrapper} from "../styles";
import {LoginVK} from "../socialNetworkLogin/LoginVK";
import {PostLink} from "./index.style";
import {Modal} from "../../../common-components/Modal";
import {ValidateInput, validationModalText} from "../validateInput/ValidateInput";


type VkParticipantsType = {
    isScriptLoaded: boolean
}

export const VkParticipants: React.FC<VkParticipantsType> = (props) => {
    const dispatch = useDispatch()
    //For modal
    const [isOpen, setOpen] = useState(false);
    const quizId = useSelector(getQuizId)
    const repostedLink = useSelector(getRepostedLink)

    const {
        pendingParticipants,
        approvedParticipants,
        blockedParticipants,
        notApprovedParticipants
    } = useSelector(getRegistrationInfoByVkParticipants)


    const [isVKAuthorise, setIsVKAuthorise] = useState(false)
    const [userVk, setUserVk] = useState<UserVK>();

    const checkParticipantsVK = () => {
        dispatch(verifyVKParticipants(quizId))
    }


    const vkAuthorise = async () => {
        const isAuthorized = await vkAPI.vkAuthorize()
        if (isAuthorized) {
            setIsVKAuthorise(isAuthorized)
        } else {
            dispatch(setAlertMessage(VK_NOTIFICATIONS.NO_AUTHORIZED_VK))
        }
    }

    async function checkVKToken(): Promise<void | undefined> {
        const unix_timestamp = sessionStorage.getItem('expireTime-loggedUser-vk')

        if (unix_timestamp) {
            const date = new Date(+unix_timestamp * 1000)

            if (Date.now() > date.getTime()) {
                setIsVKAuthorise(false)
                return
            }

            props.isScriptLoaded && await vkAPI.initVk()
            setIsVKAuthorise(true)
        }
    }

    useEffect(() => {
        const user = sessionStorage.getItem('userVK')
        if (user) setUserVk(JSON.parse(user)[0])
    }, [isVKAuthorise])

    useEffect(() => {
        checkVKToken()
    }, [props.isScriptLoaded])

    return (
        <>
            <PageTitleWrapper>
                <PageTitle
                    text={'Проверка участников Вконтакте'}/>
                {
                    userVk && <Button className={'button primary'}
                                      variant="extended"
                                      type={'button'}
                                      aria-label="add"
                                      onClick={checkParticipantsVK}>Проверить
                    </Button>
                }
            </PageTitleWrapper>
            <SocialNetworkButtonsBlock>
                <LoginVK vkAuthorise={vkAuthorise} isVKAuthorise={isVKAuthorise} user={userVk}/>
                <Button className={'button blue'}
                        variant="extended"
                        type={'button'}
                        aria-label="add"
                        onClick={() => setOpen(true)}>
                    Ручная валидация
                </Button>
                {/*<LoginFB/>*/}
            </SocialNetworkButtonsBlock>
            <PostLink>
                {repostedLink?.vk && <a href={repostedLink.vk} target="_blank">Ссылка на пост для репоста</a>}
            </PostLink>

            <VerifyParticipantsBlock>
                <div className={'CheckWrap'}>
                    <div className={'Box'}>
                        <img src={clockIcon} alt=""/>
                        <h4>Ожидают проверку</h4>
                        <span>{pendingParticipants} человек</span>
                    </div>

                    <div className={'Box'}>
                        <img src={checkmarkIcon} alt=""/>
                        <div>
                            <h4>Прошли проверку</h4>
                            <BasicTooltip placement={'bottom-start'}
                                          title={"Успешно зарегистрированных пользователей, которые могут участвовать в викторине!"}>
                                <img src={infoIcon} alt=""/>
                            </BasicTooltip>
                        </div>
                        <span>{approvedParticipants} человек</span>
                    </div>

                    <div className={'Box'}>
                        <img src={alertIcon} alt=""/>
                        <div>
                            <h4>Не выполнили условия</h4>
                            <BasicTooltip placement={'bottom-start'}
                                          title={"Пользователи, которые не выполнили условия регистрации, но имеют возможность зарегистрироваться еще раз!"}>
                                <img src={infoIcon} alt=""/>
                            </BasicTooltip>
                        </div>
                        <span>{notApprovedParticipants} человек</span>
                    </div>

                    <div className={'Box'}>
                        <img src={errorCrossIcon} alt=""/>
                        <div>
                            <h4>Заблокированы</h4>
                            <BasicTooltip placement={'bottom-end'}
                                          title={"Пользователи, которые не выполнили условия регистрации более 10 раз и больше не имеют возможности зарегистрироваться на викторину!"}>
                                <img src={infoIcon} alt=""/>
                            </BasicTooltip>
                        </div>
                        <span>{blockedParticipants} человек</span>
                    </div>
                </div>
                {
                    isOpen &&
                    <Modal
                        quizId={quizId} title={'Ручная валидация'}
                        setOpenModal={() => setOpen(state => !state)}
                        text={validationModalText}>
                        <ValidateInput setOpen={setOpen}/>
                    </Modal>
                }
            </VerifyParticipantsBlock>
        </>
    );
}

