import {ParticipantStatusType, ParticipantsVKType, VKParticipantsType} from "../../api-types";

export interface CheckedUserVK {
    id: number,
    first_name: string,
    last_name: string,
    can_access_closed: boolean,
    is_closed: boolean
}

export interface ResponseVKType<T> {
    response: T
}

export interface VideosVk {
    count: number
    items: VideoVK[]
}

export type VKResponse = {
    session : {
        mid : number,
        sid : string ,
        sig : string  ,
        secret : string ,
        expire : number,
        user : {
            id :  string ,
            domain : string ,
            href : string ,
            first_name : string,
            last_name : string,
            nickname : string,  
       },
   },
    status : string
}

export interface VideoVK {
    can_comment: number,
    can_like: number,
    can_repost: number,
    can_subscribe: number,
    can_add_to_faves: number,
    can_add: number,
    comments: number,
    date: number,
    description: string,
    duration: number,
    image: {
        url: string,
        width: number,
        height: number,
        with_padding: number
    }[],
    id: number,
    owner_id: number,
    title: string,
    is_favorite: boolean,
    player: string,
    added: number,
    type: string,
    views: number,
    local_views: number,
    platform: string,
    likes: { count: number, user_likes: number },
    reposts: { count: number, user_reposted: number }
}


export interface VkAPIPost {
    id: number,
    from_id: number,
    owner_id: number,
    date: number,
    is_favorite: boolean,
    text: string,
}

export interface VkAPIPostDeleted extends VkAPIPost {
    is_deleted: boolean,
    deleted_reason: string,
    deleted_details: string
}

export interface VkAPIPostData extends VkAPIPost, InformationAboutMarks {
    post_type: string,
    attachments:
        {
            type: string,
            video: {
                access_key: string,
                can_comment: number,
                can_like: number,
                can_repost: number,
                can_subscribe: number,
                can_add_to_faves: number,
                can_add: number,
                comments: number,
                date: number,
                description: string,
                duration: number,
                image: {
                    url: string,
                    width: number,
                    height: number,
                    with_padding: number
                } [],
                id: number,
                owner_id: number,
                title: string,
                is_favorite: false,
                track_code: string,
                type: string,
                views: number,
                local_views: number,
                platform: string
            }
        }[]
}

export type MapParticipantData = {
    [key: string]: VKParticipantsType
        & {
        dataFromVK?: ParticipantStatusType,
        postId: string | null
    }
}

export interface Repost {
    _id: string
    addedDate: number
    repostDate: number
    vkUserId: number
    quizId: string
    repostedPostId: number
    iterationId: string
    status: string // some status
}

export interface MappedParticipantData {
    [key: string]: ParticipantsVKType
}

export type PostAttachmentType = {
    type: string;
    video: any;
};

export type VKAPIPostResponseType = {
    id: number;
    owner_id: number;
    attachments: PostAttachmentType[];
}

export type ErrorMapType = {
    [key: string]: boolean,
};

export type VideoDataResponseType = {
    player: string;
    owner_id: number;
};

export interface VKRepost {
    items: UserReposted [],
    profiles: ProfileVk[],
    groups: []
}

export interface UserReposted extends VkAPIPost, InformationAboutMarks {
    to_id: number,
    post_type: string,
    copy_history: VkAPIPostData["attachments"]
}

interface InformationAboutMarks {
    comments: {
        count: number
    },
    likes: {
        can_like: number,
        count: number,
        user_likes: number
    },
    reposts: {
        count: number
    },
    views: {
        count: number
    },
    donut: {
        is_donut: boolean
    },
    short_text_rate: number,
    hash: string
}

interface ProfileVk {
    id: number,
    sex: number,
    screen_name: string,
    photo_50: string,
    photo_100: string,
    online_info: {
        visible: boolean,
        last_seen: number,
        is_online: boolean,
        is_mobile: boolean
    },
    online: number,
    first_name: string,
    last_name: string,
    can_access_closed: boolean,
    is_closed: boolean
}

export const enum PARTICIPANT_STATUS {
    NULL = 'null',
    APPROVED = 'approved',
    PENDING = 'pending',
    BAD_LINK = 'bad-link',
    PRIVATE_ACCT = 'private_network',
}

export interface DataForVkRequestReposts {
    owner_id: string,
    post_id: string,
    offset: string,
    count: string,
    v: string,
}