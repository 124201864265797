import {AppStateType} from "../redux-store";
import {QuestionType} from "../../api/api-types";
import {QUESTION_STATUS} from "../../constants";

export const getQuestions = (state: AppStateType): QuestionType[] => state.questions.questions
export const getScrolling = (state: AppStateType): boolean => state.questions.scrolling

export const getCurrentStartedQuestion = ((state: AppStateType): QuestionType | undefined =>
    state.questions.questions.find(q => q.status === QUESTION_STATUS.STARTED))

export const getLastClosedQuestion = ((state: AppStateType): QuestionType | undefined =>
    state.questions.questions.find(q => q.id === state.questions.lastStartedQuestionId))

export const getFilteredQuestionsByStatus = ((state: AppStateType): QuestionType[] =>
    state.questions.questions.filter(q => q.status === QUESTION_STATUS.STARTED || q.status === QUESTION_STATUS.CLOSED))