enum LoggerLevels {
  none = -1,
  error = 0,
  warning = 1,
  info = 2,
  debug = 3
}

class Logger {
  private readonly loggerLevel: LoggerLevels;

  constructor(loggerLevel: LoggerLevels = LoggerLevels.none) {
    this.loggerLevel = loggerLevel;
  }

  error(subject: string, message: unknown) {
    if (this.loggerLevel >= LoggerLevels.error) {
      console.groupCollapsed(`%c Error message ${subject}` , 'color:red; font-size: small');
      console.error(subject);
      console.table(message);
      console.groupEnd();
    }
  }

  warn(subject: string, message: unknown) {
    if (this.loggerLevel >= LoggerLevels.warning) {
      console.groupCollapsed(`%c Warn message ${subject} `, 'color:orange; font-size: small');
      console.warn(subject);
      console.table(message);
      console.groupEnd();
    }
  }

  info(subject: string, message: unknown) {
    if (this.loggerLevel >= LoggerLevels.info) {
      console.groupCollapsed(`%c Info message  ${subject}`, 'color:green; font-size: small');
      console.log(subject);
      console.table(message);
      console.groupEnd();
    }
  }

  debug(subject: string, message: unknown) {
    if (this.loggerLevel >= LoggerLevels.debug) {
      console.groupCollapsed(`%c Debug message ${subject}`, 'color:blue; font-size: medium');
      console.log(subject);
      console.table(message);
      console.groupEnd();
    }
  }
}
const typeOfLoggerLevel = process.env.REACT_APP_LOGLEVEL as keyof typeof LoggerLevels

export const logger = new Logger(LoggerLevels[typeOfLoggerLevel  || LoggerLevels.debug]);
