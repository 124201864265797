import {
    DataOverlaysType,
    OverlayType,
    QuestionStatisticType,
    Top15ParticipantType,
} from "../api/api-types";
import {Dispatch} from "redux";
import {logger} from "../logger";
import {AppStateType} from "./redux-store";
import {overlaysAPI} from "../api/api";

const SET_OVERLAY_ACTIVE_FRONT = 'overlays/SET-OVERLAY-ACTIVE_FRONT';
const SET_MEANING_OVERLAYS = 'overlays/SET-MEANING-OVERLAYS';
const SET_OVERLAYS_DATA = 'overlays/SET-OVERLAYS-DATA';
const SET_TOP15_PARTICIPANTS = 'overlays/SET-TOP-10-PARTICIPANTS';
const SET_STATISTIC = 'overlays/SET_STATISTIC';

type ActionsTypes =
    | ReturnType<typeof activateOverlayFront>
    | ReturnType<typeof setMeaningOverlays>
    | ReturnType<typeof setOverlaysData>
    | ReturnType<typeof setTopParticipants>
    | ReturnType<typeof setTopParticipants>
    | ReturnType<typeof setStatistic>


export type InitialStateOverlaysType = {
    overlayFrontActive: boolean
    meaningOverlays: OverlayType[]
    image: null | string
    text: null | string
    top15Participants: null | Top15ParticipantType[]
    questionStatistic: null | QuestionStatisticType
    correctAnswers: string[] | null
    errors: null | string[]
}

const initialState: InitialStateOverlaysType = {
    overlayFrontActive: false,
    meaningOverlays: [],
    image: null,
    text: null,
    top15Participants: null,
    questionStatistic: null,
    correctAnswers: null,
    errors: null
};

export const overlaysReducer = (state = initialState, action: ActionsTypes): InitialStateOverlaysType => {
    switch (action.type) {

        case SET_MEANING_OVERLAYS: {
            return {
                ...state,
                meaningOverlays: action.meaningOverlays
            }
        }

        case SET_OVERLAY_ACTIVE_FRONT: {
            return {
                ...state,
                overlayFrontActive: true
            }
        }

        case SET_OVERLAYS_DATA: {
            return {
                ...state,
                ...action.data,
            }
        }

        case SET_TOP15_PARTICIPANTS: {
            return {
                ...state,
                top15Participants: action.data
            }
        }

        case SET_STATISTIC: {
            return {
                ...state,
                questionStatistic: action.data
            }
        }

        default :
            return state;
    }
}


export const setMeaningOverlays = (meaningOverlays: OverlayType[]) => ({
    type: SET_MEANING_OVERLAYS,
    meaningOverlays
} as const)

export const activateOverlayFront = () => ({
    type: SET_OVERLAY_ACTIVE_FRONT
} as const)

export const setTopParticipants = (data: Top15ParticipantType[] | null) => ({
    type: SET_TOP15_PARTICIPANTS,
    data
} as const)

export const setOverlaysData = (data: DataOverlaysType) => ({
    type: SET_OVERLAYS_DATA,
    data
} as const)

export const setStatistic = (data: QuestionStatisticType) => ({
    type: SET_STATISTIC,
    data
} as const)


export const getMeaningOverlays = (quizId: string) => async (dispatch: Dispatch) => {
    try {
        const meaningOverlays = await overlaysAPI.getMeaningOverlays(quizId)
        dispatch(setMeaningOverlays(meaningOverlays))
    } catch (error) {
        logger.error('getMeaningOverlays', error)
    }
}

export const getOverlays = (quizId: string) => async (dispatch: Dispatch) => {
    try {
        const overlays = await overlaysAPI.getOverlays(quizId)
        dispatch(setOverlaysData(overlays))
    } catch (error) {
        logger.error('getMeaningOverlays', error)
    }
}

export const setOverlayFrontActive = (state: AppStateType): boolean => state.overlays.overlayFrontActive
export const setOverlays = (state: AppStateType): OverlayType[] => state.overlays.meaningOverlays
