import { StyledTextarea } from "./TextField_Styles";


type TextareaPropsType = {
    id?: string,
    error?: boolean,
    placeholder?: string,
    width?: number | string,
    maxWidth?: number | string,
    rows?: number
}

export const BasicTextField = (props:TextareaPropsType) => {

    return (
        <StyledTextarea multiline
                        rows={props.rows}
                        placeholder={props.placeholder}
                        error={props.error}
                        sx={{ width: props.width, maxWidth: props.maxWidth}} {...props}
        />
    );
}