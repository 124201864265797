import {setProgress} from "./app-reducer";
import {Dispatch} from "redux";
import {quizAPI} from "../api/api";
import {logger} from "../logger";
import {AppStateType} from "./redux-store";
import {QUIZ_STATUS} from "../constants";
import {Statuses} from "../api/api-types";

const INCREMENT_CURRENT_QUESTION = 'SET-CURRENT-QUESTION'
const SET_EVENT_VOTING = 'SET-EVENT-VOTING';
const SET_CURRENT_NUMBER_VOTE_QUESTION = 'SET-CURRENT-NUMBER-VOTE-QUESTION';


type ActionsTypes =
    | ReturnType<typeof IncrementQuestionNumber>
    | ReturnType<typeof setProgress>
    | ReturnType<typeof setEventVoting>
    | ReturnType<typeof setCurrentNumberVoteQuestion>

export type initialStateTypeVoteConference = {
    currentQuestionNumber: number
    status: QUIZ_STATUS
}

const initialState: initialStateTypeVoteConference = {
    currentQuestionNumber: 1,
    status: QUIZ_STATUS.pending
};


export const quizReducer = (state = initialState, action: ActionsTypes): initialStateTypeVoteConference => {
    switch (action.type) {

        case INCREMENT_CURRENT_QUESTION: {
            return {
                ...state,
                currentQuestionNumber: state.currentQuestionNumber + 1
            }

        }

        case SET_CURRENT_NUMBER_VOTE_QUESTION: {
            return {
                ...state,
                currentQuestionNumber: action.num
            }

        }

        case SET_EVENT_VOTING: {
            return {
                ...state,
                status: action.status
            }
        }

        default :
            return state;
    }
}


export const setCurrentNumberVoteQuestion = (num: number) => ({
    type: SET_CURRENT_NUMBER_VOTE_QUESTION,
    num
} as const)

export const IncrementQuestionNumber = () => ({
    type: INCREMENT_CURRENT_QUESTION,
} as const)


export const setEventVoting = (status: QUIZ_STATUS) => ({
    type: SET_EVENT_VOTING,
    status
} as const)


export const openVoteQuiz = () => async (dispatch: Dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await quizAPI.openVoteQuiz()
        if (response.resultCode === Statuses.Success) {
            dispatch(setEventVoting(QUIZ_STATUS.started))
        } else {
            console.dir(response.messages[0])
        }
    } catch (error) {
        console.dir(error)
    } finally {
        dispatch(setProgress(false))
    }
}

export const closeVoteQuiz = () => async (dispatch: Dispatch) => {
    dispatch(setProgress(true))
    try {
        const response = await quizAPI.closeVoteQuiz()
        if (response.resultCode === Statuses.Success) {
            dispatch(setEventVoting(QUIZ_STATUS.closed))
        } else {
            logger.error('Bad response closeVoteQuiz', response.messages[0])
        }
    } catch (error) {
        logger.error('closeVoteQuiz', error)
    } finally {
        dispatch(setProgress(false))
    }
}

export const getQuizStatus = (state: AppStateType): QUIZ_STATUS => state.quiz.status

