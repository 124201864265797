import styled from "styled-components";

export const WrapButton = styled.div`
  max-width: 440px;
  width: 100%;
  height: 36px;
  
  .css-sghohy-MuiButtonBase-root-MuiButton-root,
  .buttonModalStyle {
    color: #2D8CE4;
    border: 1px solid #2D8CE4;
    border-radius: 2px;
    background: #FFFFFF;
    width: 100%;
    height: 36px;
    box-shadow: none;

    :hover {
      background: #FFFFFF;
    }
  }
`