import React from "react";
import {Button} from "../../../common-styles/Common.styles";
import {AuthBtnWrapper} from "./styles";
import {SocialNetworkIcon} from "./SocialNetworkIcon";
import {Account} from "../../../common-components/Account/Account";
import {LoginVKType} from "./types";

export const LoginVK: React.FC<LoginVKType> = ({isVKAuthorise, vkAuthorise, user}) => {

  return (
    <>
      {
        isVKAuthorise
          ?
          <>
            {user && <Account accountName={'Вконтакте'} fullName={`${user.first_name} ${user.last_name}`}
                              photo={user.photo_50}/>}
            {/*<div className={'check-participants-button'}>*/}
            {/*</div>*/}
          </>
          :
          <AuthBtnWrapper backgroundColor={'#5181B8'}>
            <Button className={'button primary'}
                    variant="extended"
                    type={'button'}
                    aria-label="add"
                    onClick={vkAuthorise}>
              {
                <SocialNetworkIcon iconId={'vk'} text={'Вход через Вконтакте'}/>
              }
            </Button>
          </AuthBtnWrapper>
      }
    </>
  )
}