export const SelectStyles = {
    width: "100%",
    height: 36,
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    zIndex: 1,
    borderRadius: "2px",

    '& div': {
        padding: "6px 40px 6px 12px !important",
        zIndex: 1,
    },

    '& input': {
        height: "100%",
        border: "1px solid var( --neutralLight80)",
        '&::placeholder': {
            color: 'var(--neutralDark80)',
            fontWeight: 500,
            opacity: 1
        },
    },

    '& svg': {
        height: "24px",
        position: "absolute",
        right: "12px",
        top: "50%",
        transform: "translateY(-50%)"
    },

    '& fieldset': {
        borderColor: "#DEDBDC",
        marginTop: '5px',
        '& legend': {
            display: "none"
        }
    },

    // STYLES ON SELECT FOCUS
    // "&.Mui-focused fieldset": {
    //     borderColor: "rgba(0, 0, 0, 0.87) !important"
    // },

    "&.Mui-focused ~ span": {
        transform: "translateY(-50%) rotate(180deg)"
    },


}