import {authAPI} from "../api/api";
import {ThunkAction} from "redux-thunk";
import {AppStateType} from "./redux-store";
import {getDataByQuiz} from "./settings-reducer";
import {setEventVoting} from "./quiz-reducer";
import {getParticipantStatistic, getStatistic} from "./statistick-reducer";
import {getMeaningOverlays} from "./overlays-reducer";
import {logger} from "../logger";


const SET_LOADING = 'app/SET-LOADING';
const SET_IS_LOGGED = 'app/SET-IS-LOGGED';
const SET_PROGRESS = 'app/SET-PROGRESS';

type ActionsTypes =
    | ReturnType<typeof setLoading>
    | ReturnType<typeof setIsLogged>
    | ReturnType<typeof setProgress>
    | ReturnType<typeof setEventVoting>


export type appInitialStateType = {
    loading: boolean
    progress: boolean
    isAuth: boolean
}

const initialState: appInitialStateType = {
    loading: true,
    progress: false,
    isAuth: false,
};

export type ThunkType = ThunkAction<void, AppStateType, unknown, any>

export const appReducer = (state = initialState, action: ActionsTypes): appInitialStateType => {

    switch (action.type) {

        case SET_LOADING: {
            return {
                ...state,
                loading: action.value
            }
        }

        case SET_IS_LOGGED: {
            return {
                ...state,
                isAuth: action.value
            }
        }

        case SET_PROGRESS: {
            return {
                ...state,
                progress: action.value
            }
        }

        default :
            return state;
    }
}

export const setLoading = (value: boolean) => ({
    type: SET_LOADING,
    value
} as const)

export const setProgress = (value: boolean) => ({
    type: SET_PROGRESS,
    value
} as const)


export const setIsLogged = (value: boolean) => ({
    type: SET_IS_LOGGED,
    value
} as const)


export const auth = (): ThunkType => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const quizId = await authAPI.authMe()
        if (quizId) {
            dispatch(getStatistic())
            dispatch(getParticipantStatistic())
            dispatch(getDataByQuiz(quizId))
            dispatch(getMeaningOverlays(quizId))
            dispatch(setIsLogged(true))
        } else {
            dispatch(setLoading(false))
        }
    } catch (error) {
        logger.error('auth', error)
        dispatch(setLoading(false))
    }
}

export const setIsLoading = (state: AppStateType): boolean => state.app.loading
export const setIsAuth = (state: AppStateType): boolean => state.app.isAuth
export const setIsProgress = (state: AppStateType): boolean => state.app.progress
