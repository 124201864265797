import {useDispatch, useSelector} from "react-redux";
import {getQuizStatus} from "../redux/quiz-reducer";
import {getStatistic} from "../redux/statistick-reducer";
import {useEffect} from "react";
import {QUIZ_STATUS} from "../constants";

export const useStatisticsInterval = (delay: number = 2000) => {
    const dispatch = useDispatch()
    const isQuizActive = useSelector(getQuizStatus)

    useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isQuizActive === QUIZ_STATUS.started) {
        interval = setInterval(() => {
            dispatch(getStatistic())
        }, delay);
        return () => clearInterval(interval);
    }
    dispatch(getStatistic())
}, [dispatch])
}
//63a32e7a2de35b827e1f65ec