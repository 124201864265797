import {ThunkAction} from "redux-thunk";
import {AppStateType} from "./redux-store";
import {fbAPI, quizAPI, vkAPI} from "../api/api";
import {logger} from "../logger";
import {ParticipantsVKManager} from "../utils";
import {VkUtils} from "../utils";
import {
    SOCIAL_NETWORK_PROVIDERS,
    ParticipantRegistrationInfoResType, ErrorType,
} from "../api/api-types";
import {FacebookAPI} from "../api/social-network-api/facebookAPI";
import {setAlertMessage} from "./settings-reducer";
import {FACEBOOK_NOTIFICATIONS, VK_NOTIFICATIONS} from "../constants";
import {prepareEncryptedIdForPostFB} from "../utils/facebookHelpers";
import {VerificationParticipantData} from "../pages/verifyParticipants/validateInput/ValidateInput";
import {Dispatch} from "redux";
import axios from "axios";
import {setProgress} from "./app-reducer";

const SET_LOADING_STATUS = "VerifyParticipants/SET-LOADING-STATUS";
const SET_REGISTRATION_INFO_BY_PROVIDER =
    "VerifyParticipants/SET-REGISTRATION-INFO";
const SET_REGISTRATION_INFO_BY_PROVIDERS =
    "VerifyParticipants/SET-ARRAY-REGISTRATION-INFO";
const SET_BLOCKED_PARTICIPANT_COUNT =
    "VerifyParticipants/SET-BLOCKED-PARTICIPANT-COUNT";

export type RegistrationParticipantsDataType = {
    pendingParticipants: number;
    approvedParticipants: number;
    notApprovedParticipants: number;
    blockedParticipants: number;
};

export interface IInitialVerifyParticipantsState {
    isLoading: boolean;
    registrationParticipantsData: {
        [key: string]: RegistrationParticipantsDataType;
    };
}

const initialState: IInitialVerifyParticipantsState = {
    isLoading: false,
    registrationParticipantsData: {
        [SOCIAL_NETWORK_PROVIDERS.VK]: {
            approvedParticipants: 0,
            notApprovedParticipants: 0,
            pendingParticipants: 0,
            blockedParticipants: 0,
        },
        [SOCIAL_NETWORK_PROVIDERS.FACEBOOK]: {
            approvedParticipants: 0,
            notApprovedParticipants: 0,
            pendingParticipants: 0,
            blockedParticipants: 0,
        },
    },
};

type ActionsTypes =
    | ReturnType<typeof changeLoadingStatus>
    | ReturnType<typeof setAlertMessage>
    | ReturnType<typeof setRegistrationParticipantInfo>
    | ReturnType<typeof setArrayRegistrationParticipantInfo>;

type ThunkType = ThunkAction<void, AppStateType, unknown, ActionsTypes>;

export const participantsRegistrationInfoReducer = (
    state = initialState,
    action: ActionsTypes
): IInitialVerifyParticipantsState => {
    switch (action.type) {
        case SET_LOADING_STATUS:
            return {
                ...state,
                isLoading: action.value,
            };

        case SET_REGISTRATION_INFO_BY_PROVIDER:
            return {
                ...state,
                registrationParticipantsData: {
                    ...state.registrationParticipantsData,
                    [action.payload.provider]: {
                        ...action.payload.registrationParticipantsData,
                    },
                },
            };

        case SET_REGISTRATION_INFO_BY_PROVIDERS:
            const copyState = {...state};

            action.payload.forEach((el) => {
                copyState.registrationParticipantsData[el.provider] =
                    el.registrationParticipantsData;
            });

            return copyState;

        default:
            return state;
    }
};

export const setRegistrationParticipantInfo = (
    data: ParticipantRegistrationInfoResType
) =>
    ({
        type: SET_REGISTRATION_INFO_BY_PROVIDER,
        payload: data,
    } as const);

export const setArrayRegistrationParticipantInfo = (
    data: ParticipantRegistrationInfoResType[]
) =>
    ({
        type: SET_REGISTRATION_INFO_BY_PROVIDERS,
        payload: data,
    } as const);

export const changeLoadingStatus = (value: boolean) =>
    ({
        type: SET_LOADING_STATUS,
        value,
    } as const);

export const getParticipantInfo = (): ThunkType => async (dispatch) => {
    try {
        const {data} = await quizAPI.getParticipantsRegistrationInfo();
        dispatch(setArrayRegistrationParticipantInfo(data));
    } catch (error) {
        logger.error("getParticipantInfo", error);
    } finally {
        dispatch(changeLoadingStatus(false));
    }
};

export const verifyVKParticipants =
    (quizId: string): ThunkType =>
        async (dispatch, getState: () => AppStateType) => {
            if (!window.VK) throw new Error("window.vk is not ready");

            if (!quizId) throw new Error(`We can't do checking without quizId`);

            const repostedLinkVK = getState().settings.repostedLinks?.vk;

            if (!repostedLinkVK) {
                dispatch(setAlertMessage(VK_NOTIFICATIONS.NO_VK_LINK));
                throw new Error(`We can't do checking without reposted vk link`);
            }

            const participantsVKManager = new ParticipantsVKManager(
                quizId,
                repostedLinkVK,
                vkAPI,
                new VkUtils()
            );

            try {
                await participantsVKManager.checkParticipants(); // check on back-end that user is owner of this stream
            } catch (error) {
                logger.error("verifyParticipantsVK thunk: ", error);
            } finally {
                dispatch(getParticipantInfo());
            }
        };

export const verifyFBParticipants =
    (): ThunkType => async (dispatch, getState: () => AppStateType) => {
        dispatch(changeLoadingStatus(true));
        if (!window.FB) throw new Error("window.FB is not ready");

        const repostedFBLink = getState().settings.repostedLinks?.fb;

        if (!repostedFBLink) {
            dispatch(setAlertMessage(FACEBOOK_NOTIFICATIONS.NO_FACEBOOK_LINK));
            dispatch(changeLoadingStatus(false));
            return;
        }

        try {
            const postId = await FacebookAPI.getPostId(
                prepareEncryptedIdForPostFB(repostedFBLink)
            );
            const usersRepostedPost = await FacebookAPI.getUsersRepostedPost(postId);

            if (usersRepostedPost.length) {
                const mappedUsers = usersRepostedPost.map((u) => ({
                    accountId: u.from.id,
                    postId: u.id,
                }));
                await fbAPI.verifyFBParticipants(mappedUsers);
                dispatch(getParticipantInfo());
                return;
            }

            await fbAPI.verifyFBParticipants([]);
            dispatch(getParticipantInfo());
            return;

            dispatch(setAlertMessage(FACEBOOK_NOTIFICATIONS.USERS_NOT_FOUNDS));
            dispatch(changeLoadingStatus(false));
        } catch (e) {
            logger.error("verifyParticipantsFB thunk: ", e);
            dispatch(changeLoadingStatus(false));
        }
    };

export const submitIdForVerification = (data: VerificationParticipantData) =>
    async (dispatch: Dispatch) => {
        dispatch(setProgress(true))

        try {
            await quizAPI.submitIdForVerification(data);
        } catch (e) {
            if (axios.isAxiosError<ErrorType>(e)) {
                 dispatch(setAlertMessage(e.response!.data.message));
                 return;
            }
            logger.error("verifyParticipantsFB thunk: ", e);
            dispatch(setAlertMessage((e as Error).message));
        } finally {
            dispatch(setProgress(false))
        }
    }


// export const verifyParticipants = (): ThunkType => async (dispatch, getState: () => AppStateType) => {
//     if (!window.VK) throw new Error('window.VK is not ready')
//
//     const youtubeLink = getState().settings.youtubeStreamLink
//     const quizId = getState().settings.quizId
//
//     if (!quizId) throw new Error(`We can't do checking without quizId`)
//
//     const participantsVKManager = new ParticipantsVKManager(quizId, youtubeLink, vkAPI, new VkUtils())
//
//     dispatch(changeLoadingStatus(true))
//
//     try {
//         await participantsVKManager.checkParticipants() // check on back-end that user is owner of this stream
//     } catch (error) {
//         logger.error("verifyParticipants thunk: ", error);
//     } finally {
//         dispatch(getParticipantInfo())
//     }
// }

export const getRegistrationInfoByVkParticipants = (
    state: AppStateType
): RegistrationParticipantsDataType =>
    state.participantsRegistrationInfo.registrationParticipantsData[
        SOCIAL_NETWORK_PROVIDERS.VK
        ];
export const getRegistrationInfoByFbParticipants = (
    state: AppStateType
): RegistrationParticipantsDataType =>
    state.participantsRegistrationInfo.registrationParticipantsData[
        SOCIAL_NETWORK_PROVIDERS.FACEBOOK
        ];