import {useEffect, useRef, RefObject, useCallback} from 'react';

import { Callback } from '../types';

export const useOutsideClick = ( onClickOutside: Callback | undefined): RefObject<HTMLImageElement>  => {
  const KEY_EVENT_TYPE = 'click';
  const ref = useRef<HTMLImageElement | null>(null);

  const handleClick = useCallback( (event: MouseEvent) => {
    const target = event.target as HTMLInputElement;
    if (ref.current && !ref.current.contains(target)) {
      onClickOutside && onClickOutside();
      return
    }
  },[]);

  useEffect(() => {

    document.addEventListener(KEY_EVENT_TYPE, handleClick, true);

    return () => {
      document.removeEventListener(KEY_EVENT_TYPE, handleClick);
    };
  }, [ref]);

  return ref ;
};
