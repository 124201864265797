import styled, {css} from "styled-components";


const Participants = styled.div<{overlaySize?: 'big' | 'small'}>`
  position: fixed;
  min-width: 550px;
  top: 12px;
  left: 12px;
  bottom: 12px;
  background: rgba(22, 22, 23, 0.9);
  overflow: hidden;
  border-radius: 10px;
  
  ${props => props.overlaySize === 'small' && css`
    width: 40%;
  `}
  
  ${props => props.overlaySize === 'big' && css`
    width: auto;
    right: 12px;
  `}
`

const ParticipantsWrap = styled.div<{overlaySize?: 'big' | 'small'}>`
  position: relative;
  flex-grow: 1;
  display: grid;
  row-gap: 22px;
  ${props => props.overlaySize === 'big' && css`
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(10, 1fr);
    column-gap: 100px;

    &::before {
      content: "";
      position: absolute;
      width: 1px;
      top: 0;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #646366;
    }
  `}
`

export const S = {
    Participants,
    ParticipantsWrap
}