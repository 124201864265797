import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement={props.placement} /> //leaveDelay={2000000000000000}
))(({ theme, placement }) => {


    const styles = {
        [`& .${tooltipClasses.arrow}`]: {
            top: '2px !important',
            transform: 'unset !important',
        },

        [`& .${tooltipClasses.arrow}::before`]: {
            color: 'var(--neutralLight140)',
            borderTop: '1px solid #CFCFCF',
            borderLeft: '1px solid #CFCFCF',
            borderTopLeftRadius: '2px',
            backgroundColor: '#FFFFFF',
            width: 6,
            height: 6,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#FFFFFF',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '-0.01em',
            color: '#0D0C0B',
            maxWidth: '340px',
            padding: '4px 20px 4px 12px',
            border: '1px solid #CFCFCF',
            borderRadius: '2px',
            filter: 'drop-shadow(0px 2px 15px rgba(0,0,0,0.06))',
            marginTop: '5px !important',
        },
    }

    if (placement === 'bottom-end') {
        //@ts-ignore
        styles[`& .${tooltipClasses.arrow}`].right = '10px !important';
        //@ts-ignore
        styles[`& .${tooltipClasses.arrow}`].left = 'unset !important';
        //@ts-ignore
        styles[`& .${tooltipClasses.tooltip}`].marginRight = '-10px';
    } else if (placement === 'bottom-start') {
        //@ts-ignore
        styles[`& .${tooltipClasses.arrow}`].left = '10px !important';
        //@ts-ignore
        styles[`& .${tooltipClasses.arrow}`].right = 'unset !important';
        //@ts-ignore
        styles[`& .${tooltipClasses.tooltip}`].marginLeft = '-12px';
    }

    return styles;

});