import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {CommonWrapper} from '../../common-styles/Common.styles';
import loadScript from '../../utils/loadScript';
import {getParticipantInfo} from '../../redux/verify-participant-reducer';
import {PropsType} from './type';
import {VK} from '../../constants';
import {VkParticipants} from "./statistics/VkParticipants";
import {FbParticipants} from "./statistics/FbParticipants";
import {LoadingScript} from "./loading/LoadingScript";
import {setIsProgress} from "../../redux/app-reducer";
import {Progress} from "../../common-components/Progress";


export const VerifyParticipants: React.FC<PropsType> = ({mainContainer}) => {

    const dispatch = useDispatch()
    const progress = useSelector(setIsProgress);
    const [mainHeight, setMainHeight] = useState(0)
    const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  useEffect(() => {
    loadScript(VK.SCRIPT, VK.API, () => {
      setIsScriptLoaded(true)
    })
    setMainHeight(mainContainer.current ? mainContainer.current.clientHeight : 0)
  }, [mainContainer])

    useEffect(() => {
        dispatch(getParticipantInfo())
    }, [])


    return (
        <div>
            <CommonWrapper>
                {progress && <Progress/>}
                {
                    isScriptLoaded
                        ? <>
                            <VkParticipants isScriptLoaded={isScriptLoaded}/>
                            <FbParticipants/>
                        </>
                        : <LoadingScript mainHeight={mainHeight}/>
                }
            </CommonWrapper>
        </div>
    )
}

