import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import circle from "../../assets/img/Group 651.png";
import pencil from "../../assets/svg/pencil.svg";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import basket from "../../assets/svg/basket.svg";
import {QuestionsStyles} from '../../common-styles/Common.styles';
import {NOTIFICATION_QUIZ_MESSAGE, QUIZ_STATUS} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {getQuizStatus} from "../../redux/quiz-reducer";
import {setAlertMessage} from "../../redux/settings-reducer";
import {QUESTION_FRONT_TYPES, QUESTION_TYPES} from "./modal";
import {ImgDataType} from "../../api/api-types";
import {ImgByQuestion} from "../../common-components/ImgByQuestion";
import {usePictureToggle} from "../../hooks";
import {getCurrentStartedQuestion, getQuestions} from "../../redux/selectors/questionSelectors";

type PropsType = {
    setQuestionId: (id: string) => void
    index: number
    answers: string[]
    setStyle: boolean
    attemptsNumber: number
    setWarningModal?: (value: boolean) => void
    setModal?: (value: boolean) => void
    questionId: string
    setQuestionTitle?: (value: string) => void
    numberId: number
    questionTitle: string
    questionType: QUESTION_TYPES
    questionDescription: string
    imgData: ImgDataType | null
}


export const Question: React.FC<PropsType> = (props) => {

    const {
        index,
        setStyle,
        attemptsNumber,
        answers,
        questionId,
        numberId,
        questionTitle,
        questionType,
        questionDescription,
        imgData,
        setQuestionTitle,
        setWarningModal,
        setQuestionId,
        setModal,
    } = props;

    const dispatch = useDispatch();
    const quizStatus = useSelector(getQuizStatus);
    const currentStartedQuestion = useSelector(getCurrentStartedQuestion);
    const questions = useSelector(getQuestions);

    const {isZoomImg, handlePicture, closeZoomPicture} = usePictureToggle();
    const quizWasStarted = quizStatus !== QUIZ_STATUS.pending;

    const handleEditQuestion = () => {
        if (currentStartedQuestion?.id === questionId) {
            dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.CURRENT_QUESTION_WAS_STARTED));
            return;
        }
        setQuestionId(questionId);
        setModal && setModal(true);
    }

    const handleDeleteQuestion = () => {
        const foundNotCompletedQuestion = questions.find((q) => q.id === questionId && !answers.length);

        if (quizStatus === QUIZ_STATUS.started && !foundNotCompletedQuestion
            || quizStatus === QUIZ_STATUS.closed && !foundNotCompletedQuestion) {
            dispatch(setAlertMessage(NOTIFICATION_QUIZ_MESSAGE.LAUNCHED));
            return;
        }

        setQuestionId(questionId);
        setQuestionTitle && setQuestionTitle(questionTitle);
        setWarningModal && setWarningModal(true);
    }

    return (
        <>
            <Draggable key={questionId}
                       draggableId={questionId}
                       index={index}
                       isDragDisabled={isZoomImg}
            >
                {(provided) => (
                    <QuestionsStyles
                        key={questionId}
                        isZoomImg={isZoomImg}
                        setStyle={setStyle}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                    >
                        <div className={'tableGrid'}>
                            <div className={'number-block'}>
                                <div className={'position'}>
                                    <img className={quizWasStarted ? 'hide' : "drag-handle !important"}
                                         src={circle}
                                         alt="circle" {...provided.dragHandleProps} />
                                    <div>#{numberId}</div>
                                </div>
                            </div>
                            <div className={'quiz-theme-block'}>
                                    <span>
                                        {
                                            questionTitle ? questionTitle : <QuestionMarkIcon fontSize={"inherit"}/>
                                        }
                                    </span>
                            </div>
                            <div className={'description-block'}>
                                <div className={'flex-block'}>
                                        <span>{questionDescription ? questionDescription :
                                            <QuestionMarkIcon fontSize={"inherit"}/>}</span>
                                </div>
                            </div>

                            <div className={'type-question-block'}>
                                <div className={'type-answer-block position-question-type-block'}>
                                    {
                                        questionType === 'image'
                                            ? <ImgByQuestion isZoomImg={isZoomImg} handlePicture={handlePicture}
                                                             closeZoomPicture={closeZoomPicture} imgData={imgData}/>
                                            : QUESTION_FRONT_TYPES[questionType]
                                    }
                                </div>
                            </div>
                            <div className={'type-question-block position-question-type-block'}>
                                <div>
                                    {
                                        answers.length
                                            ? <ol>{answers.map((a, index) => <li key={index}>{a}</li>)}</ol>
                                            : <QuestionMarkIcon fontSize={"inherit"}/>
                                    }
                                </div>
                            </div>
                            <div className={'attempts-number-block center-content'}>
                                <span>{attemptsNumber}</span>
                            </div>
                            <div className={'pictures-block'}>
                                <div>
                                    <img onClick={handleEditQuestion} className={'picture'} src={pencil}
                                         alt="pencil"/>
                                </div>
                                <div className={'basket'}>
                                    <img
                                        onClick={handleDeleteQuestion} src={basket} alt="basket"/>
                                </div>
                            </div>
                        </div>
                    </QuestionsStyles>
                )}
            </Draggable>
        </>
    );
};

