import styled from "styled-components";

type AnswerInputWrapType = {
    answerIsActive: boolean
}

export const AnswerInputWrap = styled.div<AnswerInputWrapType>`
  position: relative;
  width: 100%;
  height: 36px;
  margin: 12px 0;
 
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1A1718;
  
  //todo: refact this styles
  .answer-img-inactive {
    position: absolute;
    top: 5px;
    right: 13px;
    
    img {
      padding-left: 13px;
    }
  }

  .answer-img-active {
    position: absolute;
    top: 5px;
    right: 13px;
  }

  .answer-inactive-block {
    height: 100%;
    
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      width: 100%;
    }
  }
  
  .input-style {
    width: 100%;
    padding: ${(props) => props.answerIsActive ? '6px 41px 6px 9px' : '6px 58px 6px 9px'};
    background: #fff;
    border: 1px solid #DFDFDF;
    
  }
  
  
  
  .cursor {
    cursor: pointer;
  }
  
  span {
    word-break: break-word;
  }
`