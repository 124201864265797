const loadScript = (src: string, elemId: string, callback: Function) => {
    const existingScript = document.getElementById(elemId);
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = src;
        script.id = elemId;
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback();
};
export default loadScript;