import * as React from 'react';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {SelectArrow} from "./SelectArrow";
import {SelectStyles} from "./SelectStyles";
import {useEffect} from "react";
import {FieldInputProps} from "formik/dist/types";

type BasicSelectPropsType = {
    items: string[],
    defaultValue: string,
    formikFieldName?: string
    setDelay?: (delay: string) => void
    getFieldPropsFormik?: (field: string) => FieldInputProps<any>
}

export function BasicSelect(props: BasicSelectPropsType) {
    const [item, setItem] = React.useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setItem(event.target.value);
        props.setDelay && props.setDelay(event.target.value)
    };

    useEffect(() => {
        setItem(props.defaultValue)
    }, [props.defaultValue])

    return (
        <FormControl sx={{width: '100%'}} size="small">
            <Select
                sx={SelectStyles}
                value={item}
                onChange={handleChange}
                IconComponent={() => <SelectArrow/>}
                {...props.getFieldPropsFormik && props.getFieldPropsFormik(props.formikFieldName!)}
            >
                {
                    props.items.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
}



